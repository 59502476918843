import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import API from "../../../api"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardBody,
  Table,
  Spinner,
} from "reactstrap"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"

const AddBonAvoirClient = props => {
  var userAuth = JSON.parse(localStorage.getItem("userAuth"))
  const user_id = userAuth.user.id
  const annee = userAuth.user.year
  //
  const [loading, setLoading] = useState(false)
  const [numBonAvoir, setNumBonAvoir] = useState("")
  const [dateBonAvoir, setDateBonAvoir] = useState(new Date())
  const [bonLiv, setBonLiv] = useState([])
  const [selectBonLiv, setSelectBonLiv] = useState("")
  const [dateBonLiv, setDateBonLiv] = useState(new Date())
  const [selectCodeClient, setSelectCodeClient] = useState("")
  const [raisonSocial, setRaisonSocial] = useState("")
  const [adresse, setAdresse] = useState("")
  const [selectFamilleClient, setSelectFamilleClient] = useState("")
  const [etatFodec, setEtatFodec] = useState(0)
  const [codeTva, setCodeTva] = useState("")
  const [remiseExcep, setRemiseExcep] = useState("")
  const [selectRepre, setSelectRepre] = useState("")
  const [etatRegimeForf, setEtatRegimeForf] = useState(0)
  const [depot, setDepot] = useState([])
  const [selectDepot, setSelectDepot] = useState("")
  const [observation, setObservation] = useState("")
  const [modal, setModal] = useState(false)
  const [indexLigne, setIndexLigne] = useState("")
  //client 
  const [ClientSelect , setClientSelect]  = useState("")
  const [arrayProduit, setArrayProduit] = useState([
    {
      produit_id: "",
      designation: "",
      fodec: "",
      qte: "",
      prix_ht: "",
      remise: "",
      montant_ht: 0,
      tva: "",
      montant_ttc: 0,
      montant_remise: 0,
      montant_fodec: 0,
      depot_id: "",
      depots:[],
      product_selected_depot:null
    },
  ])
  const [produits, setProduits] = useState([])
  const [selectProduit, setSelectProduit] = useState("")
  const [indexOfProduit, setIndexOfProduit] = useState("")
  //
  const [prixHT, setPrixHT] = useState("")
  const [designation, setDesignation] = useState("")
  const [tva, setTva] = useState("")
  const [quantite, setQuantite] = useState("")
  const [totalHT, setTotalHT] = useState("")
  const [totalTTC, setTotalTTC] = useState("")
  const [totalTva, setTotalTva] = useState("")
  const [totalRemise, setTotalRemise] = useState("")
  const [remise, setRemise] = useState("")
  const [totalFodec, setTotalFodec] = useState("")
  const [fodec, setFodec] = useState("")
  const [buttonClicked, setButtonClicked] = useState(false);


  useEffect(async () => {
    const res = await API.post("bon/avoir/client/getnum",{
      annee:annee
    }).then(res => {
      setNumBonAvoir(res.data.BonAvoirClient)
      setLoading(true)
    })
    const resF = await API.get("bon/livraison/client/select").then(resF => {
      setBonLiv(resF.data.BonLivraisonClient)
    })
    const resP = await API.get("article/select").then(resP => {
      setProduits(resP.data.Article)
    })
    const resD = await API.get("depot/select").then(resA => {
      setDepot(resA.data.Depot)
    })
    const resC = await API.get("client/select").then(resC => {
      setClientSelect(resC.data.Client)
    })
  }, [])
    //selection_depot_pour_produit
    const select_articleDepot = (depot, indexOfProduit) => {
     
    
      const selectedOption = {
        value: depot.value,
        label: depot.label,
      };
      setArrayProduit((prevArrayProduit) =>
        prevArrayProduit.map((el, id) =>
          id === indexOfProduit
            ? {
                ...el,
                depot_id: depot.value,
                product_selected_depot: selectedOption,
              }
            : el
        )
      );
    };

  const addLigne = () => {
    let element = {
      produit_id: "",
      designation: "",
      fodec: "",
      qte: "",
      prix_ht: "",
      remise: "",
      montant_ht: 0,
      tva: "",
      montant_ttc: 0,
      montant_remise: 0,
      montant_fodec: 0,
      depot_id:'',
      depots:[],
      product_selected_depot:null
    }
    setArrayProduit([...arrayProduit, element])
  }

  const toggle = indexOfLigne => {
    setModal(!modal)
    setIndexLigne(indexOfLigne)
  }

  const toggleDelete = async () => {
    arrayProduit.splice(indexLigne, 1)
    setModal(false)
  }

  useEffect(() => {
    var tot_ht = arrayProduit
      .map(elem => elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ht = parseFloat(tot_ht).toFixed(3)
    setTotalHT(total_ht)
    var tot_ttc = arrayProduit
      .map(elem => elem.montant_ttc)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ttc = parseFloat(tot_ttc).toFixed(3)
    setTotalTTC(total_ttc)
    var tot_tva = arrayProduit
      .map(elem => elem.montant_ttc - elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_tva = parseFloat(tot_tva).toFixed(3)
    setTotalTva(total_tva)
    var tot_remise = arrayProduit
      .map(elem => elem.montant_remise)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_rem = parseFloat(tot_remise).toFixed(3)
    setTotalRemise(total_rem)
    var tot_fodec = arrayProduit
      .map(elem => elem.montant_fodec)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_fodec = parseFloat(tot_fodec).toFixed(3)
    setTotalFodec(total_fodec)
  }, [modal])


  const select_Client = async selectClient => {
    setSelectCodeClient(selectClient)


    const res = await API.post("bon/livraison/client/get_livraison_by_client", {
      id: selectClient.value
    }).then(res => {
      setBonLiv(res.data.BonLivraisonClient)
      setDateBonLiv("")

    })


    setArrayProduit([
      {
        produit_id: "",
        designation: "",
        fodec: "",
        qte: "",
        prix_ht: "",
        remise: "",
        montant_ht: 0,
        tva: "",
        montant_ttc: 0,
        montant_remise: 0,
        montant_fodec: 0,
        depot_id: "",
        depots:[],
      product_selected_depot:null
      },
    ])

    setSelectBonLiv("")



   }

  const getClientBonLiv = async selectBonLiv => {
    if (selectBonLiv != null) {
      var bon_liv_id = selectBonLiv.value
    } else {
      var bon_liv_id = ""
    }
    setSelectBonLiv(selectBonLiv)
    //
    const res = await API.post("bon/livraison/client/get_by_id", {
      id: bon_liv_id,
    }).then(res => {
      setDateBonLiv(new Date(res.data.BonLivraisonClient.date_livr))
      setSelectCodeClient(res.data.BonLivraisonClient.client)
      setRaisonSocial(res.data.BonLivraisonClient.raisonSocial)
      setAdresse(res.data.BonLivraisonClient.adresse)
      setSelectFamilleClient(res.data.BonLivraisonClient.famille_client)
      setEtatFodec(res.data.BonLivraisonClient.fodec)
      setCodeTva(res.data.BonLivraisonClient.codeTva)
      setSelectRepre(res.data.BonLivraisonClient.representant)
      setEtatRegimeForf(res.data.BonLivraisonClient.regimeForfitaire)
      setSelectDepot(res.data.BonLivraisonClient.depot)
      setArrayProduit(res.data.BonLivraisonClient.ligne)
      setTotalFodec(res.data.BonLivraisonClient.total_fodec)
      setTotalHT(res.data.BonLivraisonClient.total_ht)
      setTotalRemise(res.data.BonLivraisonClient.total_remise)
      setTotalTTC(res.data.BonLivraisonClient.total_ttc)
      setTotalTva(res.data.BonLivraisonClient.total_tva)
    })
  }

  const getProduits = async (selectProduit, indexOfProduit) => {
    if (selectProduit != null) {
      var produit_id = selectProduit.value
    } else {
      var produit_id = ""
    }
    if (selectProduit != null) {
      var produit = selectProduit
    } else {
      var produit = ""
    }
    setIndexOfProduit(indexOfProduit)
    const res = await API.post("article/get_by_id", {
      id: produit_id,
    })
      .then(res => {
        setDesignation(res.data.Article.designation)
        setPrixHT(res.data.Article.prix_achat_ht)
        setTva(res.data.Article.tva)
        setFodec(res.data.Article.fodec)
        setSelectProduit(selectProduit)
      })
      .then(() => {
        setArrayProduit(
          arrayProduit.map((el, id) =>
            id === indexOfProduit
              ? Object.assign(el, {
                  produit_id: produit,
                  designation: designation,
                  prix_ht: prixHT,
                  tva: tva,
                  fodec: fodec,
                })
              : el
          )
        )
      })
      .then(() => {
        var tot_ht = arrayProduit
          .map(elem => elem.montant_ht)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_ht = parseFloat(tot_ht).toFixed(3)
        setTotalHT(total_ht)
        var tot_ttc = arrayProduit
          .map(elem => elem.montant_ttc)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_ttc = parseFloat(tot_ttc).toFixed(3)
        setTotalTTC(total_ttc)
        var tot_tva = arrayProduit
          .map(elem => elem.montant_ttc - elem.montant_ht)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_tva = parseFloat(tot_tva).toFixed(3)
        setTotalTva(total_tva)
        var tot_remise = arrayProduit
          .map(elem => elem.montant_remise)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_rem = parseFloat(tot_remise).toFixed(3)
        setTotalRemise(total_rem)
        var tot_fodec = arrayProduit
          .map(elem => elem.montant_fodec)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_fodec = parseFloat(tot_fodec).toFixed(3)
        setTotalFodec(total_fodec)
      })
  }

  useEffect(() => {
    if (selectProduit != null) {
      var produit = selectProduit
    } else {
      var produit = ""
    }
    setArrayProduit(
      arrayProduit.map((el, id) =>
        id === indexOfProduit
          ? Object.assign(el, {
              produit_id: produit,
              designation: designation,
              prix_ht: prixHT,
              tva: tva,
              fodec: fodec,
            })
          : el
      )
    )
  }, [selectProduit])

  const addQuantite = (e, indexOfQte) => {
    if (e < 1) {
      toast.error("Veuillez vérifier la quantite !", {
        containerId: "A",
      })
    } else {
      if (parseInt(arrayProduit[indexOfQte].qte_x) >= parseInt(e)) {
        setQuantite(e)
        var qte = e
        var prixHT = arrayProduit[indexOfQte].prix_ht
        var tva = arrayProduit[indexOfQte].tva.label
        var fodec = arrayProduit[indexOfQte].fodec
        var remise = arrayProduit[indexOfQte].remise
        var montantHT = prixHT * qte
        // calcule total HT avec remise
        var montantRemise = montantHT - (montantHT * remise) / 100
        // calcule total remise
        var totalRemise = montantHT - montantRemise
        // calcule fodec
        if (etatFodec == 1 && fodec == 1) {
          var montantFodec = (montantRemise * 1) / 100
        } else if (etatFodec == 0 || fodec == 0) {
          var montantFodec = 0
        }
        // calcule TTC
        var montantHT_fodec = montantRemise + montantFodec
        var montantTTC =
          (montantHT_fodec * tva) / 100 + parseFloat(montantHT_fodec)
        setArrayProduit(
          arrayProduit.map((el, id) =>
            id === indexOfQte
              ? Object.assign(el, {
                  qte: qte,
                  montant_ht: parseFloat(montantRemise).toFixed(3),
                  montant_remise: parseFloat(totalRemise).toFixed(3),
                  montant_ttc: parseFloat(montantTTC).toFixed(3),
                  montant_fodec: parseFloat(montantFodec).toFixed(3),
                })
              : el
          )
        )
      } else {
        toast.error(
          "Ne pas depasser la quantité de " + arrayProduit[indexOfQte].qte_x,
          {
            containerId: "A",
          }
        )
      }
    }
  }

  useEffect(() => {
    var tot_ht = arrayProduit
      .map(elem => elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ht = parseFloat(tot_ht).toFixed(3)
    setTotalHT(total_ht)
    var tot_ttc = arrayProduit
      .map(elem => elem.montant_ttc)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ttc = parseFloat(tot_ttc).toFixed(3)
    setTotalTTC(total_ttc)
    var tot_tva = arrayProduit
      .map(elem => elem.montant_ttc - elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_tva = parseFloat(tot_tva).toFixed(3)
    setTotalTva(total_tva)
    var tot_remise = arrayProduit
      .map(elem => elem.montant_remise)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_rem = parseFloat(tot_remise).toFixed(3)
    setTotalRemise(total_rem)
    var tot_fodec = arrayProduit
      .map(elem => elem.montant_fodec)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_fodec = parseFloat(tot_fodec).toFixed(3)
    setTotalFodec(total_fodec)
  }, [quantite])

  const addRemise = (e, indexOfRemise) => {
    if (e > 100 || e < 0) {
      toast.error("Veuillez vérifier la remise !", {
        containerId: "A",
      })
    } else {
      setRemise(e)
      var remise = e
      var qte = arrayProduit[indexOfRemise].qte
      var prixHT = arrayProduit[indexOfRemise].prix_ht
      var fodec = arrayProduit[indexOfRemise].fodec
      var tva = arrayProduit[indexOfRemise].tva.label
      // calcule HT avec remise
      var montantHT = prixHT * qte
      var montantRemise = montantHT - (montantHT * remise) / 100
      // calcule total remise
      var totalRemise = montantHT - montantRemise
      // calcule fodec
      if (etatFodec == 1 && fodec == 1) {
        var montantFodec = (montantRemise * 1) / 100
      } else if (etatFodec == 0 || fodec == 0) {
        var montantFodec = 0
      }
      // calcule TTC
      var montantHT_fodec = montantRemise + montantFodec
      var montantTTC =
        (montantHT_fodec * tva) / 100 + parseFloat(montantHT_fodec)
      setArrayProduit(
        arrayProduit.map((el, id) =>
          id === indexOfRemise
            ? Object.assign(el, {
                montant_ht: parseFloat(montantRemise).toFixed(3),
                remise: remise,
                montant_remise: parseFloat(totalRemise).toFixed(3),
                montant_ttc: parseFloat(montantTTC).toFixed(3),
                montant_fodec: parseFloat(montantFodec).toFixed(3),
              })
            : el
        )
      )
    }
  }

  useEffect(() => {
    var tot_ht = arrayProduit
      .map(elem => elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ht = parseFloat(tot_ht).toFixed(3)
    setTotalHT(total_ht)
    var tot_ttc = arrayProduit
      .map(elem => elem.montant_ttc)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ttc = parseFloat(tot_ttc).toFixed(3)
    setTotalTTC(total_ttc)
    var tot_tva = arrayProduit
      .map(elem => elem.montant_ttc - elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_tva = parseFloat(tot_tva).toFixed(3)
    setTotalTva(total_tva)
    var tot_remise = arrayProduit
      .map(elem => elem.montant_remise)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_rem = parseFloat(tot_remise).toFixed(3)
    setTotalRemise(total_rem)
    var tot_fodec = arrayProduit
      .map(elem => elem.montant_fodec)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_fodec = parseFloat(tot_fodec).toFixed(3)
    setTotalFodec(total_fodec)
  }, [remise])

  const save = async () => {
    setButtonClicked(true);

    let count = 0
    if (selectCodeClient != "") {
  
        if (arrayProduit.length > 0) {
          for (let index = 0; index < arrayProduit.length; index++) {
            let qte = arrayProduit[index].qte
            if (qte <= 0) {
              count++
            }
            var depot =arrayProduit[index].depot_id
            if(depot === undefined){
           
              var selection_depot = 1;
            }
          
          if (selection_depot === 1) {
            toast.error("⛔ Selectionner un depot SVP", {
              containerId: "A",
            })
            setButtonClicked(false);

          
          }
           else if (index == arrayProduit.length - 1) {
              if (count == 0) {
                if (dateBonAvoir != "") {
                  let date = dateBonAvoir
                  let month = "" + (date.getMonth() + 1)
                  let day = "" + date.getDate()
                  let year = date.getFullYear()
                  if (month.length < 2) month = "0" + month
                  if (day.length < 2) day = "0" + day
                  var convertDateBonAvoir = [year, month, day].join("-")
                } else {
                  var convertDateBonAvoir = ""
                }
                let dateBon = dateBonLiv
                let monthBon = "" + (dateBon.getMonth() + 1)
                let dayBon = "" + dateBon.getDate()
                let yearBon = dateBon.getFullYear()
                if (monthBon.length < 2) monthBon = "0" + monthBon
                if (dayBon.length < 2) dayBon = "0" + dayBon
                var convertDateBonLiv = [yearBon, monthBon, dayBon].join("-")
                //
                const res = await API.post("bon/avoir/client/add", {
                  annee: annee,
                  user_id,
                  // information
                  num_bon_avoir: numBonAvoir,
                  date_bon_avoir: convertDateBonAvoir,
                  bon_liv_id: selectBonLiv.value,
                  date_bon_liv: convertDateBonLiv,
                  client_id: selectCodeClient.value,
                  raison_social: raisonSocial,
                  adresse: adresse,
                  famille_id: selectFamilleClient.value,
                  fodec: etatFodec,
                  code_tva: codeTva,
                  remise_excep: remiseExcep,
                  representant_id: selectRepre.value,
                  regime_forf: etatRegimeForf,
                  depot_id: selectDepot.value,
                  // array
                  array: arrayProduit,
                  // information
                  observation: observation,
                  tot_remise: totalRemise,
                  tot_ht: totalHT,
                  tot_fodec: totalFodec,
                  tot_tva: totalTva,
                  avance_impot: "",
                  tot_ttc: totalTTC,
                }).then(res => {
                 
                  props.setSection(1)
                })
              } else {
                toast.error("La quantité doit étre supérieur a 0", {
                  containerId: "A",
                })
                setButtonClicked(false);

              }
            }
          }
        } else {
          toast.error("Veuillez choisir au moins un article", {
            containerId: "A",
          })
          setButtonClicked(false);

        
      } 
    } else {
      toast.error("Veuillez selectionner un client", {
        containerId: "A",
      })
      setButtonClicked(false);

    }
  }

  return (
    <React.Fragment>
      {loading ? (
        <Row>
          <Form>
            <Row>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">N° bon Avoir</Label>
                  <Input
                    lg="3"
                    type="text"
                    className="form-control"
                    disabled
                    value={numBonAvoir}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Date avoir</Label>
                  <Col md={12} className="pr-0">
                    <DatePicker
                      className="form-control ddate"
                      selected={dateBonAvoir}
                      onChange={e => setDateBonAvoir(e)}
                      dateFormat="dd/MM/yyyy"
                    />
                  </Col>
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">
                    N° bon livraison
                  </Label>
                  <Select
                    options={bonLiv}
                    isSearchable={true}
                    onChange={e => getClientBonLiv(e)}
                    value={selectBonLiv}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">
                    Date bon livraison
                  </Label>
                  <Col md={12} className="pr-0">
                    <DatePicker
                      className="form-control ddate"
                      selected={dateBonLiv}
                      onChange={() => setDateBonLiv(e)}
                      dateFormat="dd/MM/yyyy"
                      disabled
                    />
                  </Col>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Client
                    <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                  </Label>
                  <Select
                    isSearchable={true}
                    value={selectCodeClient}
                    options={ClientSelect}
                    onChange={(e) => select_Client(e)}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Raison sociale</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Raison sociale"
                    value={raisonSocial}
                    disabled
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Adresse</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Adresse"
                    value={adresse}
                    disabled
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Famille</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Famille"
                    value={selectFamilleClient.label}
                    disabled
                  />
                </div>
              </Col>
            </Row>
            <Row>
             {/* <Col md={3}>
                <div className="mb-3 mt-3">
                  <div className="form-check form-check-inline">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="horizontal-customCheck"
                      checked={etatFodec == 1}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="horizontal-customCheck"
                    >
                      Fodec
                    </Label>
                  </div>
                </div>
      </Col>*/}
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Code TVA</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Code TVA"
                    value={codeTva}
                    disabled
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Representant</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Representant"
                    value={selectRepre.label}
                    disabled
                  />
                </div>
              </Col>
              {/*<Col md={3}>
                <div className="mb-3 mt-3">
                  <div className="form-check form-check-inline">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="horizontal-customCheck"
                      checked={etatRegimeForf == 1}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="horizontal-customCheck"
                    >
                      Régime forfaitaire
                    </Label>
                  </div>
                </div>
    </Col>*/}
            </Row>
            <Row>
              {/*<Col md={3}>
                <div className="mb-3">
                  <div
                    style={{ display: "flex", justifyContent: "space-arround" }}
                  >
                    <Label for="basicpill-firstname-input1">Depot</Label>
                    <Label style={{ color: "red", paddingLeft: "5px" }}>
                      *
                    </Label>
                  </div>
                  <Select
                    options={depot}
                    isSearchable={true}
                    onChange={e => setSelectDepot(e)}
                    value={selectDepot}
                  />
                </div>
      </Col>*/}
            </Row>
          </Form>
          <CardTitle className="h4 mt-4">Details</CardTitle>
          <div>
            <table className="table table-bordered mt-4">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} scope="col">
                    Article
                  </th>
            
                  <th style={{ textAlign: "center" }} scope="col">
                    Qte
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    P.U.H.T
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Remise
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Fodec
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant HT
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    TVA
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant TTC
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Depot
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    <i
                      style={{
                        color: "#a0ceb8",
                        cursor: "pointer",
                      }}
                      className="fas fa-plus"
                      onClick={addLigne}
                    />
                  </th>
                </tr>
              </thead>
              {arrayProduit.map((el, index) => (
                <tbody key={index}>
                    <tr>
                      <td style={{ textAlign: "center", width: "20%" }}>
                        <Select
                          options={produits}
                          isSearchable={true}
                          value={el.produit_id}
                          onChange={e => getProduits(e, index)}
                        />
                      </td>
                    
                      <td style={{ textAlign: "center", width: "8%" }}>
                        <Input
                          type="number"
                          className="form-control"
                          placeholder="..."
                          value={el.qte}
                          min={0}
                          onChange={e => addQuantite(e.target.value, index)}
                        />
                      </td>
                      <td style={{ textAlign: "center", width: "10%" }}>
                        <Input
                          type="number"
                          className="form-control"
                          placeholder="..."
                          value={el.prix_ht}
                        />
                      </td>
                      <td style={{ textAlign: "center", width: "8%" }}>
                        <Input
                          type="number"
                          className="form-control"
                          placeholder="..."
                          value={el.remise}
                          min={0}
                          max={100}
                          onChange={e => addRemise(e.target.value, index)}
                        />
                      </td>
                      <td style={{ textAlign: "center", width: "1%" }}>
                        <Input
                          type="checkbox"
                          className="form-check-Input"
                          id="horizontal-customCheck"
                          checked={el.fodec == 1}
                        />
                      </td>
                      <td style={{ textAlign: "center", width: "12%" }}>
                        <Input
                          type="number"
                          className="form-control"
                          placeholder="..."
                          value={el.montant_ht}
                        />
                      </td>
                      <td style={{ textAlign: "center", width: "7%" }}>
                        <Input value={el.tva.label} disabled />
                      </td>
                      <td style={{ textAlign: "center", width: "12%" }}>
                        <Input
                          type="number"
                          className="form-control"
                          placeholder="..."
                          value={el.montant_ttc}
                        />
                      </td>

                      <td style={{ textAlign: "center", width: "13%" }}>
                      <Select
                        options={depot}
                        isSearchable={true}
                        onChange={e => select_articleDepot(e, index)}
                      //onChange={e => setSelectDepot(e)}
                      value={el.product_selected_depot}
                      />
                    </td>
                      <td style={{ textAlign: "center", width: "1%" }}>
                        <i
                          style={{ color: "#cc0000" }}
                          className="mdi mdi-delete font-size-18"
                          id="deletetooltip"
                          onClick={() => toggle(index)}
                        />
                      </td>
                    </tr>
                </tbody>
              ))}
            </table>
          </div>
          <Card>
            <CardBody>
              <CardTitle className="mb-3">Total </CardTitle>
              <Row>
                <Col className="mt-3" md={12}>
                  <div className="mb-3">
                    <Label for="basicpill-lastname-input2">Observation</Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Observation"
                      onChange={e => setObservation(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <div className="table-responsive">
                <Table className="table mb-0">
                  <tbody>
                    <tr>
                      <td>Total des remises :</td>
                      <td>{totalRemise}</td>
                    </tr>
                    <tr>
                      <td>Total HT :</td>
                      <td>{totalHT}</td>
                    </tr>
                    <tr>
                      <td>Total fodec :</td>
                      <td>{totalFodec}</td>
                    </tr>
                    <tr>
                      <td>Total TVA :</td>
                      <td>{totalTva}</td>
                    </tr>
                    <tr>
                      <td>Avance/Impot :</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>Total TTC : </td>
                      <td>{totalTTC}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className="text-center mt-4"
              >
                <Button
                  type="button"
                  color="warning"
                  className="btn btn-warning  mb-2 me-2"
                  onClick={props.back}
                >
                  Annuler
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="text-center mt-4"
              >
                <button
                  type="submit"
                  className="btn btn-primary "
                  disabled={buttonClicked}
                  onClick={save}
                >
                  Confirmer
                </button>
              </div>
            </Col>
          </div>
        </Row>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            {" "}
            Chargement en cours ...{" "}
          </h4>
        </div>
      )}
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggle}
        >
          Suppression ligne
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  onClick={toggleDelete}
                  color="info"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default AddBonAvoirClient
AddBonAvoirClient.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
