import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import API from "../../../api"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  Table,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"

const AddFactureLivraison = props => {
  var userAuth = JSON.parse(localStorage.getItem("userAuth"))
  const user_id = userAuth.user.id
  const annee = userAuth.user.year
  const [numFacture, setNumFacture] = useState("")
  const [dateFacture, setDateFacture] = useState(new Date())
  const [fournisseur, setFournisseur] = useState([])
  const [selectFournisseur, setSelectFournisseur] = useState("")
  const [adresse, setAdresse] = useState("")
  const [raisonSocial, setRaisonSocial] = useState("")
  const [codeTva, setCodeTva] = useState("")
  const [etatFodec, setEtatFodec] = useState("")
  const [reference, setReference] = useState("")
  const [dateReference, setDateReference] = useState(new Date())
  const [montantRef, setMontantRef] = useState("")
  const [displayBlock, setDisplayBlock] = useState(false)
  const [displayBlockTotal, setDisplayBlockTotal] = useState(false)
  const [arrayProduit, setArrayProduit] = useState([])
  const [array, setArray] = useState([])
  const [totalHT, setTotalHT] = useState("")
  const [totalTTC, setTotalTTC] = useState("")
  const [totalTva, setTotalTva] = useState("")
  const [totalRemise, setTotalRemise] = useState("")
  const [totalFodec, setTotalFodec] = useState("")
  const [buttonClicked, setButtonClicked] = useState(false);
  const [modeReglement, setModeReglement] = useState([])
  const [selectModeReglement, setSelectModeReglement] = useState("")



  useEffect(async () => {
    const resL = await API.post("fournisseur/facture/num",{
      annee:annee
    }).then(resL => {
      setNumFacture(resL.data.FactureFournisseur)
    })
    const res = await API.get("fournisseur/select").then(res => {
      setFournisseur(res.data.Fournisseur)
    })

    const resM = await API.get("mode/reglement/select").then(resM => {
      setModeReglement(resM.data.ModeReglement)
    })
  }, [])

  const getFournisseur = async selectFournisseur => {
    if (selectFournisseur != null) {
      var fournisseur_id = selectFournisseur.value
    } else {
      var fournisseur_id = ""
    }
    setSelectFournisseur(selectFournisseur)
    const res = await API.post("fournisseur/get_by_id", {
      id: fournisseur_id,
    }).then(res => {
      setAdresse(res.data.Fournisseur.adresse)
      setEtatFodec(res.data.Fournisseur.fodec)
      setRaisonSocial(res.data.Fournisseur.raisonSocial)
      setCodeTva(res.data.Fournisseur.code)
    })
  }

  // const tousFact = () => {
  //   if (arrayProduit[0].etat == 0) {
  //     setArrayProduit(arrayProduit.map(el => Object.assign(el, { etat: 1 })))
  //   } else {
  //     setArrayProduit(arrayProduit.map(el => Object.assign(el, { etat: 0 })))
  //   }
  // }

  const coche = (event, index, id) => {
    if (event.target.checked == true) {
      let etat = 1
      setArrayProduit(
        arrayProduit.map((el, id) =>
          id === index
            ? Object.assign(el, {
                etat: etat,
              })
            : el
        )
      )
      var arrayFact = array
      arrayFact.push(id)
      setArray(arrayFact)
    } else {
      setArrayProduit(
        arrayProduit.map((el, id) =>
          id === index
            ? Object.assign(el, {
                etat: 0,
              })
            : el
        )
      )
      var arrayFact = array
      arrayFact.splice(index, 1)
      setArray(arrayFact)
    }
  }

  const display = async () => {
    if (selectFournisseur != null) {
      var fournisseur_id = selectFournisseur.value
      const res = await API.post("fournisseur/facture/get_list_liv", {
        fournisseur_id: fournisseur_id,
      }).then(res => {
        setArrayProduit(res.data.LivraisonFournisseur)
        setDisplayBlock(true)
      })
    }
  }

  const facturer = async () => {
    const res = await API.post("fournisseur/facture/get_liv", {
      array_id_livraison: array,
    }).then(res => {
      setTotalHT(res.data.LivraisonFournisseur.total_ht)
      setTotalRemise(res.data.LivraisonFournisseur.total_remise)
      setTotalTva(res.data.LivraisonFournisseur.total_tva)
      setTotalFodec(res.data.LivraisonFournisseur.total_fodec)
      setTotalTTC(res.data.LivraisonFournisseur.total_ttc)
      setDisplayBlockTotal(true)
    })
  }

  const save = async () => {

    /*if (dateFacture != "") {
      let dateFact = dateFacture
      let month = "" + (dateFact.getMonth() + 1)
      let day = "" + dateFact.getDate()
      let year = dateFact.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDateFact = [year, month, day].join("-")
    } else {
      var convertDateFact = ""
    }
    if (dateReference != "") {
      let dateRef = dateReference
      let month = "" + (dateRef.getMonth() + 1)
      let day = "" + dateRef.getDate()
      let year = dateRef.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDateRef = [year, month, day].join("-")
    } else {
      var convertDateRef = ""
    }*/
    setButtonClicked(true)
    var etat = true
    for (let i = 0; i < arrayProduit.length; i++) {
      var qte = arrayProduit[i].qte
      if (qte == "") {
        var etat = false
        setButtonClicked(flase)

        toast.error("⛔ Quantité doit étre supérieur à 0", {
          containerId: "A",
        })
      }
    }
    if (arrayProduit.length == 0) {
      setButtonClicked(flase)

      toast.error("⛔ Saisir au moins une article", {
        containerId: "A",
      })
    /*} else if (montantRef != totalTTC) {
      setButtonClicked(false)

      toast.error("⛔ Montant référence et total TTC doit étre égale", {
        containerId: "A",
      })*/
    } else if (
      //montantRef == "" ||
      //reference == "" ||
      selectFournisseur == "" ||
     // dateReference == "" || 
      selectModeReglement == ""
    ) {
      setButtonClicked(false)

      toast.error("⛔ Remplir les champs obligatoire", {
        containerId: "A",
      })
    } else if (etat) {
      const res = await API.post("fournisseur/facture/add_liv", {
        user_id: user_id,
        annee:annee,
        date_fact: dateFacture,
        fournisseur_id: selectFournisseur.value,
        reference: reference,
        date_ref: dateReference,
        montant_ref: montantRef,
        total_remise: totalRemise,
        total_ht: totalHT,
        total_fodec: totalFodec,
        total_tva: totalTva,
        total_ttc: totalTTC,
        array_id_livraison: array,
        modeReglement:selectModeReglement
      }).then(res => {
        props.setSection(1)
      })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">N° facture</Label>
                <Input
                  lg="3"
                  type="text"
                  className="form-control"
                  disabled
                  value={numFacture}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Date facture</Label>
                <Col md={12} className="pr-0">
                  <DatePicker
                    className="form-control ddate"
                    selected={dateFacture}
                    onChange={() => setDateFacture(e)}
                    dateFormat="dd/MM/yyyy"
                  />
                </Col>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <div
                  style={{ display: "flex", justifyContent: "space-arround" }}
                >
                  <Label for="basicpill-firstname-input1">Fournisseur</Label>
                  <Label style={{ color: "red", paddingLeft: "5px" }}>*</Label>
                </div>
                <Select
                  options={fournisseur}
                  isSearchable={true}
                  value={selectFournisseur}
                  onChange={e => getFournisseur(e)}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Adresse</Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Adresse"
                  disabled
                  value={adresse}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Raison sociale</Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Raison sociale"
                  disabled
                  value={raisonSocial}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2"> Code TVA </Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Code tva"
                  disabled
                  value={codeTva}
                />
              </div>
            </Col>
            <Col className="mt-3" md={3}>
              <div className="mb-3 mt-3">
                <div className="form-check form-check-inline">
                  <Input
                    type="checkbox"
                    className="form-check-Input"
                    id="horizontal-customCheck"
                    disabled
                    checked={etatFodec == 1}
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="horizontal-customCheck"
                  >
                    Fodec
                  </Label>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="mb-3">
                <div
                  style={{ display: "flex", justifyContent: "space-arround" }}
                >
                  <Label for="basicpill-firstname-input1">
                    Référence fournisseur
                  </Label>
                  {/*<Label style={{ color: "red", paddingLeft: "5px" }}>*</Label>*/}
                  </div>
                <Input
                  placeholder="Référence"
                  type="text"
                  className="form-control"
                  onChange={e => setReference(e.target.value)}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Mode de règlement</Label>
                <Label style={{ color: "red", paddingLeft: "5px" }}>
                  *
                </Label>
                <Select
                  options={modeReglement}
                  isSearchable={true}
                  onChange={e => setSelectModeReglement(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <div
                  style={{ display: "flex", justifyContent: "space-arround" }}
                >
                  <Label for="basicpill-firstname-input1">
                    Date référence du fournisseur
                  </Label>
                  {/*<Label style={{ color: "red", paddingLeft: "5px" }}>*</Label>*/}
                </div>
                <Col md={12} className="pr-0">
                  <DatePicker
                    className="form-control ddate"
                    selected={dateReference}
                    onChange={e => setDateReference(e)}
                    dateFormat="dd/MM/yyyy"
                  />
                </Col>
              </div>
            </Col>
           {/* <Col md={6}>
              <div style={{ display: "flex", justifyContent: "space-arround" }}>
                <Label for="basicpill-firstname-input1">
                  Montant référence du fournisseur
                </Label>
                <Label style={{ color: "red", paddingLeft: "5px" }}>*</Label>
              </div>
              <div className="mb-3">
                <Input
                  placeholder="Montant"
                  type="number"
                  className="form-control"
                  onChange={e => setMontantRef(e.target.value)}
                />
              </div>
  </Col>*/}
          </Row>
        </Form>
        <div
          className="mb-4"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {" "}
          <div className="text-center mt-4">
            <button
              type="button"
              style={{
                backgroundColor: "#761C19",
                borderColor: "#761C19",
                color: "white",
              }}
              className="btn mb-2 me-2"
              onClick={display}
            >
              Afficher
            </button>
          </div>
        </div>
        {displayBlock ? (
          <Fragment>
            {/* <div
              className="col-md-12 mt-4"
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <div className="form-group">
                <button
                  onClick={tousFact}
                  className="btn btn-success btn btn-success"
                  type="submit"
                >
                  Tous facturer
                </button>
              </div>
            </div> */}
            <div className="row mt-4">
              <table
                style={{ marginTop: "10px" }}
                className="table table-bordered"
              >
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }} scope="col">
                      Numéro piéce
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Date piéce
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Montant TTC
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Facturation
                    </th>
                  </tr>
                </thead>
                {arrayProduit.map((el, index) => (
                  <tbody key={index}>
                    <tr>
                      <td style={{ textAlign: "center" }}>
                        <p
                          style={{
                            textAlign: "center",
                            borderRadius: "5px",
                          }}
                        >
                          {el.numBonLiv}
                        </p>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <p
                          style={{
                            textAlign: "center",
                            borderRadius: "5px",
                          }}
                        >
                          {el.dateBonLiv}
                        </p>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <p
                          style={{
                            textAlign: "center",
                            borderRadius: "5px",
                          }}
                        >
                          {el.mntTtc}
                        </p>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <label style={{ paddingRight: "10px" }}>
                          {" "}
                          Facturer
                        </label>
                        <Input
                          type="checkbox"
                          className="form-check-Input"
                          onChange={e => coche(e, index, el.id)}
                          checked={el.etat}
                        />
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <Col lg="6">
                <div className="text-center mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary "
                    onClick={facturer}
                  >
                    Facturer
                  </button>
                </div>
              </Col>
            </div>
            {displayBlockTotal ? (
              <Fragment>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-3">Total </CardTitle>
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <tbody>
                          <tr>
                            <td>Total des remises :</td>
                            <td style={{ fontWeight: "bold" }}>
                              {totalRemise}
                            </td>
                          </tr>
                          <tr>
                            <td>Total HT :</td>
                            <td style={{ fontWeight: "bold" }}>{totalHT}</td>
                          </tr>
                          <tr>
                            <td>Total fodec :</td>
                            <td style={{ fontWeight: "bold" }}>{totalFodec}</td>
                          </tr>
                          <tr>
                            <td>Total TVA :</td>
                            <td style={{ fontWeight: "bold" }}>{totalTva}</td>
                          </tr>
                          <tr>
                            <td>Avance/Impot :</td>
                            <td style={{ fontWeight: "bold" }}>{""}</td>
                          </tr>
                          <tr>
                            <td>Total TTC : </td>
                            <td style={{ fontWeight: "bold" }}>{totalTTC}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {" "}
                  <Col lg="6">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                      className="text-center mt-4"
                    >
                      <Button
                        type="button"
                        color="warning"
                        className="btn btn-warning  mb-2 me-2"
                        onClick={props.back}
                      >
                        Annuler
                      </Button>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                      className="text-center mt-4"
                    >
                      <button
                        type="submit"
                        className="btn btn-primary "
                        disabled={buttonClicked}
                        onClick={save}
                      >
                        CONFIRMER
                      </button>
                    </div>
                  </Col>
                </div>
              </Fragment>
            ) : null}
          </Fragment>
        ) : null}
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default AddFactureLivraison
AddFactureLivraison.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
