import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { withRouter } from "react-router";
import SocieteParam from "./societeParam"; 

const Societe = props => {
  const [userdata, setUserdata] = useState([]);
  const [perm, setPerm] = useState({
    add: 0,
    delete: 0,
    edit: 0,
    imp: 0,
    view: 0
  });

  useEffect(() => {
    const userAuth = JSON.parse(localStorage.getItem('userAuth'));
    setUserdata(userAuth.user.userData);
  }, []);

  useEffect(() => {
    const typeArticleSubMenu = userdata.find(user =>
      user.sous_menus.some(subMenu => subMenu.sous_menu === "Parametrage societe")
    );

    if (typeArticleSubMenu) {
      const typeArticlePermission = typeArticleSubMenu.sous_menus.find(
        subMenu => subMenu.sous_menu === "Parametrage societe"
      ).permissions[0];
      
      setPerm(prevPerm => ({
        ...prevPerm,
        add: typeArticlePermission.add,
        delete: typeArticlePermission.delete,
        edit: typeArticlePermission.edit,
        imp: typeArticlePermission.imp,
        view: typeArticlePermission.view,
      }));
    }
  }, [userdata]);

  const [section, setSection] = useState(1);

  const renderBlock = (section) => {
    if (section === 1 && perm.edit===1) {
      return <SocieteParam />; 
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Société | Cresus</title>
        </MetaTags>
        <Container fluid>
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 1,
                  })}
                  onClick={() => {
                    setSection(1);
                  }}
                >
                  {"Société"}
                </NavLink>
              </NavItem>
            </Nav>
            <Card>
              <CardBody>
                {renderBlock(section)}
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Societe);
