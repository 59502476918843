import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import {
    Col, Label, Input,
} from "reactstrap";
import Select from "react-select"

import DatePicker from "react-datepicker";
import API from '../../api';
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"

const StatistiqueSuivi = () => {
    const [etatPercentages, setEtatPercentages] = useState([]);
    //const [etatFacturationPercentages, setEtatFacturationPercentages] = useState([]);
    const [stat, setStat] = useState([]);
    const [dateDebut, setDateDebut] = useState("")
    const [dateFin, setDateFin] = useState("")

    const [displayBlock, setDisplayBlock] = useState(false);
    
    const [montant_deja_encaisse, setMontant_deja_encaisse]= useState("")
    const [montant_a_encaisse, setMontant_a_encaisse]= useState("")
    const [nb_facture_a_encaisse, setNb_facture_a_encaisse]= useState("")
    const [nb_facture_deja_encaisse, setNb_facture_deja_encaisse]= useState("")


    const [montant_deja_paye, setMontant_deja_paye]= useState("")
    const [montant_a_paye, setMontant_a_paye]= useState("")
    const [nb_facture_a_paye, setNb_facture_a_paye]= useState("")
    const [nb_facture_deja_paye, setNb_facture_deja_paye]= useState("")




    
    const [Operation_financière,setOperation_financière]=useState("")



    const options = [
        { value: 'encaissement', label: "encaissement" },
        { value: 'paiement', label: "paiement" }
      ]
    const [selectedOption, setSelectedOption] = useState(null);
    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };



    const getOptionEtat = () => {
        return {
            toolbox: {
                show: false,
            },
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} ({d}%)',
            },
            legend: {
                orient: 'vertical',
                left: '10px',
                top: 'top',
                data: etatPercentages.map(item => item.name),
                textStyle: {
                    color: '#74788d',
                },
            },
            color: ['#2D3C7F','#D81818'],
            series: [
                {
                    name: 'Total sales',
                    type: 'pie',
                    radius: '55%',
                    center: ['50%', '50%'],
                    data: etatPercentages,
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                },
            ],
        };
    };



    const handleClickAfficher = async () => {
        if (!selectedOption) {
            toast.error("⛔Veuillez sélectionner une opération financière", {
                containerId: "A",
            });
            return;
        }
        if (dateDebut && dateFin) {
            if(selectedOption.value==='encaissement'){
                const response = await API.post('banque/matricule/suivie_stat_encaissement', {
                    dateDeb: dateDebut,
                    dateFin: dateFin,
                   // ValSelect:selectedOption.value
                });

                const data = response.data.etatPercentages;
             
                   setMontant_a_encaisse(response.data.montant_a_encaisse)
                   setMontant_deja_encaisse(response.data.montant_deja_encaisse)
                   setNb_facture_a_encaisse(response.data.nb_facture_a_encaisse)
                   setNb_facture_deja_encaisse(response.data.nb_facture_deja_encaisse)
                    setOperation_financière(1)
                    const formattedData = Object.entries(data).map(([key, value]) => ({
                        name: key,
                        value: parseFloat(value.replace('%', '')),
                    }));
                    setEtatPercentages(formattedData);

                
                }

                else if (selectedOption.value==='paiement'){
                    const response = await API.post('banque/matricule/suivie_stat_paiement', {
                        dateDeb: dateDebut,
                        dateFin: dateFin,
                       // ValSelect:selectedOption.value
                    });
                    const data = response.data.etatPercentages;

                    setMontant_a_paye(response.data.montant_a_paye)
                    setMontant_deja_paye(response.data.montant_deja_paye)
                    setNb_facture_a_paye(response.data.nb_facture_a_paye)
                    setNb_facture_deja_paye(response.data.nb_facture_deja_paye)

                    setOperation_financière(2)
                    const formattedData = Object.entries(data).map(([key, value]) => ({
                        name: key,
                        value: parseFloat(value.replace('%', '')),
                    }));
                    setEtatPercentages(formattedData);
                }
                  

                setDisplayBlock(true);
           
        } else {
            toast.error("⛔Veuillez sélectionner une date de début et une date de fin", {
                containerId: "A",
            })
        }
    }

    return (
        <div>
            <div style={{ display: 'flex', marginBottom: '20px', height: '180px' }}>
                <div className="mb-3" style={{ marginRight: '20px' }}>
                    <Label for="basicpill-lastname-input2">Date Début</Label>
                    <DatePicker
                        className="form-control date"
                        selected={dateDebut}
                        onChange={date => setDateDebut(date)}
                        dateFormat="dd/MM/yyyy"
                        required
                    />
                </div>

                <div className="mb-3" style={{ marginRight: '20px', marginLeft: '20px' }}>
                    <Label for="basicpill-lastname-input2">Date Fin</Label>
                    <DatePicker
                        className="form-control date"
                        selected={dateFin}
                        onChange={date => setDateFin(date)}
                        dateFormat="dd/MM/yyyy"
                        required
                    />
                </div>

                <div className="mb-3" style={{ marginRight: '20px', marginLeft: '20px'  ,width: '300px'}}>
                    <Label for="basicpill-lastname-input2">Opération Financière</Label>
                    <Select
                        value={selectedOption}
                        onChange={handleChange}
                        options={options}
                        required
                         
                    />
                </div>
            </div>

            <div className="mb-4" style={{ display: "flex", justifyContent: "center" }}>
                <div className="text-center mt">
                    <button
                        type="button"
                        style={{
                            backgroundColor: "#761C19",
                            borderColor: "#761C19",
                            color: "white",
                        }}
                        className="btn mb-1 me-1"
                        onClick={handleClickAfficher}
                    >
                        Afficher
                    </button>
                </div>
            </div>

            {displayBlock ? (

                <div style={{ display: 'flex' }}>

                    <div style={{ flex: 1, marginRight: '20px' }}>
                    {Operation_financière===1 ?(
                        <div style={{ display: 'flex', marginBottom: '20px' }}>
                            <div className="mb-1" style={{ marginRight: '20px' }}>
                                <Label for="basicpill-lastname-input2">{"Nombre des factures a encaisser :"}</Label>
                                <p>{nb_facture_a_encaisse}</p>
                            </div>

                            <div className="mb-1" style={{ marginRight: '20px' }}>
                                <Label for="basicpill-lastname-input2">{"Nombre des factures déjà encaissé :"}</Label>
                                <p>{nb_facture_deja_encaisse}</p>
                            </div>

                           
                           
                        </div> 
                         ): null}


                            {Operation_financière===2 ?(
                        <div style={{ display: 'flex', marginBottom: '20px' }}>
                             <div className="mb-1" style={{ marginRight: '20px' }}>
                                <Label for="basicpill-lastname-input2">{"Nombre des factures a payer :"}</Label>
                                <p>{nb_facture_a_paye}</p>
                            </div>

                            <div className="mb-1" style={{ marginRight: '20px' }}>
                                <Label for="basicpill-lastname-input2">{"Nombre des factures déjà payé :"}</Label>
                                <p>{nb_facture_deja_paye}</p>
                            </div>

                           

                            
                           
                        </div> 
                         ): null}    
                       
                        <p>Financier</p>

                        <ReactEcharts style={{ height: '350px' }} option={getOptionEtat()} />
                    </div>
                </div>
            ) : null}
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </div>
    );
};

export default StatistiqueSuivi;