/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, Fragment } from "react"
import Select from "react-select"
import API from "../../api"
import PropTypes from "prop-types"

import {
  Col,
  Form,
  Label,
  Row,
  CardTitle,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
// Checkbox
import Checkbox from "rc-checkbox"
import "rc-checkbox/assets/index.css"

const ModifReglementFournisseur= props => {
    const [id, setId] = useState("")
    const [disbutReg, setDisbutReg] = useState(true)
    const [fournisseur, setFournisseur] = useState([])
    const [selectFournisseur, setSelectFournisseur] = useState("")
    const [date, setDate] = useState(new Date())
    const [dateEsp, setDateEsp] = useState("")
    const [dateEch, setDateEch] = useState("")
    const [dateEnc, setDateEnc] = useState("")
    const [listFacture, setListFacture] = useState([])
    const [blockList, setBlockList] = useState(false)
    const [disable, setDisable] = useState(false)
    const [mntTotal, setMntTotal] = useState(0)
    const [modePay, setModePay] = useState([])
    const [banque, setBanque] = useState([])
    const [compte, setCompte] = useState([])
    const [modal, setModal] = useState(false)
    const [indexLigne, setIndexLigne] = useState("")
    const [taux, setTaux] = useState([])
    const [arrayIdFacture, setArrayIdFacture] = useState([])
    const [blockPaiement, setBlockPaiement] = useState(false)
    const [facture, setFacture] = useState([])
    const [loading, setLoading] = useState(false)
    const [arrayModePaiement, setArrayModePaiement] = useState([
      {
        modPay: "",
        montant: "",
      },
    ])
   
    useEffect(async () => {
        var url = window.location.href
        var array = url.split("=")
        var id = array[1]
        setId(id)
        const resR = await API.post("reglement/fournisseur/get_by_id", { id }).then(resR => {
          // setDisbutReg()
           setSelectFournisseur(resR.data.ReglementFournisseur.fournisseur_id)
           setDate(resR.data.ReglementFournisseur.date)
          
           setArrayModePaiement(resR.data.ReglementFournisseur.arrayModePaiement)
          
          setLoading(true)  
        })
     
      const res = await API.get("fournisseur/select").then(res => {
        setFournisseur(res.data.Fournisseur)
        
      })
      const resP = await API.get("paiement/select").then(resP => {
        setModePay(resP.data.TypePaiement
  )
      })
      const resB = await API.get("banque/matricule/select").then(resB => {
        setBanque(resB.data.MatriculeBancaire)
      })
      const resT = await API.get("taux/retenue/select").then(resT => {
        setTaux(resT.data.TauxRetenue)
      })
    }, [])
    

    
    useEffect(async () => {
      const filteredArray = listFacture.filter(item =>  item.mnt > 0);
      setArrayModePaiement(filteredArray);
      
          },[listFacture])
         
  
    // Ajout Ligne
    const addLigne = () => {
      let element = {
        modPay: "",
        montant: "",
      }
      setArrayModePaiement([...arrayModePaiement, element])
    }
  
    const toggle = indexOfLigne => {
      setModal(!modal)
      setIndexLigne(indexOfLigne)
    }
  
    const toggleDelete = async () => {
      arrayModePaiement.splice(indexLigne, 1)
      setModal(false)
    }
  
    const addFacture = (e, indexOfFacture) => {
      //var facture_id = e.value
      var facture_id = e.value
      setArrayModePaiement(
        arrayModePaiement.map((el, id) =>
          id === indexOfFacture
            ? Object.assign(el, {
                facture_id: facture_id,
                facture:e
              })
            : el
        )
      )
    }
  
    // e : selectedmodepay

    // <Select
    // isClearable={true}
    // isSearchable={true}
    // onChange={(e) => this.addModePaiement(e, index)}
    // options={this.state.listemode}/>


  //   addModePaiement = (selectedModPay, indexModPay) => {
  //     if (selectedModPay != null) {
  //         let mode = selectedModPay;
  //         this.setState({
  //             listepaiement: this.state.listepaiement.map((el, id) => (id === indexModPay ? Object.assign(el, { mode: mode }) : el))
  //         },
  //         ()=>{
  //         });
  //     }
  // }
    const addModePay = (e, indexOfModePay) => {
      var modePay = e
      
      setArrayModePaiement(
        arrayModePaiement.map((el, id) =>
          id === indexOfModePay
            ? Object.assign(el, {
                modPay: modePay.value,
                modedepaiement:e
               
              })
            : el
        )
        
      )
    }
  //   addMntModPay = (event, indexModPay) => {
  //     let montantPaiement = event.target.value;
  //     this.setState({
  //         listepaiement: this.state.listepaiement.map((el, id) => (id === indexModPay ? Object.assign(el, { montantPaiement: montantPaiement }) : el))
  //     });
  // }
  
    const addMnt = (e, indexOfMnt) => {
      var mnt = e.target.value
      setArrayModePaiement(
        arrayModePaiement.map((el, id) =>
          id === indexOfMnt
            ? Object.assign(el, {
              montantdejapaye: mnt,
              })
            : el
        )
      )
    }
  
    const addDate = (e, indexDate) => {
      var date = e
      if (date != "") {
        let dateEsp = date
        let month = "" + (dateEsp.getMonth() + 1)
        let day = "" + dateEsp.getDate()
        let year = dateEsp.getFullYear()
        if (month.length < 2) month = "0" + month
        if (day.length < 2) day = "0" + day
        var convertDate = [year, month, day].join("-")
      } else {
        var convertDate = ""
      }
      setArrayModePaiement(
        arrayModePaiement.map((el, id) =>
          id === indexDate
            ? Object.assign(el, {
                dateEsp: convertDate,
              })
            : el
        )
      )
    }
  
    const addDateEch = (e, indexOfDateEch) => {
      var date = e
      if (date != "") {
        let dateEch = date
        let month = "" + (dateEch.getMonth() + 1)
        let day = "" + dateEch.getDate()
        let year = dateEch.getFullYear()
        if (month.length < 2) month = "0" + month
        if (day.length < 2) day = "0" + day
        var convertDate = [year, month, day].join("-")
      } else {
        var convertDate = ""
      }
      setArrayModePaiement(
        arrayModePaiement.map((el, id) =>
          id === indexOfDateEch
            ? Object.assign(el, {
                dateEch: convertDate,
              })
            : el
        )
      )
    }
  
    const addNumCheque = (e, indexOfNumCheq) => {
      var numCheque = e.target.value
      setArrayModePaiement(
        arrayModePaiement.map((el, id) =>
          id === indexOfNumCheq
            ? Object.assign(el, {
                numCheque: numCheque,
              })
            : el
        )
      )
    }
  
    const addBanque = async (e, indexOfBanque) => {
      var selectBanque = e.value
      //var selectBanque = e.value
      // const res = await API.post("get_compte", {
      //   banque_id: selectBanque,
      // }).then(res => {
      //   setCompte(res.data.List)
      // })
      setArrayModePaiement(
        arrayModePaiement.map((el, id) =>
          id === indexOfBanque
            ? Object.assign(el, {
                banque: e,
              })
            : el
        )
      )
    }
  
    // const addCompte = (e, indexOfCompte) => {
    //   var selectCompte = e.value
    //   setArrayModePaiement(
    //     arrayModePaiement.map((el, id) =>
    //       id === indexOfCompte
    //         ? Object.assign(el, {
    //             compte: selectCompte,
    //           })
    //         : el
    //     )
    //   )
    // }
  
    const addDateEnc = (e, indexOfDateEnc) => {
      var date = e
      if (date != "") {
        let dateEnc = date
        let month = "" + (dateEnc.getMonth() + 1)
        let day = "" + dateEnc.getDate()
        let year = dateEnc.getFullYear()
        if (month.length < 2) month = "0" + month
        if (day.length < 2) day = "0" + day
        var convertDate = [year, month, day].join("-")
      } else {
        var convertDate = ""
      }
      setArrayModePaiement(
        arrayModePaiement.map((el, id) =>
          id === indexOfDateEnc
            ? Object.assign(el, {
                encaissement: convertDate,
              })
            : el
        )
      )
    }
  
    const addTaux = (e, indexOfTaux) => {
      var taux = parseFloat(e.label)
      var taux_id = e.value
      var montant = arrayModePaiement[indexOfTaux].montant
      var mntTaux = (montant * taux) / 100
      var montantNet = montant - mntTaux
      setArrayModePaiement(
        arrayModePaiement.map((el, id) =>
          id === indexOfTaux
            ? Object.assign(el, {
                taux: taux_id,
                mntTaux: mntTaux,
                montantNet: montantNet,
              })
            : el
        )
      )
    }
  
    const search = async () => {
      if (selectFournisseur != null) {
        var fournisseur_id = selectFournisseur.value
        const res = await API.post("reglement/fournisseur/list_fr_edit", {
          id: id,
        }).then(res => {
          setListFacture(res.data.List)

          setListFacture(prevListFacture => {
            const updatedListFacture = [...prevListFacture];
            for (let i = 0; i < updatedListFacture.length; i++) {
              const facture = updatedListFacture[i];
              updatedListFacture[i] = {
                ...facture,
                checked: facture.mntReste === 0 ,
              };
            }
            var sommeMnt = updatedListFacture.reduce((acc, facture) => acc + parseFloat(facture.mnt), 0);
             setMntTotal(sommeMnt)
            return updatedListFacture;
          });
         


          setBlockList(true)
        })
      }
    }
  
    const handleBlur = indexOfBlur => {
      var id = listFacture[indexOfBlur].id
      var array = arrayIdFacture
      array.push(id)
      var unique = [...new Set(array)]
      setArrayIdFacture(unique)
    }
  
    const editDate = date => {
      setDate(date)
    }
  
    const addMontant = (event, indexOfMontant) => {
      var mntPaiement = event.target.value
      var mntPaye = listFacture[indexOfMontant].mntPaye
      var mntAPaye = listFacture[indexOfMontant].mntAPaye
      var total = listFacture[indexOfMontant].mntTotal
      
      if (mntPaye == 0) {
       var diff=total-mntPaiement
        if (diff>=0) {
        
          var mntRestant = (total-mntPaye) - mntPaiement
          var ligne_reglement_id=listFacture[indexOfMontant].ligne_reglement_id;
          var modedepaiement1 = arrayModePaiement.filter(item => item.ligne_reglement_id === ligne_reglement_id);
         if(modedepaiement1[0]){
         
          
          setListFacture(
            listFacture.map((el, id) =>
              id === indexOfMontant
                ? Object.assign(el, {
                    mnt: mntPaiement,
                    mntReste: mntRestant,
                    modedepaiement:modedepaiement1[0].modedepaiement
                  })
                : el
            )
          )
          var mntTotal = 0
          for (let i = 0; i < listFacture.length; i++) {
          if(listFacture[i].mnt){
            mntTotal += parseFloat(listFacture[i].mnt)
          }
          }
          setMntTotal(Number.parseFloat(mntTotal).toFixed(3))
         }
          
        } 
        else {
          toast.error("⛔ Le montant est supérieur !", {
            containerId: "A",
          })
        }
      } else if (mntPaye > 0) {
        var resteMontant = total - mntPaye
        //if (parseFloat(mntAPaye) >= parseFloat(mntPaiement)) {
          var mntRestant = (total-mntPaye) - mntPaiement
          if(mntPaiement <= resteMontant){

          
          setListFacture(
            listFacture.map((el, id) =>
              id === indexOfMontant
                ? Object.assign(el, {
                    mnt: mntPaiement,
                    mntReste: mntRestant,
                  })
                : el
            )
          )
          let mntTotal = 0;
          for (let i = 0; i < listFacture.length; i++) {
              mntTotal += (listFacture[i].mnt !== '' ? parseFloat(listFacture[i].mnt) : 0);
          }
          setMntTotal(Number.parseFloat(mntTotal).toFixed(3));
          }
         else {
          toast.error("⛔ Le montant est supérieur !", {
            containerId: "A",
          })
        }
      }
    }
  
    const cocheMontant = (e, indexOfCoche) => {
              if (e.target.checked === true) {
                  setListFacture(
                      listFacture.map((el, id) =>
                          id === indexOfCoche
                              ? Object.assign(el, {
                                  checked: true,
                              })
                              : el
                      )
                  )
                  var montant = listFacture[indexOfCoche].mntReste
      
                  var diff = parseFloat(listFacture[indexOfCoche].mntTotal) - parseFloat( listFacture[indexOfCoche].mntPaye)
                 
                  diff =  parseFloat(diff.toFixed(3));
      
                  /* var montantRestant =
                       listFacture[indexOfCoche].mntAPaye -
                       (parseFloat(listFacture[indexOfCoche].mntPaye) + montant)*/
                  setListFacture(
                      listFacture.map((el, id) =>
                          id === indexOfCoche
                              ? Object.assign(el, {
                                  mnt: diff,
                                  mntReste: 0,
                              })
                              : el
                      )
                  )
                  let mntTotal = 0;
                  for (let i = 0; i < listFacture.length; i++) {
                      mntTotal += (listFacture[i].mnt !== '' ? parseFloat(listFacture[i].mnt) : 0);
                  }
                  setMntTotal(Number.parseFloat(mntTotal).toFixed(3));
                 /* const option = {
                      value: listFacture[indexOfCoche].id,
                      label: listFacture[indexOfCoche].numero,
                      montantPaye: listFacture[indexOfCoche].mnt,
                      modePaiement: null,
                      modePaiementOptions: null
                  }
      
                  var id = listFacture[indexOfCoche].id
                  var array = arraypaiement
                  array.push(option)
                  //var unique = [...new Set(array)]
                  setArraypaiement(array)*/
              } else {
                setListFacture(
                    listFacture.map((el, id) =>
                        id === indexOfCoche
                            ? Object.assign(el, {
                                checked: false,
                            })
                            : el
                    )
                )
                var montant = listFacture[indexOfCoche].mntReste
    
                var diff = parseFloat(listFacture[indexOfCoche].mntTotal) - parseFloat( listFacture[indexOfCoche].mntPaye)
               
                diff =  parseFloat(diff.toFixed(3));
    
                /* var montantRestant =
                     listFacture[indexOfCoche].mntAPaye -
                     (parseFloat(listFacture[indexOfCoche].mntPaye) + montant)*/
                setListFacture(
                    listFacture.map((el, id) =>
                        id === indexOfCoche
                            ? Object.assign(el, {
                                mnt: 0,
                                mntReste: diff,
                            })
                            : el
                    )
                )
                let mntTotal = 0;
                for (let i = 0; i < listFacture.length; i++) {
                    mntTotal += (listFacture[i].mnt !== '' ? parseFloat(listFacture[i].mnt) : 0);
                }
                setMntTotal(Number.parseFloat(mntTotal).toFixed(3));
               /* const option = {
                    value: listFacture[indexOfCoche].id,
                    label: listFacture[indexOfCoche].numero,
                    montantPaye: listFacture[indexOfCoche].mnt,
                    modePaiement: null,
                    modePaiementOptions: null
                }
    
                var id = listFacture[indexOfCoche].id
                var array = arraypaiement
                array.push(option)
                //var unique = [...new Set(array)]
                setArraypaiement(array)*/
              }
          }
        
  
    const pay = async () => {
      const res = await API.post("reglement/fournisseur/list_fact", {
        array_ModePaiement: arrayModePaiement,
      }).then(res => {
        setArrayModePaiement(res.data.List)
        setBlockPaiement(true)
        setBlockList(true)
      })
    }
  
    // const edit = async () => {
    //   var sommePaiement = 0
    //   sommePaiement = parseFloat(mntTotal)
     
    //   var sommeReg = 0
    //   for (let i = 0; i < arrayModePaiement.length; i++) {
    //     //sommeReg += parseFloat(arrayModePaiement[i].montant)
    //     //mntTotal
    //     sommeReg = parseFloat(mntTotal)
    //   }
    //  
      
    //   if (sommePaiement != sommeReg) {
    //     toast.error("⛔ Veuillez vérifier les sommes saisie !", {
    //       containerId: "A",
    //     })
    //   } else {
    //     setDisbutReg(false)
    //     var userAuth = JSON.parse(localStorage.getItem("userAuth"))
    //     var userId = userAuth.user.id
    //     
    //     if (date != "") {
    //       let dateReg = date
    //       let month = "" + (dateReg.getMonth() + 1)
    //       let day = "" + dateReg.getDate()
    //       let year = dateReg.getFullYear()
    //       if (month.length < 2) month = "0" + month
    //       if (day.length < 2) day = "0" + day
    //       var convertDate = [year, month, day].join("-")
    //     } else {
    //       var convertDate = ""
    //     }
    //     var fournisseur_id = selectFournisseur.value
    //     const res = await API.post("reglement/fournisseur/edit", {
    //       user_id: userId,
    //       date: convertDate,
    //       fournisseur_id: fournisseur_id,
    //       total: mntTotal,
    //       LigneFacture: listFacture,
    //       LigneModePay: arrayModePaiement,
    //     }).then(res => {
    //       props.setSection(1)
    //     })
    //   }
    // }
    
        const edit = async () => {
      var sommePaiement = 0
      sommePaiement = parseFloat(mntTotal)
     if (arrayModePaiement.length ==0) {
      toast.error("⛔ Veuillez vérifier votre réglement!", {
        containerId: "A",
      })
     }
      var sommeReg = 0
      for (let i = 0; i < arrayModePaiement.length; i++) {
        //sommeReg += parseFloat(arrayModePaiement[i].montant)
        //mntTotal
        sommeReg = parseFloat(mntTotal)
      }
      
      if (sommePaiement != sommeReg) {
        toast.error("⛔ Veuillez vérifier les sommes saisie !", {
          containerId: "A",
        })
      } else {
        setDisbutReg(false)
        var userAuth = JSON.parse(localStorage.getItem("userAuth"))
        var userId = userAuth.user.id
        
  
        // if (date != "") {
        //   let dateReg = date
        //   let month = "" + (dateReg.getMonth() + 1)
        //   let day = "" + dateReg.getDate()
        //   let year = dateReg.getFullYear()
        //   if (month.length < 2) month = "0" + month
        //   if (day.length < 2) day = "0" + day
        //   var convertDate = [year, month, day].join("-")
        // } else {
        //   var convertDate = ""
        // }
        
        var fournisseur_id = selectFournisseur.value
        const res = await API.post("reglement/fournisseur/edit", {
          id: id,
          user_id: userId,
          // date: convertDate,
          fournisseur_id: fournisseur_id,
          total: mntTotal,
          LigneFacture: listFacture,
          LigneModePay: arrayModePaiement,
        }).then(res => {
          props.history.push("/ReglementFournisseur")

          
         
        })
      }
    }

  return (
    <React.Fragment>
    <div  className="page-content card">

        <Form >
      <Row>
        <Col lg="6">
          <div className="mb-3">
            <Label for="basicpill-firstname-input1">Fournisseur</Label>
            <Select
              options={fournisseur}
              value={selectFournisseur}
              isSearchable={true}
              isDisabled={true}
              //onChange={e => setSelectFournisseur(e)}
            />
          </div>
        </Col>
        <Col lg="6">
          <div className="mb-3">
            <Label for="basicpill-lastname-input2">Date</Label>
            <Col md={12} className="pr-0">
              <DatePicker
                className="form-control ddate"
                value={date}
                onChange={editDate}
                dateFormat="dd/MM/yyyy"
                isDisabled={true}
              />
            </Col>
          </div>
        </Col>
      </Row>
    </Form>
    <div
      className="mb-4"
      style={{ display: "flex", justifyContent: "center" }}
    >
      {" "}
      <div className="text-center mt-4">
        <button
          type="button"
          style={{
            backgroundColor: "#761C19",
            borderColor: "#761C19",
            color: "white",
          }}
          className="btn mb-2 me-2"
          onClick={search}
        >
          Afficher
        </button>
      </div>
    </div>
    {blockList === true ? (
      <Fragment>
        {" "}
        <table className="table mt-4">
          <thead className="table-light">
            <tr>
              <th style={{ textAlign: "center" }} scope="col">
                Num Facture
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Date
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Montant HT
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Montant TTC
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Montant déja payé
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Montant a payé
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Montant restant
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Cocher
              </th>
            </tr>
          </thead>
          {listFacture.map((el, index) => (
            <tbody key={el.id}>
              <tr>
                <td className="col-md-2" style={{ textAlign: "center" }}>
                  <p
                    style={{
                      color: "#5784BA",
                      textDecoration: "underline",
                      textAlign: "center",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      const url = `/DetailFactureDirectFournisseur?id=${el.facture_id}`;
                      window.location.href = url;
                    }}
                  >
                    {el.numero}
                  </p>
                </td>
                <td className="col-md-2" style={{ textAlign: "center" }}>
                  <p
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {el.date}
                  </p>
                </td>
                <td className="col-md-2" style={{ textAlign: "center" }}>
                  <p
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {Number.parseFloat(el.totalHT).toFixed(3)}
                  </p>
                </td>
                <td className="col-md-2" style={{ textAlign: "center" }}>
                  <p
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {Number.parseFloat(el.mntTotal).toFixed(3)}
                  </p>
                </td>
                <td className="col-md-2" style={{ textAlign: "center" }}>
                  <p
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {Number.parseFloat(el.mntPaye).toFixed(3)}
                  </p>
                </td>
                <td className="col-md-2" style={{ textAlign: "center" }}>
                  <input
                    type="number"
                    value={el.mnt}
                    //onBlur={() => handleBlur(index)}
                    onChange={e => addMontant(e, index)}
                    style={{
                      textAlign: "center",
                      borderRadius: "5px",
                      border: "solid 1px #B3B3B3",
                    }}
                  />
                </td>
                <td className="col-md-2" style={{ textAlign: "center" }}>
                  <input
                    type="number"
                    disabled
                    value={Number.parseFloat(el.mntReste).toFixed(3)}
                    style={{
                      textAlign: "center",
                      borderRadius: "5px",
                    }}
                  />
                </td>
                <td className="col-md-1">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Checkbox
                      onChange={e => cocheMontant(e, index)}
                      checked={el.checked}
                      disabled={disable}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
        <table className="table mt-4">
          <tbody>
            <tr style={{ backgroundColor: "#f8f8fb" }}>
              <td style={{ fontWeight: "bold" }} className="col-md-2">
                {" "}
                Montant Total Payé :{" "}
              </td>
              <td className="col-md-2"> </td>
              <td className="col-md-2"> </td>
              <td className="col-md-2"> </td>
              <td
                style={{ fontWeight: "bold", textAlign: "center" }}
                className="col-md-1"
              >
                {mntTotal}
              </td>
              <td className="col-md-2"> </td>
              <td className="col-md-2"> </td>
              <td className="col-md-1"> </td>
            </tr>
          </tbody>
        </table>
        <div
          className="mb-4"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {" "}
          <div
            className="text-center mt-4"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <button type="button" className="btn btn-primary " onClick={pay}>
              Procédure de paiement
            </button>
          </div>
        </div>
        {blockPaiement === true ? (
          <Fragment>
            <Row className="mb-2">
              <Col sm="4">
                <CardTitle
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="h4 mt-4"
                >
                  Mode de paiement :{" "}
                </CardTitle>
              </Col>
             
            </Row>
            <Row>
              {arrayModePaiement.map((el, index) => (
                <Col key={index} lg="12" className="pt-4">
                  <Form>
                    <div className="row mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Facture
                      </Label>
                      <Col sm={9}>
                      <Input
                                                            
                      value={el.numero}
                     
                        />
                      </Col>
                    </div>
                    <div className="row mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Mode de paiement
                      </Label>
                      <Col sm={9}>
                        <Select
                          options={modePay}
                          value={el.modedepaiement}
                          isSearchable={true}
                          onChange={e => addModePay(e, index)}
                        />
  {/* <Select
    isClearable={true}
    isSearchable={true}
    onChange={(e) => this.addModePaiement(e, index)}
    options={this.state.listemode}/>
 */}

                      </Col>
                    </div>
                    <div className="row mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Montant
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="number"
                          className="form-control"
                          placeholder="..."
                          value={el.mnt}
                          onChange={e => addMnt(e, index)}
                        />
                      </Col>
                    </div>
                    {el.modPay === 1 ? (
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Date
                        </Label>
                        <Col md={9} className="pr-0">
                          <DatePicker
                            placeholderText="__/__/____"
                            className="form-control ddate"
                            selected={dateEsp}
                            value={el.dateEsp}
                            onChange={e => addDate(e, index)}
                            dateFormat="dd/MM/yyyy"
                          />
                        </Col>
                      </div>
                    ) : null}
                    {el.modPay === 2 ? (
                      <Fragment>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            Echeance
                          </Label>
                          <Col sm={9}>
                            <DatePicker
                              placeholderText="__/__/____"
                              className="form-control ddate"
                              selected={dateEch}
                              value={el.dateEch}
                              onChange={e => addDateEch(e, index)}
                              dateFormat="dd/MM/yyyy"
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            Numéro cheque
                          </Label>
                          <Col sm={9}>
                          <Input
                          value={el.numCheque}
                              type="number"
                              className="form-control"
                              placeholder="..."
                              onChange={e => addNumCheque(e, index)}
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {/* banque : code + desg(rib) */}
                            Banque
                          </Label>
                          <Col sm={9}>
                            <Select
                              options={banque}
                              value={el.banque}
                              isSearchable={true}
                              onChange={e => addBanque(e, index)}
                            />
                          </Col>
                        </div>
                        {/* <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            RIB
                          </Label>
                          <Col sm={9}>
                            <input
                              value={el.banque}
                              isSearchable={true}
                              onChange={e => addCompte(e, index)}
                            />
                          </Col>
                        </div> */}
                      </Fragment>
                    ) : el.modPay === 3 ? (
                      <Fragment>
                     

                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            Date Encaisement
                          </Label>
                          <Col sm={9}>
                            <DatePicker
                              className="form-control ddate"
                              selected={dateEnc}
                              value={el.encaissement}
                              onChange={e => addDateEnc(e, index)}
                              dateFormat="dd/MM/yyyy"
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            Numéro cheque
                          </Label>
                          <Col sm={9}>
                          <Input
                          value={el.numCheque}
                              type="number"
                              className="form-control"
                              placeholder="..."
                              onChange={e => addNumCheque(e, index)}
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            Banque
                          </Label>
                          <Col sm={9}>
                          <Select
                              options={banque}
                              value={el.banque}
                              isSearchable={true}
                              onChange={e => addBanque(e, index)}
                            />
                          </Col>
                        </div>
                        {/* <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            RIB
                          </Label>
                          <Col sm={9}>
                            <Select
                              options={compte}
                              isSearchable={true}
                              onChange={e => addCompte(e, index)}
                            />
                          </Col>
                        </div> */}
                      </Fragment>
                    ) : el.modPay === 4 ? (
                      <Fragment>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            Echeance
                          </Label>
                          <Col sm={9}>
                            <DatePicker
                              placeholderText="__/__/____"
                              className="form-control ddate"
                              selected={dateEch}
                              value={el.dateEch}
                              onChange={e => addDateEch(e, index)}
                              dateFormat="dd/MM/yyyy"
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            Numéro cheque
                          </Label>
                          <Col sm={9}>
                          <Input
                              value={el.numCheque}
                              type="number"
                              className="form-control"
                              placeholder="..."
                              onChange={e => addNumCheque(e, index)}
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            Banque
                          </Label>
                          <Col sm={9}>
                            <Select
                            value={el.banque.value}
                              options={banque}
                              isSearchable={true}
                              onChange={e => addBanque(e, index)}
                            />
                          </Col>
                        </div>
                        {/* <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            RIB
                          </Label>
                          <Col sm={9}>
                            <Select
                              value={el.banque.value}
                              isSearchable={true}
                              onChange={e => addCompte(e, index)}
                            />
                          </Col>
                        </div> */}
                      </Fragment>
                    ) : el.modPay === 5 ? (
                      <Fragment>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            Taux
                          </Label>
                          <Col sm={9}>
                            <Select
                              options={taux}
                              isSearchable={true}
                              onChange={e => addTaux(e, index)}
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            Montant taux
                          </Label>
                          <Col sm={9}>
                            <Input
                              type="number"
                              className="form-control"
                              placeholder="..."
                              value={el.mntTaux}
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            Montant Net
                          </Label>
                          <Col sm={9}>
                            <Input
                              type="number"
                              className="form-control"
                              placeholder="..."
                              value={el.montantNet}
                            />
                          </Col>
                        </div>
                      </Fragment>
                    ) : null}
                  </Form>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <i
                      style={{ color: "#cc0000", cursor: "pointer" }}
                      className="mdi mdi-delete font-size-18"
                      id="deletetooltip"
                      onClick={() => toggle(index)}
                    />
                  </div>{" "}
                  <div style={{ borderBottom: "1px solid #E4E9E2" }}></div>{" "}
                </Col>
              ))}
            </Row>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  className="text-center mt-4"
                >
                  <Button
                    type="button"
                    color="warning"
                    className="btn btn-warning  mb-2 me-2"
                    onClick={() => props.history.push("/ReglementFournisseur")}
                  >
                    Annuler
                  </Button>
                </div>
              </Col>
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
                >
                  <button
                    disabled={!disbutReg}
                    type="button"
                    className="btn btn-primary "
                    onClick={edit}
                  >
                    CONFIRMER
                  </button>
                </div>
              </Col>
            </div>
          </Fragment>
        ) : null}
      </Fragment>
    ) : null}
    <Modal isOpen={modal} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle} tag="h4">
        Suppression ligne{" "}
      </ModalHeader>
      <ModalBody>
        <div
          style={{
            fontFamily: "Montserrat, sans-serif",
            FontSize: "14px",
            FontWeight: "700",
            LineHeight: "18.375px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
          </div>
          <div
            className="hvr-push"
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: "15px",
            }}
          >
            <div>
              <Button
                onClick={toggleDelete}
                color="info"
                className="btn-rounded "
              >
                <i
                  style={{ color: "white" }}
                  className="far fa-thumbs-up"
                ></i>
                Oui
              </Button>
            </div>
            <div>
              <Button
                onClick={toggle}
                color="danger"
                className="btn-rounded "
              >
                <i
                  style={{ color: "white" }}
                  className="far fa-thumbs-down"
                ></i>
                Non
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
    <ToastContainer
      transition={Slide}
      enableMultiContainer
      containerId={"A"}
      position={toast.POSITION.TOP_RIGHT}
      autoClose={2500}
    />
        </div>
   
  </React.Fragment>
  )
}



export default ModifReglementFournisseur
ModifReglementFournisseur.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
