import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Input, Label } from 'reactstrap';
import API from '../../api'
import { Search } from "react-bootstrap-table2-toolkit"

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"


const TransferList = (props) => {
  const [articles, setArticles] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [depots, setDepots] = useState([]);
  const [clicked_article, setClickedArticle] = useState(0);
  const [filterElement, setFilterElement] = useState("");
  const [initArticle, setInitArticle] = useState([])
  const [userdata, setUserdata] = useState([])
  const [perm, setPerm] = useState({
    add: 0,
    delete: 0,
    edit: 0,
    imp: 0,
    view: 0
  });


  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('userAuth'));
    setUserdata(userAuth.user.userData)
  }, [])

  useEffect(() => {
    const typeArticleSubMenu = userdata.find(user =>
      user.sous_menus.some(subMenu => subMenu.sous_menu === "Inventaire et alimentation du stock")
    );

    if (typeArticleSubMenu) {
      const typeArticlePermission = typeArticleSubMenu.sous_menus.find(
        subMenu => subMenu.sous_menu === "Inventaire et alimentation du stock"
      ).permissions[0];
      setPerm(prevPerm => ({
        ...prevPerm,
        add: typeArticlePermission.add,
        delete: typeArticlePermission.delete,
        edit: typeArticlePermission.edit,
        imp: typeArticlePermission.imp,
        view: typeArticlePermission.view,
      }));
    }
  }, [userdata]);

  useEffect(async () => {
    const res = await API.get("article/index_all")
      .then(res => {
        setArticles(res.data.Article[0].Article)
        setInitArticle(res.data.Article[0].Article)
      })
  }, [])

  const handleArticleSelect = (article) => {
    setSelectedArticle(article);
    setShowPopup(true);
  };

  const show_article_depots = (article) => {
    setClickedArticle(clicked_article + 1)
    setSelectedArticle(article);
  };



  const handleQteGratuiteChange = (e, id) => {
    var newValue = e.target.value;
    if (e.target.value === "") {
      newValue = 0
    }
    const article_selectionne = initArticle.find(article => article.id === selectedArticle.id);
    const initialQte = article_selectionne.depots.find(depot => depot.id === id).qte;
    const initialQteGratuite = article_selectionne.depots.find(depot => depot.id === id).qte_gratuite;


    if (initialQte >= newValue) {
      var updatedDepots = []
      if (newValue < initialQteGratuite) {
        updatedDepots = selectedArticle.depots.map(depot => {
          if (depot.id === id) {
            return {
              ...depot, qte_gratuite: newValue,

              qte: initialQte + (initialQteGratuite - newValue)
            };
          }
          return depot;
        });
      }

      else {
        updatedDepots = selectedArticle.depots.map(depot => {
          if (depot.id === id) {
            return {
              ...depot, qte_gratuite: newValue,

              qte: initialQte - newValue
            };
          }
          return depot;
        });
      }

      const updatedSelectedArticle = { ...selectedArticle, depots: updatedDepots };

      setSelectedArticle(updatedSelectedArticle)

      const updatedArticles = articles.map(article => {
        if (article.id === selectedArticle.id) {
          return updatedSelectedArticle;
        }
        return article;
      });

      setArticles(updatedArticles);
      setDepots(updatedDepots);
    }
    else {
      toast.error("Verifier la quantite gratuite", {
        containerId: "A",
      })
    }






  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const { SearchBar } = Search;

  const handleSearch = value => {
    setFilterElement(value);
  };
  const save = async () => {
    const res = await API.post("stock/gestion_qte_gratuite", {
      articles: articles
    }).then(res => {
      window.location.reload()


    })
  }

  return (
    <div>
      <div className="search-box me-2 mb-2 d-inline-block">
        <div className="position-relative">
          <SearchBar onSearch={handleSearch} />
          <i className="bx bx-search-alt search-icon" />
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <div style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '10px', width: '45%', overflowY: 'auto', maxHeight: '600px' }}>
          <h2 style={{ marginBottom: '10px', textAlign: 'center', color: 'black' }}>Liste des Articles</h2>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {articles.map((article, index) => (
              <li
                key={article.id}
                style={{
                  marginBottom: '10px',
                  cursor: 'pointer',
                  padding: '10px',
                  borderRadius: '5px',
                  backgroundColor: (selectedArticle !== null && selectedArticle.id === article.id) ? '#00A6FF' : '#f0f0f0',
                  color: (selectedArticle !== null && selectedArticle.id === article.id) ? '#FFFFFF' : '#000000',
                  position: 'relative',
                  transition: 'background-color 0.3s ease, color 0.3s ease',
                }}
                onClick={() => show_article_depots(article)}
                onMouseEnter={(e) => {
                  if (selectedArticle === null || selectedArticle.id !== article.id) {
                    e.target.style.backgroundColor = '#00A6FF';
                    e.target.style.color = '#FFFFFF';
                  }
                }}
                onMouseLeave={(e) => {
                  if (selectedArticle === null || selectedArticle.id !== article.id) {
                    e.target.style.backgroundColor = '#f0f0f0';
                    e.target.style.color = '#000000';
                  }
                }}
              >
                {article.nom}
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleArticleSelect(article)}
                />
              </li>


            ))}
          </ul>
        </div>

        <div style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '10px', width: '45%' }}>
          <h2 style={{ marginBottom: '10px', textAlign: 'center', color: 'black' }}>Liste des Dépôts</h2>
          {selectedArticle && selectedArticle.depots && selectedArticle.depots.length > 0 && (
            <div>
              <h3 style={{ marginBottom: '10px' }}>{selectedArticle.title}</h3>
              <ul>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Quantite totale</th>
                      <th>Quantite gratuit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedArticle.depots.map((depot, index) => (
                      <tr key={depot.id}>
                        <td style={{ width: '20vh' }}>{depot.designation}</td>
                        <td><Input type='number' value={depot.qte} disabled={true} /></td>
                        <td><Input type='number' value={depot.qte_gratuite} onChange={(e) => handleQteGratuiteChange(e, depot.id)} /></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </ul>
            </div>
          )}

        </div>
        <Modal show={showPopup} onHide={handleClosePopup} centered>
          <Modal.Header closeButton>
            <Modal.Title>{selectedArticle && selectedArticle.nom}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>  Famille :{selectedArticle && selectedArticle.famille.name_fr}</p>
            <p>  Groupe :{selectedArticle && selectedArticle.groupe.name_fr}</p>
            <p>  Prix :{selectedArticle && selectedArticle.prix_vente_ht}</p>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClosePopup}>Fermer</Button>
          </Modal.Footer>
        </Modal>
      </div>
      {perm.edit === 1 &&
        <div style={{ display: "flex", justifyContent: "center" }} className="text-center mt-4">
          <button type="submit" className="btn btn-primary" onClick={save}>
            Confirmer
          </button>
        </div>
      }
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </div>
  );
};

export default TransferList;
TransferList.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.func.isRequired,
}

