import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import API from "../../../api"
import {
  Col,
  Spinner,
  Input,
  Row,
  Label,
  Form,
  Button,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardBody,
  Table,
} from "reactstrap"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Produits from "pages/Produits/Produits"

const AddDevisClient = props => {
  var userAuth = JSON.parse(localStorage.getItem("userAuth"))
  const user_id = userAuth.user.id
  const annee = userAuth.user.year

  //
  const [loading, setLoading] = useState(false)
  const [numDevis, setNumDevis] = useState("")
  const [dateDevis, setDateDevis] = useState(new Date())
  const [client, setClient] = useState([])
  const [selectCodeClient, setSelectCodeClient] = useState("")
  const [raisonSocial, setRaisonSocial] = useState("")
  const [adresse, setAdresse] = useState("")
  const [selectFamilleClient, setSelectFamilleClient] = useState("")
  const [etatFodec, setEtatFodec] = useState(0)
  const [codeTva, setCodeTva] = useState("")
  const [remiseExcep, setRemiseExcep] = useState("")
  const [selectRepre, setSelectRepre] = useState("")
  const [etatRegimeForf, setEtatRegimeForf] = useState(0)
  const [modeRegl, setModeRegl] = useState([])
  const [selectModeRegl, setSelectModeRegl] = useState("")
  const [modal, setModal] = useState(false)
  const [indexLigne, setIndexLigne] = useState("")
  const [errorMessage, setErrorMessage] = useState('');
  const [reloadData, setReloadData] = useState(false)

  const [arrayProduit, setArrayProduit] = useState([
    {
      produit_id: "",
      designation: "",
      qte: "",
      qte_gratuit: "",
      prix_ht: "",
      remise: "",
      fodec: "",
      montant_ht: 0,
      tva: "",
      montant_ttc: 0,
      montant_remise: 0,
      montant_fodec: 0,
    },
  ])
  const [produits, setProduits] = useState([])
  const [selectProduit, setSelectProduit] = useState("")
  const [indexOfProduit, setIndexOfProduit] = useState("")
  //
  const [observation, setObservation] = useState("")
  const [totalRemise, setTotalRemise] = useState(0)
  const [remise, setRemise] = useState("")
  const [totalTva, setTotalTva] = useState("")
  const [quantite, setQuantite] = useState("")
  const [totalHT, setTotalHT] = useState(0)
  const [totalFodec, setTotalFodec] = useState(0)
  const [totalTTC, setTotalTTC] = useState(0)
  //
  const [prixHT, setPrixHT] = useState("")
  const [designation, setDesignation] = useState("")
  const [tva, setTva] = useState("")
  const [fodec, setFodec] = useState("")
  const [buttonClicked, setButtonClicked] = useState(false);

  const [taux_promotion_client, setTaux_promotion_client] = useState(0);
  const [ExistePromotionClient, setExistePromotionClient] = useState(false)
  const [ExistePromotionArticle, setExistePromotionArticle] = useState(false)
  const [selectedTypePromotion, setselectedTypePromotion] = useState("all")
  const [initTauxPromoClient, setinitTauxPromoClient] = useState(0)
  const [idPromo ,setIdPromo]= useState(0) 

  const [getClientWorked ,setGetClientWorked]= useState(0) 

  





  useEffect(async () => {
   
    /*const resN = await API.get("devi/num").then(resN => {
      setNumDevis(resN.data.DevisClient)
      setLoading(true)
    })*/

    setselectedTypePromotion(options[0])
    const resA = await API.post("devi/num", {
      annee: annee,
    }).then(resA => {
      setNumDevis(resA.data.DevisClient)
      setLoading(true)
    })
    const resT = await API.get("mode/reglement/select").then(rest => {
      setModeRegl(rest.data.ModeReglement)
    })
    const resVerif = await API.post("article/verif_exist_promos_article",
      {
        date: dateDevis
      }).then(resVerif => {
        setExistePromotionArticle(resVerif.data)

      })



    const res = await API.get("client/select").then(res => {
      setClient(res.data.Client)
    })
    if( getClientWorked > 0){
    getClient(selectCodeClient)}
    setReloadData(false)
    setGetClientWorked(getClientWorked+1)
  }, [dateDevis])





  const options = [

    { value: 'none', label: 'Select...' },
    { value: 'Articles', label: 'Sur articles' },
    { value: 'Client', label: 'Sur client' },

  ]
  const selectOptions = options.slice(1);

  const addLigne = () => {
    let element = {
      produit_id: "",
      designation: "",
      qte: "",
      qte_gratuit: "",
      prix_ht: "",
      remise: "",
      fodec: "",
      montant_ht: 0,
      tva: "",
      montant_ttc: 0,
      montant_remise: 0,
      montant_fodec: 0,
    }
    setArrayProduit([...arrayProduit, element])
  }

  const toggle = indexOfLigne => {
    setModal(!modal)
    setIndexLigne(indexOfLigne)
  }

  const toggleDelete = async () => {
    arrayProduit.splice(indexLigne, 1)
    setModal(false)
  }

  useEffect(() => {
    var tot_ht = arrayProduit
      .map(elem => elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ht = parseFloat(tot_ht).toFixed(3)
    setTotalHT(total_ht)
    var tot_ttc = arrayProduit
      .map(elem => elem.montant_ttc)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ttc = parseFloat(tot_ttc).toFixed(3)
    setTotalTTC((parseFloat(tot_ttc) - (parseFloat(tot_ttc) * (taux_promotion_client / 100))).toFixed(3))
    var tot_tva = arrayProduit
      .map(elem => elem.montant_ttc - elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_tva = parseFloat(tot_tva).toFixed(3)
    setTotalTva(total_tva)
    var tot_remise = arrayProduit
      .map(elem => elem.montant_remise)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_rem = parseFloat(tot_remise).toFixed(3)
    setTotalRemise(total_rem)
    var tot_fodec = arrayProduit
      .map(elem => elem.montant_fodec)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_fodec = parseFloat(tot_fodec).toFixed(3)
    setTotalFodec(total_fodec)
  }, [modal, selectCodeClient, taux_promotion_client])

  const getClient = async selectClient => {

    setArrayProduit([{
      produit_id: "",
      designation: "",
      qte: "",
      qte_gratuit: "",
      prix_ht: "",
      remise: "",
      fodec: "",
      montant_ht: 0,
      tva: "",
      montant_ttc: 0,
      montant_remise: 0,
      montant_fodec: 0,
    },])
    if (selectClient != null) {
      var client_id = selectClient.value
    } else {
      var client_id = ""
    }


    const res = await API.post("client/get_by_id", {
      date: dateDevis,
      id: client_id,
    }).then(res => {


      setRaisonSocial(res.data.Client.raisonSocial)
      setAdresse(res.data.Client.adresseLivraison)
      setSelectFamilleClient(res.data.Client.famille_client)
      setEtatFodec(res.data.Client.fodec)
      setCodeTva(res.data.Client.codeTVA)
      setSelectRepre(res.data.Client.Representant)
      setEtatRegimeForf(res.data.Client.regimeForfutaire)
      setSelectModeRegl(res.data.Client.ModeReglement)
      if (res.data.Client.promotions && res.data.Client.promotions.length > 0 && res.data.Client.promotions[0].taux) {
        setTaux_promotion_client(res.data.Client.promotions[0].taux);
        setinitTauxPromoClient(res.data.Client.promotions[0].taux);
        setExistePromotionClient(true)
      }
      else {
        setTaux_promotion_client(0);
        setinitTauxPromoClient(0);
        setExistePromotionClient(false)

      }

      setSelectCodeClient(selectClient)
    })

  }
  //message
  useEffect(() => {
   
    
    if (selectCodeClient.value == null) {
      setErrorMessage('Veuillez sélectionner un client.');

    } else if (selectedTypePromotion.value === "none" && ExistePromotionArticle && ExistePromotionClient) {
      setErrorMessage('Veuillez sélectionner un type de promotion');
    }
    else {
      setErrorMessage('')

    }
    

  }, [selectCodeClient, selectedTypePromotion, ExistePromotionArticle, ExistePromotionClient,dateDevis])


  const getProduits = async (selectProduit, indexOfProduit) => {
    if (selectProduit != null) {
      var produit_id = selectProduit.value
    } else {
      var produit_id = ""
    }
    if (selectProduit != null) {
      var produit = selectProduit
    } else {
      var produit = ""
    }
    setIndexOfProduit(indexOfProduit)
    const res = await API.post("article/get_by_id", {
      id: produit_id,
      date: dateDevis
    })
      .then(res => {
        setDesignation(res.data.Article.designation)
        setPrixHT(res.data.Article.prix_vente_ht)
        setTva(res.data.Article.tva)
        setFodec(res.data.Article.fodec)
        setSelectProduit(selectProduit)
        var rem
        if (selectedTypePromotion.value !== "Client") {
          rem = res.data.Article.remise
        }
        else {
          rem = 0
        }

        return rem
      })
      .then(rem => {
        setArrayProduit(
          arrayProduit.map((el, id) =>
            id === indexOfProduit
              ? Object.assign(el, {
                produit_id: produit,
                designation: designation,
                prix_ht: prixHT,
                tva: tva,
                fodec: fodec,
                remise: rem,
              })
              : el
          )
        )
      })
      .then(() => {
        var tot_ht = arrayProduit
          .map(elem => elem.montant_ht)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_ht = parseFloat(tot_ht).toFixed(3)
        setTotalHT(total_ht)
        var tot_ttc = arrayProduit
          .map(elem => elem.montant_ttc)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_ttc = parseFloat(tot_ttc).toFixed(3)
        setTotalTTC((parseFloat(tot_ttc) - (parseFloat(tot_ttc) * (taux_promotion_client / 100))).toFixed(3))
        var tot_tva = arrayProduit
          .map(elem => elem.montant_ttc - elem.montant_ht)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_tva = parseFloat(tot_tva).toFixed(3)
        setTotalTva(total_tva)
        var tot_remise = arrayProduit
          .map(elem => elem.montant_remise)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_rem = parseFloat(tot_remise).toFixed(3)
        setTotalRemise(total_rem)
        var tot_fodec = arrayProduit
          .map(elem => elem.montant_fodec)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_fodec = parseFloat(tot_fodec).toFixed(3)
        setTotalFodec(total_fodec)
      })
  }


  useEffect(() => {
    if (selectProduit != null) {
      var produit = selectProduit
    } else {
      var produit = ""
    }


    setArrayProduit(
      arrayProduit.map((el, id) =>
        id === indexOfProduit
          ? Object.assign(el, {
            produit_id: produit,
            designation: designation,
            prix_ht: prixHT,
            tva: tva,
            fodec: fodec,
            qte: 0,
            montant_ht: 0,
            montant_ttc: 0,

          })
          : el
      )
    )
  }, [selectProduit])

  const addQuantiteGrt = (e, indexOfQte) => {
    if (e < 0) {
      toast.error("Veuillez vérifier la quantite gratuite !", {
        containerId: "A",
      })
    } else {
      var qte_gratuit = e
      setArrayProduit(
        arrayProduit.map((el, id) =>
          id === indexOfQte
            ? Object.assign(el, {
              qte_gratuit: qte_gratuit,
            })
            : el
        )
      )
    }
  }


  const addQuantite = (e, indexOfQte) => {

    setQuantite(e)
    var qte = e
    var prixHT = arrayProduit[indexOfQte].prix_ht
    var tva = arrayProduit[indexOfQte].tva.label
    var fodec = arrayProduit[indexOfQte].fodec
    var remise = arrayProduit[indexOfQte].remise
    var montantHT = prixHT * qte
    // calcule total HT avec remise
    var montantRemise = montantHT - (montantHT * remise) / 100
    // calcule total remise
    var totalRemise = montantHT - montantRemise
    // calcule fodec


    if (fodec == 1) {
      var montantFodec = (montantRemise * 0.07)
    } else if (fodec == 0) {
      var montantFodec = 0
    }

    // calcule TTC
    var montantHT_fodec = montantRemise + montantFodec
    var montantTTC =
      (montantHT_fodec * tva) / 100 + parseFloat(montantHT_fodec)
    setArrayProduit(
      arrayProduit.map((el, id) =>
        id === indexOfQte
          ? Object.assign(el, {
            qte: qte,
            montant_ht: parseFloat(montantRemise).toFixed(3),
            montant_remise: parseFloat(totalRemise).toFixed(3),
            montant_ttc: parseFloat(montantTTC).toFixed(3),
            montant_fodec: parseFloat(montantFodec).toFixed(3),
          })
          : el
      )
    )

  }

  useEffect(() => {
  

    var tot_ht = arrayProduit
      .map(elem => elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ht = parseFloat(tot_ht).toFixed(3)
    setTotalHT(total_ht)
    var tot_ttc = arrayProduit
      .map(elem => elem.montant_ttc)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ttc = parseFloat(tot_ttc).toFixed(3)
    setTotalTTC((parseFloat(tot_ttc) - (parseFloat(tot_ttc) * (taux_promotion_client / 100))).toFixed(3))
    var tot_tva = arrayProduit
      .map(elem => elem.montant_ttc - elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_tva = parseFloat(tot_tva).toFixed(3)
    setTotalTva(total_tva)
    var tot_remise = arrayProduit
      .map(elem => elem.montant_remise)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_rem = parseFloat(tot_remise).toFixed(3)
    setTotalRemise(total_rem)
    var tot_fodec = arrayProduit
      .map(elem => elem.montant_fodec)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_fodec = parseFloat(tot_fodec).toFixed(3)
    setTotalFodec(total_fodec)
  }, [quantite, selectCodeClient, taux_promotion_client])

  const addRemise = (e, indexOfRemise) => {
    if (e > 100 || e < 0) {
      toast.error("Veuillez vérifier la remise !", {
        containerId: "A",
      })
    } else {
      setRemise(e)
      var remise = e
      var qte = arrayProduit[indexOfRemise].qte
      var prixHT = arrayProduit[indexOfRemise].prix_ht
      var fodec = arrayProduit[indexOfRemise].fodec
      var tva = arrayProduit[indexOfRemise].tva.label
      // calcule HT avec remise
      var montantHT = prixHT * qte
      var montantRemise = montantHT - (montantHT * remise) / 100
      // calcule total remise
      var totalRemise = montantHT - montantRemise
      // calcule fodec
      if (fodec == 1) {
        var montantFodec = (montantRemise * 0.07)
      } else if (fodec == 0) {
        var montantFodec = 0
      }
      // calcule TTC
      var montantHT_fodec = montantRemise + montantFodec
      var montantTTC =
        (montantHT_fodec * tva) / 100 + parseFloat(montantHT_fodec)
      setArrayProduit(
        arrayProduit.map((el, id) =>
          id === indexOfRemise
            ? Object.assign(el, {
              montant_ht: parseFloat(montantRemise).toFixed(3),
              remise: remise,
              montant_remise: parseFloat(totalRemise).toFixed(3),
              montant_ttc: parseFloat(montantTTC).toFixed(3),
              montant_fodec: parseFloat(montantFodec).toFixed(3),
            })
            : el
        )
      )
    }
  }

  useEffect(() => {
    var tot_ht = arrayProduit
      .map(elem => elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ht = parseFloat(tot_ht).toFixed(3)
    setTotalHT(total_ht)
    var tot_ttc = arrayProduit
      .map(elem => elem.montant_ttc)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ttc = parseFloat(tot_ttc).toFixed(3)
    setTotalTTC(parseFloat(tot_ttc) - (parseFloat(tot_ttc) * (taux_promotion_client / 100)).toFixed(3))
    var tot_tva = arrayProduit
      .map(elem => elem.montant_ttc - elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_tva = parseFloat(tot_tva).toFixed(3)
    setTotalTva(total_tva)
    var tot_remise = arrayProduit
      .map(elem => elem.montant_remise)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_rem = parseFloat(tot_remise).toFixed(3)
    setTotalRemise(total_rem)
    var tot_fodec = arrayProduit
      .map(elem => elem.montant_fodec)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_fodec = parseFloat(tot_fodec).toFixed(3)
    setTotalFodec(total_fodec)
  }, [remise, taux_promotion_client])

  const save = async () => {
    setButtonClicked(true)
    let count = 0
    if (selectCodeClient != "") {
      if (selectModeRegl.label != "") {
        if (arrayProduit.length > 0) {
          for (let index = 0; index < arrayProduit.length; index++) {
            let qte = arrayProduit[index].qte
            if (qte <= 0) {
              count++
            }
            if (index == arrayProduit.length - 1) {
              if (count == 0) {
                // convert date devis
                let date = dateDevis
                let month = "" + (date.getMonth() + 1)
                let day = "" + date.getDate()
                let year = date.getFullYear()
                if (month.length < 2) month = "0" + month
                if (day.length < 2) day = "0" + day
                var convertDate = [year, month, day].join("-")
                // get code
                let arrayNum = numDevis.split("D22")
                let numero = arrayNum[1]
                //
                const res = await API.post("devi/add", {
                  annee: annee,
                  user_id,
                  numero,
                  idPromo:idPromo,
                
                  // information
                  num_devis: numDevis,
                  date_devis: convertDate,
                  client_id: selectCodeClient.value,
                  raison_social: raisonSocial,
                  adresse: adresse,
                  famille_id: selectFamilleClient.value,
                  fodec: etatFodec,
                  code_tva: codeTva,
                  remise_excep: remiseExcep,
                  representant_id: selectRepre.value,
                  regime_forf: etatRegimeForf,
                  mode_regl_id: selectModeRegl.value,
                  // array
                  array: arrayProduit,
                  // information
                  observation: observation,
                  tot_remise: totalRemise,
                  tot_ht: totalHT,
                  tot_fodec: totalFodec,
                  tot_tva: totalTva,
                  avance_impot: "",
                  tot_ttc: totalTTC,
                }).then(res => {
                  props.setSection(1)
                  
                })
              } else {
                toast.error("La quantité doit étre supérieur a 0", {
                  containerId: "A",
                })
                setButtonClicked(false)

              }
            }
          }
        } else {
          toast.error("Veuillez choisir au moins un article", {
            containerId: "A",
          })
          setButtonClicked(false)

        }

      } else {
        toast.error("Veuillez selectionner un mode de reglement ", {
          containerId: "A",
        })
        setButtonClicked(false)

      }
    } else {
      toast.error("Veuillez selectionner un client", {
        containerId: "A",
      })
      setButtonClicked(false)

    }
  }


  const handleCheckboxChange = () => {
    if (etatFodec > 0) {
      setEtatFodec(0);
    } else {
      setEtatFodec(1);
    }

    if (etatFodec === 0) {
      setTotalTTC((totalTTC * 1.07).toFixed(3));
    } else {
      setTotalTTC((totalTTC / 1.07).toFixed(3));
    }

    // Mettre à jour les autres valeurs nécessaires
    const customEvent = arrayProduit[0].qte;
    addQuantite(customEvent, 0);
  }

  useEffect(async () => {
    
    const res = await API.post("devi/change_date", {
      Client_Id: selectCodeClient.value,
      date: dateDevis,
      arrayProduit: arrayProduit,
      selected_type_promotion: selectedTypePromotion,
    }).then(res => {
     setIdPromo(res.data.id_promo_article )
      if (selectedTypePromotion.value === "Client" && res.data[1] && res.data[1][0] && res.data[1][0].taux) {
        setTaux_promotion_client(res.data[1][0].taux)
        setinitTauxPromoClient(res.data[1][0].taux)
        setIdPromo(res.data[1][0].id )
       // setExistePromotionClient(true)
        // taux_promo=res.data[1][0].taux
        // change_ttc_by_taux(res.data[1][0].taux)
      } else {
        setTaux_promotion_client(0)
        setinitTauxPromoClient(0)
        //setExistePromotionClient(false)

        // taux_promo=0
        // change_ttc_by_taux(0)

      }
      if (res.data.no_change === 0) {
        setArrayProduit(res.data[0])
      }
      //change_ttc_by_taux(taux_promo)
      
    if (ExistePromotionArticle && ExistePromotionClient) {
      setProduits([])
    }else{
      setProduits(res.data.articles)
    }
      

    })
    //  setselectedTypePromotion(options[2])
    /* setTaux_promotion_client(0)
     setinitTauxPromoClient(0)*/
     
  }, [dateDevis, selectedTypePromotion,selectCodeClient])
  /*
    const change_ttc_by_taux = (nv_taux) => {
      setTotalTTC(nv_taux)
  
      var tot_ttc = arrayProduit
        .map(elem => elem.montant_ttc)
        .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
  
  
      setTotalTTC(parseFloat(tot_ttc) - (parseFloat(tot_ttc) * (nv_taux / 100)))
    }*/



  const change_date = async (e) => {
    setReloadData(true)
    setDateDevis(e)
  }

  useEffect(async () => {
    const resP = await API.post("article/select", {
      date: dateDevis,
      typePromo: selectedTypePromotion
    }).then(resP => {

      setProduits(resP.data.Article)
    })

  }, [selectedTypePromotion])
  const verifTypePromo = (e) => {
    setselectedTypePromotion(e)
    if (e.value === "Articles") {
      setTaux_promotion_client(0)
    } else {
      setTaux_promotion_client(initTauxPromoClient)
    }
  }






  useEffect(() => {


    if (ExistePromotionArticle && ExistePromotionClient) {
      setProduits([])
    } else if (ExistePromotionClient && !ExistePromotionArticle) {
      setselectedTypePromotion(options[2])
    }
    else {
      setselectedTypePromotion(options[0])
    }

  }, [ExistePromotionArticle, ExistePromotionClient])
  useEffect(() => {
  

}, [produits])

 

  useEffect(async () => {
    //setTotalTTC(parseFloat(totalTTC)+1)

    var tot_ttc = arrayProduit
      .map(elem => elem.montant_ttc)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ttc = parseFloat(tot_ttc).toFixed(3)
    setTotalTTC(parseFloat(total_ttc) - (parseFloat(total_ttc) * (taux_promotion_client / 100)))
    
  }, [arrayProduit, selectedTypePromotion])
  return (
    <React.Fragment>
      {loading ? (
        <Row>
          <Form>
            <Row>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">N° devis</Label>
                  <Input
                    lg="3"
                    type="text"
                    className="form-control"
                    disabled
                    value={numDevis}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Date devis</Label>
                  <DatePicker
                    name="Date devis"
                    selected={dateDevis}
                    className="form-control ddate"
                    dateFormat="dd/MM/yyyy"
                    onChange={e => change_date(e)}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Client <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Select
                    options={client}
                    isSearchable={true}
                    onChange={e => getClient(e)}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Raison sociale</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Raison sociale"
                    value={raisonSocial}
                    disabled
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Adresse</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Adresse"
                    value={adresse}
                    disabled
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Famille</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Famille"
                    value={ selectFamilleClient.label}
                    disabled
                  />
                </div>
              </Col>
              {/*<Col md={3}>
                <div className="mb-3 mt-3">
                  <div className="form-check form-check-inline">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="fodec"
                      checked={etatFodec == 1}
                      onChange={handleCheckboxChange}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="fodec"
                    >
                      Fodec
                    </Label>
                  </div>
                </div>
      </Col>*/}
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Code TVA</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Code TVA"
                    value={codeTva}
                    disabled
                  />
                </div>
              </Col>
            </Row>
            <Row>
              {/* <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">
                    Remise exceptionelle
                  </Label>
                  <Col md={12} className="pr-0">
                    <Input
                      type="number"
                      className="form-control"
                      placeholder="Remise exceptionelle"
                      onChange={e => setRemiseExcep(e.target.value)}
                    />
                  </Col>
                </div>
              </Col> */}
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Representant</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Representant"
                    value={selectRepre.label}
                    disabled
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Mode reglement</Label>
                  <Select
                    options={modeRegl}
                    isSearchable={true}
                    onChange={e => setSelectModeRegl(e)}
                    value={selectModeRegl}
                  />
                </div>
              </Col>
              {/*<Col md={3}>
                <div className="mb-3 mt-3">
                  <div className="form-check form-check-inline">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="Regimeforfaitaire"
                      checked={etatRegimeForf == 1}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="Regimeforfaitaire"
                    >
                      Régime forfaitaire
                    </Label>
                  </div>
                </div>
            </Col>*/}

              {ExistePromotionArticle && ExistePromotionClient && (
                <Col md={3}>
                  <div className="mb-3">
                    <Label for="basicpill-lastname-input2">Type de promotion</Label>
                    <Select
                      options={selectOptions}
                      isSearchable={true}
                      onChange={e => verifTypePromo(e)}
                    />
                  </div>
                </Col>
              )}
            </Row>
          </Form>
          {reloadData ? (
  <h1>Chargement ...</h1> 
) : (<html>
          <CardTitle className="h4 mt-4">Details</CardTitle>
          <div>

            
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            <table className="table table-bordered mt-4">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} scope="col">
                    Article
                  </th>

                  <th style={{ textAlign: "center" }} scope="col">
                    Qte
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Gratuit
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    P.U.H.T
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Remise
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Fodec
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant HT
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    TVA
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant TTC
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    <i
                      style={{
                        color: "#a0ceb8",
                        cursor: "pointer",
                      }}
                      className="fas fa-plus"
                      onClick={addLigne}
                    />
                  </th>
                </tr>
              </thead>
              {arrayProduit.map((el, index) => (
                <tbody key={index}>
                  <tr>
                    <td style={{ textAlign: "center", width: "23%" }}>
                      <Select
                        options={produits}
                        isSearchable={true}
                        value={el.produit_id}
                        onChange={e => getProduits(e, index)}
                        isDisabled={!selectCodeClient}
                      />
                    </td>

                    <td style={{ textAlign: "center", width: "8%" }}>
                      <Input
                        type="number"
                        className="form-control"
                        placeholder="..."
                        value={el.qte}
                        min={0}
                        onChange={
                          selectProduit != ""
                            ? e => addQuantite(e.target.value, index)
                            : null
                        }
                        disabled={!selectCodeClient}
                      />
                    </td>
                    <td style={{ textAlign: "center", width: "7%" }}>
                      <Input
                        type="number"
                        className="form-control"
                        placeholder="..."
                        value={el.qte_gratuit}
                        min={0}
                        onChange={e => addQuantiteGrt(e.target.value, index)}
                        disabled={!selectCodeClient}
                      />
                    </td>
                    <td style={{ textAlign: "center", width: "10%" }}>
                      <Input
                        type="number"
                        className="form-control"
                        placeholder="..."
                        value={el.prix_ht}
                        disabled={!selectCodeClient}
                      />
                    </td>
                    <td style={{ textAlign: "center", width: "8%" }}>
                      <Input
                        type="number"
                        className="form-control"
                        placeholder="..."
                        value={el.remise}
                        onChange={
                          selectProduit != ""
                            ? e => addRemise(e.target.value, index)
                            : null
                        }
                        disabled={!selectCodeClient || ExistePromotionArticle || ExistePromotionClient}
                      />
                    </td>
                    <td style={{ textAlign: "center", width: "2%" }}>
                      <Input
                        type="checkbox"
                        className="form-check-Input"
                        id="horizontal-customCheck"
                        checked={el.fodec == 1}
                        disabled={!selectCodeClient}
                      />
                    </td>
                    <td style={{ textAlign: "center", width: "13%" }}>
                      <Input
                        type="number"
                        className="form-control"
                        placeholder="..."
                        value={el.montant_ht}
                        disabled={!selectCodeClient}
                      />
                    </td>
                    <td style={{ textAlign: "center", width: "7%" }}>
                      <Input value={el.tva.label} disabled />
                    </td>
                    <td style={{ textAlign: "center", width: "13%" }}>
                      <Input
                        type="number"
                        className="form-control"
                        placeholder="..."
                        value={el.montant_ttc}
                        disabled={!selectCodeClient}

                      />
                    </td>
                    <td style={{ textAlign: "center", width: "1%" }}>
                      <i
                        style={{ color: "#cc0000", cursor: "pointer" }}
                        className="mdi mdi-delete font-size-18"
                        id="deletetooltip"
                        onClick={() => toggle(index)}
                      />
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
          <Card>
            <CardBody>
              <CardTitle className="mb-3">Total </CardTitle>
              <Row>
                <Col className="mt-3" md={12}>
                  <div className="mb-3">
                    <Label for="basicpill-lastname-input2">Observation</Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Observation"
                      onChange={e => setObservation(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <div className="table-responsive">
                <Table className="table mb-0">
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        {taux_promotion_client > 0 && selectedTypePromotion.value === "Client" &&


                          <Label
                            className="form-check-label"
                            htmlFor="horizontal-customCheck"
                            style={{ color: 'blue' }}
                          > Promotion pour le Client&nbsp;{taux_promotion_client}%
                          </Label>

                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Total des remises :</td>
                      <td>{totalRemise}</td>
                    </tr>
                    <tr>
                      <td>Total HT :</td>
                      <td>{totalHT}</td>
                    </tr>
                    <tr>
                      <td>Total fodec :</td>
                      <td>{totalFodec}</td>
                    </tr>
                    <tr>
                      <td>Total TVA :</td>
                      <td>{totalTva}</td>
                    </tr>
                    <tr>
                      <td>Avance/Impot :</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>Total TTC : </td>
                      <td>{totalTTC}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card></html>
)}
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className="text-center mt-4"
              >
                <Button
                  type="button"
                  color="warning"
                  className="btn btn-warning  mb-2 me-2"
                  onClick={props.back}
                >
                  Annuler
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="text-center mt-4"
              >
                <button
                  type="button"
                  className="btn btn-primary "
                  disabled={buttonClicked}
                  onClick={save}
                >
                  Confirmer
                </button>
              </div>
            </Col>
          </div>
        </Row>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            {" "}
            Chargement en cours ...{" "}
          </h4>
        </div>
      )}
      {/* < delete > */}
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggle}
        >
          Suppression ligne
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  onClick={toggleDelete}
                  color="info"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default AddDevisClient
AddDevisClient.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
