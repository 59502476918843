import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import API from "../../../api"
import {
  Col,
  Spinner,
  Input,
  Row,
  Label,
  Form,
  Button,
  CardTitle,
  Modal,
  ModalBody,
  Card,
  CardBody,
  Table,
  Container,
} from "reactstrap"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"

const EditBonCmdClient = props => {
  var userAuth = JSON.parse(localStorage.getItem("userAuth"))
  const user_id = userAuth.user.id
  //
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState("")
  const [numBonCmd, setNumBonCmd] = useState("")
  const [dateCmd, setDateCmd] = useState(new Date())
  const [devis, setDevis] = useState([])
  const [selectDevis, setSelectDevis] = useState("")
  const [dateDevis, setDateDevis] = useState(new Date())
  const [client, setClient] = useState([])
  const [selectCodeClient, setSelectCodeClient] = useState("")
  const [raisonSocial, setRaisonSocial] = useState("")
  const [adresse, setAdresse] = useState("")
  const [selectFamilleClient, setSelectFamilleClient] = useState("")
  const [etatFodec, setEtatFodec] = useState(0)
  const [codeTva, setCodeTva] = useState("")
  const [remiseExcep, setRemiseExcep] = useState("")
  const [selectRepre, setSelectRepre] = useState("")
  const [etatRegimeForf, setEtatRegimeForf] = useState(0)
  const [modeRegl, setModeRegl] = useState([])
  const [selectModeRegl, setSelectModeRegl] = useState("")
  const [numCmdInt, setNumCmdInt] = useState("")
  const [modal, setModal] = useState(false)
  const [indexLigne, setIndexLigne] = useState("")
  const [arrayProduit, setArrayProduit] = useState([
    {
      produit_id: "",
      designation: "",
      qte: "",
      qte_gratuit: "",
      prix_ht: "",
      remise: "",
      fodec: "",
      montant_ht: 0,
      tva: "",
      montant_ttc: 0,
      montant_remise: 0,
      montant_fodec: 0,
      qte_rest: 1,
    },
  ])
  const [produits, setProduits] = useState([])
  const [selectProduit, setSelectProduit] = useState("")
  const [indexOfProduit, setIndexOfProduit] = useState("")
  //
  const [observation, setObservation] = useState("")
  const [totalRemise, setTotalRemise] = useState(0)
  const [remise, setRemise] = useState("")
  const [totalTva, setTotalTva] = useState("")
  const [quantite, setQuantite] = useState("")
  const [totalHT, setTotalHT] = useState(0)
  const [totalFodec, setTotalFodec] = useState(0)
  const [totalTTC, setTotalTTC] = useState(0)
  //
  const [prixHT, setPrixHT] = useState("")
  const [designation, setDesignation] = useState("")
  const [tva, setTva] = useState("")
  const [fodec, setFodec] = useState("")

  const options = [

    { value: 'none', label: 'Select...' },
    { value: 'Articles', label: 'Sur articles' },
    { value: 'Client', label: 'Sur client' },

  ]

  const [selectOptions, setSelectOptions] = useState(options.slice(1))


  const [taux_promotion_client, setTaux_promotion_client] = useState(0);
  const [selectedTypePromotion, setSelectedTypePromotion] = useState(options[0]);
  const [ExistePromotion, setExistePromotion] = useState([])
  const [initTauxPromoClient, setinitTauxPromoClient] = useState(0)

  const [idPromo, setIdPromo] = useState(0)
  const [typePromoDevis, setTypePromoDevis]=useState("")
  const [dateSys,setDateSys]=useState(new Date())





  useEffect(async () => {
    const resT = await API.get("mode/reglement/select").then(rest => {
      setModeRegl(rest.data.ModeReglement)
    })
    
    // get by id
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    var date_Cmd
    var idClient
    setId(id)
    const resD = await API.post("bon/comande/client/get_by_id", { id }).then(
      resD => {
        setNumBonCmd(resD.data.BonComandeClient.num_comm)
        setDateCmd(new Date(resD.data.BonComandeClient.date_comm))
        date_Cmd= new Date(resD.data.BonComandeClient.date_comm)
        setSelectDevis(resD.data.BonComandeClient.devis_client)
        setDateDevis(new Date(resD.data.BonComandeClient.date_devis))
        setSelectCodeClient(resD.data.BonComandeClient.client)
        idClient= resD.data.BonComandeClient.client
        setTypePromoDevis(resD.data.BonComandeClient.devis_promotion)
        //
        const resE = API.post("devi/get_devi_by_client", {
          id: resD.data.BonComandeClient.client.value,
        }).then(resE => {
          setDevis(resE.data.DevisClient)
        })
        //
        setRaisonSocial(resD.data.BonComandeClient.raisonSocial)
        setAdresse(resD.data.BonComandeClient.adresse)
        setSelectFamilleClient(resD.data.BonComandeClient.famille_client)
        setEtatFodec(resD.data.BonComandeClient.fodec)
        setCodeTva(resD.data.BonComandeClient.codeTva)
        setSelectRepre(resD.data.BonComandeClient.representant)
        setSelectModeRegl(resD.data.BonComandeClient.mode_reglement)
        setEtatRegimeForf(resD.data.BonComandeClient.regimeForfitaire)
        setNumCmdInt(resD.data.BonComandeClient.num_comm_int)
        setArrayProduit(resD.data.BonComandeClient.ligne)
        setObservation(resD.data.BonComandeClient.observation)
        setTotalRemise(resD.data.BonComandeClient.total_remise)
        setTotalHT(resD.data.BonComandeClient.total_ht)
        setTotalFodec(resD.data.BonComandeClient.total_fodec)
        setTotalTva(resD.data.BonComandeClient.total_tva)
        setTotalTTC(resD.data.BonComandeClient.total_ttc)
        setExistePromotion(resD.data.BonComandeClient.promotion)
        if (resD.data.BonComandeClient.promotion !== null) {
          setSelectedTypePromotion(resD.data.BonComandeClient.promotion.type)
          setTaux_promotion_client(resD.data.BonComandeClient.promotion.taux)
        }
        setLoading(true)
      }
    )

    const resA = await API.post("promotion/verif_exist", {
      date: date_Cmd,
      idClient: idClient.value,
    }).then(resA => {
      if (resA.data.exist_promo_article === false) {
        setSelectOptions(selectOptions.slice(1))

      } else if (resA.data.exist_promo_client === false) {
        setSelectOptions(selectOptions.slice(2))

      }

    })
  }, [])

  const addLigne = () => {
    let element = {
      produit_id: "",
      designation: "",
      qte: "",
      qte_gratuit: "",
      prix_ht: "",
      remise: "",
      fodec: "",
      montant_ht: 0,
      tva: "",
      montant_ttc: 0,
      montant_remise: 0,
      montant_fodec: 0,
      qte_rest: 1,
    }
    setArrayProduit([...arrayProduit, element])
  }

  const toggle = indexOfLigne => {
    setModal(!modal)
    setIndexLigne(indexOfLigne)
  }

  const toggleDelete = async () => {
    arrayProduit.splice(indexLigne, 1)
    setModal(false)
  }

  useEffect(() => {
    var tot_ht = arrayProduit
      .map(elem => elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ht = parseFloat(tot_ht).toFixed(3)
    setTotalHT(total_ht)
    var tot_ttc = arrayProduit
      .map(elem => elem.montant_ttc)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ttc = parseFloat(tot_ttc).toFixed(3)
    setTotalTTC((parseFloat(tot_ttc) - (parseFloat(tot_ttc) * (taux_promotion_client / 100))).toFixed(3))
    var tot_tva = arrayProduit
      .map(elem => elem.montant_ttc - elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_tva = parseFloat(tot_tva).toFixed(3)
    setTotalTva(total_tva)
    var tot_remise = arrayProduit
      .map(elem => elem.montant_remise)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_rem = parseFloat(tot_remise).toFixed(3)
    setTotalRemise(total_rem)
    var tot_fodec = arrayProduit
      .map(elem => elem.montant_fodec)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_fodec = parseFloat(tot_fodec).toFixed(3)
    setTotalFodec(total_fodec)
  }, [modal])

  const getClientByDevis = async selectDevis => {
    setIdPromo(null)
   
    //setchangedDevis(changedDevis+1)
    if (selectDevis != null) {
      var devis_id = selectDevis.value
    } else {
      var devis_id = ""
    }
    setSelectDevis(selectDevis)
    //setSelectedDevis(SelectedDevis!=null)

    //
    const res = await API.post("devi/get_client_by_devi", {
      id: devis_id,
      date:dateCmd
    }).then(res => {
      setSelectCodeClient(res.data.Client.client)
      setRaisonSocial(res.data.Client.raisonSocial)
      setAdresse(res.data.Client.adresseLivraison)
      setSelectFamilleClient(res.data.Client.famille_client)
      setEtatFodec(res.data.Client.fodec)
      setCodeTva(res.data.Client.codeTVA)
      setSelectRepre(res.data.Client.Representant)
      setEtatRegimeForf(res.data.Client.regimeForfutaire)
      setSelectModeRegl(res.data.Client.ModeReglement)
      if (res.data.Client.promotions && res.data.Client.promotions.length > 0 && res.data.Client.promotions[0].taux) {
        setTaux_promotion_client(res.data.Client.promotions[0].taux);
      }
      else {
        
        setTaux_promotion_client(0);
      }
      //setSelectCodeClient(selectCodeClient)
    })
    

    
    const resD = await API.post("devi/get_ligne_by_devi", {
      id: devis_id,
    }).then(resD => {
      setArrayProduit(resD.data.Article.ligne)
      setTotalFodec(resD.data.Article.total_fodec)
      setTotalHT(resD.data.Article.total_ht)
      setTotalRemise(resD.data.Article.total_remise)
      setTotalTTC(resD.data.Article.total_ttc)
      setTotalTva(resD.data.Article.total_tva)
      setTypePromoDevis(resD.data.Article.promotion)
    })

    setTaux_promotion_client(0)
  }

  const getDevisByClient = async selectClient => {
    if (selectClient != null) {
      var client_id = selectClient.value
    } else {
      var client_id = ""
    }
    setSelectCodeClient(selectClient)
    // get devis client
    const resD = await API.post("devi/get_devi_by_client", {
      id: client_id,
    }).then(resD => {
      setDevis(resD.data.DevisClient)
    })
    //
    const res = await API.post("client/get_by_id", {
      id: client_id,
    }).then(res => {
      setRaisonSocial(res.data.Client.raisonSocial)
      setAdresse(res.data.Client.adresseLivraison)
      setSelectFamilleClient(res.data.Client.famille_client)
      setEtatFodec(res.data.Client.fodec)
      setCodeTva(res.data.Client.codeTVA)
      setSelectRepre(res.data.Client.Representant)
      setEtatRegimeForf(res.data.Client.regimeForfutaire)
      setSelectModeRegl(res.data.Client.ModeReglement)
    })
  }

  const getProduits = async (selectProduit, indexOfProduit) => {
    if (selectProduit != null) {
      var produit_id = selectProduit.value
    } else {
      var produit_id = ""
    }
    if (selectProduit != null) {
      var produit = selectProduit
    } else {
      var produit = ""
    }
    setIndexOfProduit(indexOfProduit)
    const res = await API.post("article/get_by_id", {
      id: produit_id,
    })
      .then(res => {
        setDesignation(res.data.Article.designation)
        setPrixHT(res.data.Article.prix_achat_ht)
        setTva(res.data.Article.tva)
        setFodec(res.data.Article.fodec)
        setSelectProduit(selectProduit)
      })
      .then(() => {
        setArrayProduit(
          arrayProduit.map((el, id) =>
            id === indexOfProduit
              ? Object.assign(el, {
                produit_id: produit,
                designation: designation,
                prix_ht: prixHT,
                tva: tva,
                fodec: fodec,
              })
              : el
          )
        )
      })
      .then(() => {
        var tot_ht = arrayProduit
          .map(elem => elem.montant_ht)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_ht = parseFloat(tot_ht).toFixed(3)
        setTotalHT(total_ht)
        var tot_ttc = arrayProduit
          .map(elem => elem.montant_ttc)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_ttc = parseFloat(tot_ttc).toFixed(3)
        setTotalTTC((parseFloat(tot_ttc) - (parseFloat(tot_ttc) * (taux_promotion_client / 100))).toFixed(3))
        var tot_tva = arrayProduit
          .map(elem => elem.montant_ttc - elem.montant_ht)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_tva = parseFloat(tot_tva).toFixed(3)
        setTotalTva(total_tva)
        var tot_remise = arrayProduit
          .map(elem => elem.montant_remise)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_rem = parseFloat(tot_remise).toFixed(3)
        setTotalRemise(total_rem)
        var tot_fodec = arrayProduit
          .map(elem => elem.montant_fodec)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_fodec = parseFloat(tot_fodec).toFixed(3)
        setTotalFodec(total_fodec)
      })
  }

  useEffect(() => {
    if (selectProduit != null) {
      var produit = selectProduit
    } else {
      var produit = ""
    }
    setArrayProduit(
      arrayProduit.map((el, id) =>
        id === indexOfProduit
          ? Object.assign(el, {
            produit_id: produit,
            designation: designation,
            prix_ht: prixHT,
            tva: tva,
            fodec: fodec,
          })
          : el
      )
    )
  }, [selectProduit])

  const addQuantiteGrt = (e, indexOfQte) => {
    if (e < 0) {
      toast.error("Veuillez vérifier la quantite gratuite !", {
        containerId: "A",
      })
    } else {
      var qte_gratuit = e
      setArrayProduit(
        arrayProduit.map((el, id) =>
          id === indexOfQte
            ? Object.assign(el, {
              qte_gratuit: qte_gratuit,
            })
            : el
        )
      )
    }
  }

  const addQuantite = (e, indexOfQte) => {
    if (e < 1) {
      toast.error("Veuillez vérifier la quantite !", {
        containerId: "A",
      })
    } else {
      if (selectDevis != "") {
        var qte =
          parseInt(arrayProduit[indexOfQte].qte_x) +
          parseInt(arrayProduit[indexOfQte].qte_rest)
        if (qte >= e) {
          setQuantite(e)
          var qte = e
          var prixHT = arrayProduit[indexOfQte].prix_ht
          var tva = arrayProduit[indexOfQte].tva.label
          var fodec = arrayProduit[indexOfQte].fodec
          var remise = arrayProduit[indexOfQte].remise
          var montantHT = prixHT * qte
          // calcule total HT avec remise
          var montantRemise = montantHT - (montantHT * remise) / 100
          // calcule total remise
          var totalRemise = montantHT - montantRemise
          // calcule fodec
          if ( fodec == 1) {
            var montantFodec = (montantRemise * 0.07)
          } else if ( fodec == 0) {
            var montantFodec = 0
          }
          // calcule TTC
          var montantHT_fodec = montantRemise + montantFodec
          var montantTTC =
            (montantHT_fodec * tva) / 100 + parseFloat(montantHT_fodec)
          setArrayProduit(
            arrayProduit.map((el, id) =>
              id === indexOfQte
                ? Object.assign(el, {
                  qte: qte,
                  montant_ht: parseFloat(montantRemise).toFixed(3),
                  montant_remise: parseFloat(totalRemise).toFixed(3),
                  montant_ttc: parseFloat(montantTTC).toFixed(3),
                  montant_fodec: parseFloat(montantFodec).toFixed(3),
                })
                : el
            )
          )
        } else {
          toast.error("Ne pas depasser la quantité de " + qte, {
            containerId: "A",
          })
        }
      } else {
        setQuantite(e)
        var qte = e
        var prixHT = arrayProduit[indexOfQte].prix_ht
        var tva = arrayProduit[indexOfQte].tva.label
        var fodec = arrayProduit[indexOfQte].fodec
        var remise = arrayProduit[indexOfQte].remise
        var montantHT = prixHT * qte
        // calcule total HT avec remise
        var montantRemise = montantHT - (montantHT * remise) / 100
        // calcule total remise
        var totalRemise = montantHT - montantRemise
        // calcule fodec
        if ( fodec == 1) {
          var montantFodec = (montantRemise * 0.07)
        } else if ( fodec == 0) {
          var montantFodec = 0
        }
        // calcule TTC
        var montantHT_fodec = montantRemise + montantFodec
        var montantTTC =
          (montantHT_fodec * tva) / 100 + parseFloat(montantHT_fodec)
        setArrayProduit(
          arrayProduit.map((el, id) =>
            id === indexOfQte
              ? Object.assign(el, {
                qte: qte,
                montant_ht: parseFloat(montantRemise).toFixed(3),
                montant_remise: parseFloat(totalRemise).toFixed(3),
                montant_ttc: parseFloat(montantTTC).toFixed(3),
                montant_fodec: parseFloat(montantFodec).toFixed(3),
              })
              : el
          )
        )
      }
    }
  }

  useEffect(() => {
    var tot_ht = arrayProduit
      .map(elem => elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ht = parseFloat(tot_ht).toFixed(3)
    setTotalHT(total_ht)
    var tot_ttc = arrayProduit
      .map(elem => elem.montant_ttc)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ttc = parseFloat(tot_ttc).toFixed(3)
    setTotalTTC((parseFloat(tot_ttc) - (parseFloat(tot_ttc) * (taux_promotion_client / 100))).toFixed(3))
    var tot_tva = arrayProduit
      .map(elem => elem.montant_ttc - elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_tva = parseFloat(tot_tva).toFixed(3)
    setTotalTva(total_tva)
    var tot_remise = arrayProduit
      .map(elem => elem.montant_remise)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_rem = parseFloat(tot_remise).toFixed(3)
    setTotalRemise(total_rem)
    var tot_fodec = arrayProduit
      .map(elem => elem.montant_fodec)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_fodec = parseFloat(tot_fodec).toFixed(3)
    setTotalFodec(total_fodec)
  }, [quantite])

  const addRemise = (e, indexOfRemise) => {
    if (e > 100 || e < 0) {
      toast.error("Veuillez vérifier la remise !", {
        containerId: "A",
      })
    } else {
      setRemise(e)
      var remise = e
      var qte = arrayProduit[indexOfRemise].qte
      var prixHT = arrayProduit[indexOfRemise].prix_ht
      var fodec = arrayProduit[indexOfRemise].fodec
      var tva = arrayProduit[indexOfRemise].tva.label
      // calcule HT avec remise
      var montantHT = prixHT * qte
      var montantRemise = montantHT - (montantHT * remise) / 100
      // calcule total remise
      var totalRemise = montantHT - montantRemise
      // calcule fodec
      if ( fodec == 1) {
        var montantFodec = (montantRemise * 0.07)
      } else if ( fodec == 0) {
        var montantFodec = 0
      }
      // calcule TTC
      var montantHT_fodec = montantRemise + montantFodec
      var montantTTC =
        (montantHT_fodec * tva) / 100 + parseFloat(montantHT_fodec)
      setArrayProduit(
        arrayProduit.map((el, id) =>
          id === indexOfRemise
            ? Object.assign(el, {
              montant_ht: parseFloat(montantRemise).toFixed(3),
              remise: remise,
              montant_remise: parseFloat(totalRemise).toFixed(3),
              montant_ttc: parseFloat(montantTTC).toFixed(3),
              montant_fodec: parseFloat(montantFodec).toFixed(3),
            })
            : el
        )
      )
    }
  }

  useEffect(() => {
    var tot_ht = arrayProduit
      .map(elem => elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ht = parseFloat(tot_ht).toFixed(3)
    setTotalHT(total_ht)
    var tot_ttc = arrayProduit
      .map(elem => elem.montant_ttc)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ttc = parseFloat(tot_ttc).toFixed(3)
    setTotalTTC((parseFloat(tot_ttc) - (parseFloat(tot_ttc) * (taux_promotion_client / 100))).toFixed(3))
    var tot_tva = arrayProduit
      .map(elem => elem.montant_ttc - elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_tva = parseFloat(tot_tva).toFixed(3)
    setTotalTva(total_tva)
    var tot_remise = arrayProduit
      .map(elem => elem.montant_remise)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_rem = parseFloat(tot_remise).toFixed(3)
    setTotalRemise(total_rem)
    var tot_fodec = arrayProduit
      .map(elem => elem.montant_fodec)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_fodec = parseFloat(tot_fodec).toFixed(3)
    setTotalFodec(total_fodec)
  }, [remise])

  const save = async () => {
    let count = 0
    if (selectCodeClient != "") {
      if (arrayProduit.length > 0) {
        for (let index = 0; index < arrayProduit.length; index++) {
          let qte = arrayProduit[index].qte
          if (qte <= 0) {
            count++
          }
          if (index == arrayProduit.length - 1) {
            if (count == 0) {
              // convert date bon cmd
              let dateBonCmd = dateCmd
              let monthBonCmd = "" + (dateBonCmd.getMonth() + 1)
              let dayBonCmd = "" + dateBonCmd.getDate()
              let yearBonCmd = dateBonCmd.getFullYear()
              if (monthBonCmd.length < 2) monthBonCmd = "0" + monthBonCmd
              if (dayBonCmd.length < 2) dayBonCmd = "0" + dayBonCmd
              var convertBonCmd = [yearBonCmd, monthBonCmd, dayBonCmd].join("-")
              // convert date devis
              let date = dateDevis
              let month = "" + (date.getMonth() + 1)
              let day = "" + date.getDate()
              let year = date.getFullYear()
              if (month.length < 2) month = "0" + month
              if (day.length < 2) day = "0" + day
              var convertDate = [year, month, day].join("-")
              // get code
              let arrayNum = numBonCmd.split("C22")
              let numero = arrayNum[1]
              //
              const res = await API.post("bon/comande/client/edit", {
                id,
                annee_id: 2,
                user_id,
                numero,
                idPromo:idPromo,
                // information
                num_bon_cmd: numBonCmd,
                date_bon_cmd: convertBonCmd,
                devis_id: selectDevis.value,
                date_devis: convertDate,
                client_id: selectCodeClient.value,
                raison_social: raisonSocial,
                adresse: adresse,
                famille_id: selectFamilleClient.value,
                fodec: fodec,
                code_tva: codeTva,
                remise_excep: remiseExcep,
                representant_id: selectRepre.value,
                regime_forf: etatRegimeForf,
                mode_regl_id: selectModeRegl.value,
                num_cmd_int: numCmdInt,
                // array
                array: arrayProduit,
                // information
                observation: observation,
                tot_remise: totalRemise,
                tot_ht: totalHT,
                tot_fodec: totalFodec,
                tot_tva: totalTva,
                avance_impot: "",
                tot_ttc: totalTTC,
              }).then(res => {
                props.history.push("/BonCmdClient")
              })
            } else {
              toast.error("La quantité doit étre supérieur a 0", {
                containerId: "A",
              })
            }
          }
        }
      } else {
        toast.error("Veuillez choisir au moins un article", {
          containerId: "A",
        })
      }
    } else {
      toast.error("Veuillez selectionner un client", {
        containerId: "A",
      })
    }
  }


  const verifTypePromo = (selectedOption) => {
    if (selectedOption === "Articles") {
      setSelectedTypePromotion(options[1]);
    } else if (selectedOption === "Client") {
      setSelectedTypePromotion(options[2]);
    } else if (selectedOption === "") {
      setSelectedTypePromotion(options[0]);
    }
  };
  useEffect(() => {
    verifTypePromo(selectedTypePromotion);
  }, [selectedTypePromotion]);


  
  useEffect(async () => {
    
    const res = await API.post("devi/change_date", {
      Client_Id: selectCodeClient.value,
      date: dateCmd,
      arrayProduit: arrayProduit,
      selected_type_promotion: selectedTypePromotion
    }).then(res => {
     setIdPromo(res.data.id_promo_article )
      if (selectedTypePromotion.value === "Client" && res.data[1] && res.data[1][0] && res.data[1][0].taux) {
        setTaux_promotion_client(res.data[1][0].taux)
        setinitTauxPromoClient(res.data[1][0].taux)
        setIdPromo(res.data[1][0].id )
      
      } else {
        setTaux_promotion_client(0)
        setinitTauxPromoClient(0)
      
      }
      /*if (res.data.no_change === 0) {
        setArrayProduit(res.data[0])
      }*/
      setProduits(res.data.articles)

    })

     
  }, [selectedTypePromotion])
  
  useEffect(async () => {
    const resP = await API.post("article/select", {
      date: dateSys,
      typePromo: selectedTypePromotion
    }).then(resP => {

      setProduits(resP.data.Article)
    })

  }, [selectedTypePromotion])

  useEffect(async () => {
    //setTotalTTC(parseFloat(totalTTC)+1)

    var tot_ttc = arrayProduit
      .map(elem => elem.montant_ttc)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ttc = parseFloat(tot_ttc).toFixed(3)
    setTotalTTC(parseFloat(total_ttc) - (parseFloat(total_ttc) * (taux_promotion_client / 100)))
  }, [arrayProduit, selectedTypePromotion])



  return (
    <React.Fragment>
      <div className="page-content">
        {loading ? (
          <Container>
            <Row>
              <CardTitle className="h4 mb-4" style={{ color: "#556ee6" }}>
                Modification bon de commande client
              </CardTitle>
              <Form>
                <Row>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">
                        N° bon commande
                      </Label>
                      <Input
                        lg="3"
                        type="text"
                        className="form-control"
                        disabled
                        value={numBonCmd}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Date commande
                      </Label>
                      <DatePicker
                        name="Date devis"
                        selected={dateCmd}
                        className="form-control ddate"
                        dateFormat="dd/MM/yyyy"
                        onChange={setDateCmd}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">Devis</Label>
                      <Select
                        options={devis}
                        isSearchable={true}
                        onChange={e => getClientByDevis(e)}
                        value={selectDevis}
                        disabled={true}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">Date devis</Label>
                      <DatePicker
                        name="Date devis"
                        selected={dateDevis}
                        className="form-control ddate"
                        dateFormat="dd/MM/yyyy"
                        onChange={setDateDevis}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Client
                        <span style={{ color: "red", paddingLeft: "5px" }}>
                          *
                        </span>
                      </Label>
                      <Select
                        options={client}
                        isSearchable={true}
                        onChange={e => getDevisByClient(e)}
                        value={selectCodeClient}
                        isDisabled
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">
                        Raison sociale
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Raison sociale"
                        value={raisonSocial}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">Adresse</Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Adresse"
                        value={adresse}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">Famille</Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Famille"
                        value={selectFamilleClient.label}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {/*<Col md={3}>
                    <div className="mb-3 mt-3">
                      <div className="form-check form-check-inline">
                        <Input
                          type="checkbox"
                          className="form-check-Input"
                          id="horizontal-customCheck"
                          checked={etatFodec == 1}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="horizontal-customCheck"
                        >
                          Fodec
                        </Label>
                      </div>
                    </div>
                  </Col>*}
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">Code TVA</Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Code TVA"
                        value={codeTva}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">
                        Representant
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Representant"
                        value={selectRepre.label}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">
                        Mode reglement
                      </Label>
                      <Select
                        options={modeRegl}
                        isSearchable={true}
                        onChange={e => setSelectModeRegl(e)}
                        value={selectModeRegl}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {/*<Col md={3}>
                    <div className="mb-3 mt-3">
                      <div className="form-check form-check-inline">
                        <Input
                          type="checkbox"
                          className="form-check-Input"
                          id="horizontal-customCheck"
                          checked={etatRegimeForf == 1}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="horizontal-customCheck"
                        >
                          Régime forfaitaire
                        </Label>
                      </div>
                    </div>
        </Col>*/}
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">
                        N° Commande interne
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="N° Commande interne"
                        value={numCmdInt}
                        onChange={e => setNumCmdInt(e.target.value)}
                      />
                    </div>
                  </Col>

                  
              {typePromoDevis &&  (
                <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">
                    Type  de promotion Devis
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="type de promortion "
                    value={typePromoDevis.type}
                    disabled={true}
                    //onChange={e => setNumCmdInt(e.target.value)}
                  />
                </div>
              </Col>
              )}
                  {ExistePromotion && !typePromoDevis &&  (
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">Type de promotion</Label>
                      <Select
                        options={selectOptions}
                        value={selectedTypePromotion}
                        isSearchable={true}
                        onChange={(selectedOption) => verifTypePromo(selectedOption.value)}
                      />
                    </div>
                  </Col>
                  )}
                </Row>
              </Form>
              <CardTitle className="h4 mt-4">Details</CardTitle>
              <div>
                <table className="table table-bordered mt-4">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Article
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Désignation
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Qte
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Gratuit
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        P.U.H.T
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Remise
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Fodec
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Montant HT
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        TVA
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Montant TTC
                      </th>
                      {selectDevis =="" ?  (

                       <th style={{ textAlign: "center" }} scope="col">
                        <i
                          style={{
                            color: "#a0ceb8",
                            cursor: "pointer",
                          }}
                          className="fas fa-plus"
                          onClick={addLigne}
                        />
                      </th> 
                     ) : null}
                    </tr>
                  </thead>
                  {arrayProduit.map((el, index) => (
                    <tbody key={index}>
                      <tr>
                      
                        <td style={{ textAlign: "center", width: "23%" }}>
                        
                          <Select
                            options={produits}
                            isSearchable={true}
                            value={el.produit_id}
                            onChange={e => getProduits(e, index)}
                            isDisabled={selectDevis}
                          />
                        </td>
                      
                        <td style={{ textAlign: "center", width: "10%" }}>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="..."
                            value={el.designation}
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "8%" }}>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder="..."
                            value={el.qte}
                            min={0}
                            onChange={e => addQuantite(e.target.value, index)}
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "7%" }}>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder="..."
                            value={el.qte_gratuit}
                            min={0}
                            onChange={e =>
                              addQuantiteGrt(e.target.value, index)
                            }
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "10%" }}>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder="..."
                            value={el.prix_ht}
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "8%" }}>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder="..."
                            value={el.remise}
                            min={0}
                            max={100}
                            onChange={e => addRemise(e.target.value, index)}
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "2%" }}>
                          <Input
                            type="checkbox"
                            className="form-check-Input"
                            id="horizontal-customCheck"
                            checked={el.fodec == 1}
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "13%" }}>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder="..."
                            value={el.montant_ht}
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "7%" }}>
                          <Input value={el.tva.label} disabled />
                        </td>
                        <td style={{ textAlign: "center", width: "13%" }}>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder="..."
                            value={el.montant_ttc}
                          />
                        </td>
                        {selectDevis =="" ?  (
                        <td style={{ textAlign: "center", width: "1%" }}>
                          <i
                            style={{ color: "#cc0000", cursor: "pointer" }}
                            className="mdi mdi-delete font-size-18"
                            id="deletetooltip"
                            onClick={() => toggle(index)}
                          />
                        </td> 
                       ) : null}

                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
              <Card>
                <CardBody>
                  <CardTitle className="mb-3">Total </CardTitle>
                  <Row>
                    <Col className="mt-3" md={12}>
                      <div className="mb-3">
                        <Label for="basicpill-lastname-input2">
                          Observation
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Observation"
                          onChange={e => setObservation(e.target.value)}
                          value={observation}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <tbody>
                      <tr>
                      <td colSpan={ 2}>
                        {taux_promotion_client > 0 && selectedTypePromotion.value === "Client" &&


                          <Label
                            className="form-check-label"
                            htmlFor="horizontal-customCheck"
                            style={{ color: 'blue' }}
                          > Promotion pour le Client&nbsp;{taux_promotion_client}%
                          </Label>

                        }
                     
                      {typePromoDevis && typePromoDevis.type === "Client" && (

                          <Label
                            className="form-check-label"
                            htmlFor="horizontal-customCheck"
                            style={{ color: 'blue' }}
                          > Taux de promotion devis pour le Client &nbsp;{typePromoDevis.taux}%
                          </Label>

                        )}
                      </td>
                    </tr>
                        <tr>
                          <td>Total des remises :</td>
                          <td>{totalRemise}</td>
                        </tr>
                        <tr>
                          <td>Total HT :</td>
                          <td>{totalHT}</td>
                        </tr>
                        <tr>
                          <td>Total fodec :</td>
                          <td>{totalFodec}</td>
                        </tr>
                        <tr>
                          <td>Total TVA :</td>
                          <td>{totalTva}</td>
                        </tr>
                        <tr>
                          <td>Avance/Impot :</td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <td>Total TTC : </td>
                          <td>{totalTTC}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <Col lg="6">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    className="text-center mt-4"
                  >
                    <Button
                      type="button"
                      color="warning"
                      className="btn btn-warning  mb-2 me-2"
                      onClick={() => props.history.push("/BonCmdClient")}
                    >
                      Annuler
                    </Button>
                  </div>
                </Col>
                <Col lg="6">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    className="text-center mt-4"
                  >
                    <button
                      type="button"
                      className="btn btn-primary "
                      onClick={save}
                    >
                      Confirmer
                    </button>
                  </div>
                </Col>
              </div>
            </Row>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              Chargement en cours ...{" "}
            </h4>
          </div>
        )}
      </div>
      {/* < delete > */}
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggle}
        >
          Suppression ligne
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  onClick={toggleDelete}
                  color="info"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default EditBonCmdClient
EditBonCmdClient.propTypes = {
  history: PropTypes.object,
}
