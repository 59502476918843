import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import { Row, Col, Spinner, ModalBody, Button, Label, Input, Card, CardTitle, CardBody } from "reactstrap"
import { withRouter } from "react-router"
//lang
import { withTranslation } from "react-i18next"
import DataTable from "react-data-table-component"
import { Search } from "react-bootstrap-table2-toolkit"
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
//PDF
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faFilePdf } from "@fortawesome/free-solid-svg-icons";
//Excel 
import * as XLSX from 'xlsx';
import { ToastContainer, toast } from "react-toastify"
import { Slide } from "react-toastify"

import Modal from 'react-bootstrap/Modal';

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';




const CadreFinancier = props => {
  const [loading, setLoading] = useState(false)
  const [Banque, setBanque] = useState([])
  const [selectBanque, setSelectBanque] = useState("")
  const [banque_id, setBanque_id] = useState(0)
  const [code, setCode] = useState("")
  const [designation, setDesignation] = useState("")
  const [quantite, setQuantite] = useState("")
  const [quantite_max, setQuantiteMaximal] = useState("")
  const [quantite_min, setQuantiteMinimal] = useState("")
  const [nbrTotlEl, setNbrTotlEl] = useState(0)
  const [nbrEltperPage, setNbrEltperPage] = useState(5)
  const [filterElement, setFilterElement] = useState("")
  const [mycurrentPage, setMycurrentPage] = useState(1)
  const [resetFirstPage, setResetFirstPage] = useState(false)
  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  const [data, setData] = useState([])
  const [suivi, setSuivi] = useState([])
  const [userdata, setUserdata] = useState([])

  //pdf 
  const contentRef = useRef(null);

  //date
  const [dateDebut, setDateDebut] = useState("")
  const [dateFin, setDateFin] = useState("")
  // Produits
  const [produit_id, setProduit_id] = useState(0)
  const [produits, setProduits] = useState([])
  const [selectProduit, setSelectProduit] = useState()

  //les quantites 
  const [quantite_actuelle, setQuantite_actuelle] = useState("")
  const [quantite_DateF, setQuantite_DateF] = useState("")
  const [quantite_DateDeb, setQuantite_DateDeb] = useState("")
  //table 
  const [arrayProduit, setArrayProduit] = useState([])
  const [selectOperation, setSelectOperation] = useState('')


  //verification
  const [BanqueError, setBanqueError] = useState("");
  const [dateDebutError, setDateDebutError] = useState("");
  const [dateFinError, setDateFinError] = useState("");
  const [perm, setPerm] = useState({
    add: 0,
    delete: 0,
    edit: 0,
    imp: 0,
    view: 0
  });
  const maxHeight = 100;
  const options = [
    { value: 'encaissement', label: "encaissement" },
    { value: 'paiement', label: "paiement" }
  ]
  const [banqueOptions, setBanqueOptions] = useState([]);
  const [MatriculeBancaire, setMatriculeBancaire] = useState([]);
  const [SelectMatriculeBancaire, setSelectMatriculeBancaire] = useState("");
  const [block, setBlock] = useState(false);
  const [montant_a_encaisse, setMontant_a_encaisse] = useState("");
  const [montant_deja_encaisse, setMontant_deja_encaisse] = useState("");
  const [reglements_lignes, setReglements_lignes] = useState("");
  const [factures_restantes, setFactures_restantes] = useState("")

  const [reglementsFor_lignes, setReglementsFor_lignes] = useState("");
  const [facturesFor_restantes, setFacturesFor_restantes] = useState("")


  const [montant_a_paiement, setMontant_a_paiement] = useState("");
  const [montant_deja_paiement, setMontant_deja_paiement] = useState("");


  const [showPopup, setShowPopup] = useState(false);
  const [selectedFact, setSelectedFact] = useState(null);





  /*const handleDownload = async () => {
    try {
      const response = await API.post("stock/generate_excel2", {
        depot_id: depot_id,
        article_id: selectProduit.value 
      }, );

     
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'example.xlsx');
  
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
  
    } catch (error) {
      console.error("Une erreur s'est produite : ", error);
    }
  };*/


  //verificationnnnnnnn


  /*useEffect(() => {
    // Validation pour le dépôt
    if (!selectBanque) {
      setBanqueError("Veuillez sélectionner un banque.");
    } else {
      setBanqueError("");
    }
  }, [selectBanque]);

  useEffect(() => {
    // Validation pour la date de début
    if (!dateDebut) {
      setDateDebutError("Veuillez sélectionner une date de début.");
    } else {
      setDateDebutError("");
    }
  }, [dateDebut]);

  useEffect(() => {
    // Validation pour la date de fin
    if (!dateFin) {
      setDateFinError("Veuillez sélectionner une date de fin.");
    } else {
      setDateFinError("");
    }
  }, [dateFin]);*/

  const handleBanqueChange = (selectedOption) => {
    setBanque_id(selectedOption.value);
    setSelectMatriculeBancaire([{ value: 0, label: "Tous" }]);
    setSelectBanque(selectedOption);

  };
  const handleOpertaionChange = async (selectedOption) => {
    setSelectOperation(selectedOption)

    if (dateDebut != "" && dateFin != "" && selectBanque && selectOperation) {

      if (selectedOption.value === "encaissement") {
        const resArt = await API.post("banque/matricule/suivie_encaissement", {
          banque_id: banque_id,
          MatriculeBancaire: SelectMatriculeBancaire,
          dateDeb: dateDebut,
          dateFin: dateFin

        })
          .then((resArt) => {
            setMontant_a_encaisse(resArt.data.montant_a_encaisse)
            setMontant_deja_encaisse(resArt.data.montant_deja_encaisse)
            setReglements_lignes(resArt.data.reglements_lignes)
            setFactures_restantes(resArt.data.factures_restantes)
            setBlock(true)


          })
      }
      else if (selectedOption.value === "paiement") {
        const resArt = await API.post("banque/matricule/suivie_paiement", {
          banque_id: banque_id,
          MatriculeBancaire: SelectMatriculeBancaire,
          dateDeb: dateDebut,
          dateFin: dateFin

        })
          .then((resArt) => {
            setMontant_a_paiement(resArt.data.montant_a_paye)
            setMontant_deja_paiement(resArt.data.montant_deja_paye)
            setReglementsFor_lignes(resArt.data.reglementsFor_lignes)
            setFacturesFor_restantes(resArt.data.facturesFor_restantes)
            setBlock(true)


          })



      }
    }
  }


  const handleMatriculeChange = (selectedOption) => {


    setSelectMatriculeBancaire(selectedOption)


  };



  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('userAuth'));
    setUserdata(userAuth.user.userData)
  }, [])

  useEffect(() => {
    const typeArticleSubMenu = userdata.find(user =>
      user.sous_menus.some(subMenu => subMenu.sous_menu === "Cadre financier")
    );

    if (typeArticleSubMenu) {
      const typeArticlePermission = typeArticleSubMenu.sous_menus.find(
        subMenu => subMenu.sous_menu === "Cadre financier"
      ).permissions[0];
      setPerm(prevPerm => ({
        ...prevPerm,
        add: typeArticlePermission.add,
        delete: typeArticlePermission.delete,
        edit: typeArticlePermission.edit,
        imp: typeArticlePermission.imp,
        view: typeArticlePermission.view,
      }));
    }
  }, [userdata]);

  useEffect(() => {
    const fetchBanqueOptions = async () => {
      try {
        const resDE = await API.get("banque/select");
        let options = resDE.data;

        options = [
          { value: 0, label: "Tous" },
          ...options,

        ];

        setBanqueOptions(options);
      } catch (error) {
        console.error("Erreur lors de la récupération des options de la banque:", error);
      }
    };

    fetchBanqueOptions();

    setLoading(true);
  }, []);

  useEffect(() => {
    const fetchMatriculeBancaire = async () => {
      try {
        const resP = await API.post("banque/matricule/select_matricule_by_banque", {
          banque_id: banque_id
        });

        let options = resP.data;

        options = [
          { value: 0, label: "Tous" },
          ...options,

        ];

        setMatriculeBancaire(options);
        setLoading(true);
      } catch (error) {
        console.error("Erreur lors de la récupération des matricules bancaires:", error);
        setLoading(false);
      }
    };

    fetchMatriculeBancaire();

    setLoading(true);
  }, [banque_id]);

  const confirmer = async () => {

    if (dateDebut != "" && dateFin != "" && selectBanque && selectOperation) {
      if (dateFin > dateDebut) {


        if (selectOperation.value === "encaissement") {
          const resArt = await API.post("banque/matricule/suivie_encaissement", {
            banque_id: banque_id,
            MatriculeBancaire: SelectMatriculeBancaire,
            dateDeb: dateDebut,
            dateFin: dateFin

          })
            .then((resArt) => {
              setMontant_a_encaisse(resArt.data.montant_a_encaisse)
              setMontant_deja_encaisse(resArt.data.montant_deja_encaisse)
              setReglements_lignes(resArt.data.reglements_lignes)
              setFactures_restantes(resArt.data.factures_restantes)
              setBlock(true)


            })
        }
        else if (selectOperation.value === "paiement") {
          const resArt = await API.post("banque/matricule/suivie_paiement", {
            banque_id: banque_id,
            MatriculeBancaire: SelectMatriculeBancaire,
            dateDeb: dateDebut,
            dateFin: dateFin

          })
            .then((resArt) => {
              setMontant_a_paiement(resArt.data.montant_a_paye)
              setMontant_deja_paiement(resArt.data.montant_deja_paye)
              setReglementsFor_lignes(resArt.data.reglementsFor_lignes)
              setFacturesFor_restantes(resArt.data.facturesFor_restantes)
              setBlock(true)


            })

        }


      }
      else {
        toast.error("⛔ La date de fin doit être plus récente que la date de debut", {
          containerId: "A",
        })
      }
    } else {
      toast.error("⛔ Remplir les champs obligatoire", {
        containerId: "A",
      })
    }


  }


  const handleFactSelect = (Fact) => {
    setSelectedFact(Fact);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };






  /*useEffect(async () => {
    //get suivi 
    const resArt = await API.post("stock/suivie", {
      depot_id: depot_id,
      article_id: produit_id

    })
      .then(resArt => {
        setQuantite_initiale(resArt.data.quantite_de_depart)
        setQuantite_actuelle(resArt.data.quantite_actuelle)
        setQuantite_preveu(resArt.data.quantite_attendue)
        setArrayProduit(resArt.data.log)
       
      })
  }, [produit_id])*/




  const customStyles = {
    rows: {
      style: {
        minHeight: "52px",
      },
    },
    headCells: {
      style: {
        fontSize: "13px",
        backgroundColor: "#eff2f7",
      },
    },
    cells: {
      style: {
        fontSize: "13px",
      },
    },
  }











  const { SearchBar } = Search

  return (
    <React.Fragment>

      <div ref={contentRef}>

        {loading ? (

          <Row>
            <div style={{ height: "350px" }}>
              <Row>
                <Col md={6} sm={6}>
                  <div className="mb-3">
                    <Label for="basicpill-lastname-input2">Date Début</Label>
                    <Label style={{ color: "red", paddingLeft: "5px" }}>
                      *
                    </Label>
                    <Col md={12} className="pr-0">
                      <DatePicker
                        className="form-control ddate"
                        selected={dateDebut}
                        onChange={e => setDateDebut(e)}
                        dateFormat="dd/MM/yyyy"
                        required
                      />
                    </Col>
                  </div>
                </Col>

                <Col md={6} sm={6}>
                  <div className="mb-3">
                    <Label for="basicpill-lastname-input2">Date Fin</Label>
                    <Label style={{ color: "red", paddingLeft: "5px" }}>
                      *
                    </Label>
                    <Col md={12} className="pr-0">
                      <DatePicker
                        className="form-control ddate"
                        selected={dateFin}
                        onChange={e => setDateFin(e)}
                        dateFormat="dd/MM/yyyy"
                        required
                      />
                    </Col>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={4} sm={6}>
                  <div className="mb-3">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Label for="basicpill-firstname-input1">Banque</Label>
                      <Label style={{ color: "red", paddingLeft: "5px" }}>*</Label>
                    </div>
                    <Select
                      required
                      options={banqueOptions}
                      isSearchable={true}
                      classNamePrefix="select2-selection"
                      onChange={selectedOption => {
                        handleBanqueChange(selectedOption);
                      }}
                    />
                  </div>
                </Col>
                <Col md={4} sm={6}>
                  <div className="mb-3">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Label for="basicpill-firstname-input1">Matricule bancaire</Label>
                    </div>
                    <Select
                      options={MatriculeBancaire}
                      isSearchable={true}
                      value={SelectMatriculeBancaire}
                      isDisabled={!selectBanque || MatriculeBancaire.length === 1}
                      classNamePrefix="select2-selection"
                      onChange={selectedOption => {
                        handleMatriculeChange(selectedOption);
                      }}
                      styles={{
                        menu: base => ({ ...base, maxHeight, overflowY: "auto" })
                      }}
                    />
                  </div>
                </Col>

                <Col md={4} sm={6}>
                  <div className="mb-3">
                    <Label for="basicpill-lastname-input2">Opération Financière</Label>
                    <Label style={{ color: "red", paddingLeft: "5px" }}>
                      *
                    </Label>
                    <Select
                      required
                      options={options}
                      isSearchable={true}
                      classNamePrefix="select2-selection"
                      onChange={selectedOption => {
                        handleOpertaionChange(selectedOption);
                      }}
                      styles={{
                        menu: base => ({ ...base, maxHeight, overflowY: "auto" })
                      }}
                    />
                  </div>
                </Col>


                <Col md={12} mt={5} >
                  <div
                    style={{
                      textAlign: 'center',
                      //margintop: "-115px",

                    }}
                    className="text-center "
                  >
                    <button

                      type="submit"
                      className="btn btn-primary "
                      onClick={confirmer}

                    >
                      Confirmer
                    </button>
                  </div>


                </Col>
              </Row>
            </div>
            {block === true && (
              <div style={{ marginTop: "-100px" }}>

                {selectOperation.label === "encaissement" && (
                  <div>
                    <Row style={{ marginTop: '20px' }} >

                      <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '30px' }}>
                        <Card style={{ border: '2px solid', borderRadius: '10px', height: '100px', width: '300px', borderColor: '#D3D3D3' }}>
                          <CardTitle style={{ textAlign: 'center' }}> {"Montant déjà encaissé"} </CardTitle>
                          <CardBody style={{ textAlign: 'center', fontSize: '20px' }}> {montant_deja_encaisse}</CardBody>
                        </Card>
                      </Col>

                      <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '30px' }} >
                        <Card style={{ border: '2px  solid ', borderRadius: '10px', height: '100px', width: "300px", borderColor: '#D3D3D3' }}>
                          <CardTitle style={{ textAlign: 'center' }}> {"Montant à encaisser"} </CardTitle>
                          <CardBody style={{ textAlign: 'center', fontSize: '20px' }}> {montant_a_encaisse}</CardBody>
                        </Card>
                      </Col>
                    </Row>






                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                      <div style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '10px', width: '45%', overflowY: 'auto', maxHeight: '600px' }}>
                        <h2 style={{ marginBottom: '10px', textAlign: 'center', color: 'black' }}> {"Encaissements effectués"}</h2>
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                          {reglements_lignes && reglements_lignes.map((reg_ligne, index) => (


                            <li

                              key={reg_ligne.id}
                              style={{
                                marginBottom: '10px',
                                cursor: 'pointer',
                                padding: '10px',
                                borderRadius: '5px',
                                backgroundColor: (selectedFact !== null && selectedFact.id === reg_ligne.id) ? '#00A6FF' : '#f0f0f0',
                                color: (selectedFact !== null && selectedFact.id === reg_ligne.id) ? '#FFFFFF' : '#000000',
                                position: 'relative',
                                transition: 'background-color 0.3s ease, color 0.3s ease',
                              }}
                              //onClick={() => show_article_depots(article)}
                              onMouseEnter={(e) => {
                                if (selectedFact === null || selectedFact.id !== reg_ligne.id) {
                                  e.target.style.backgroundColor = '#00A6FF';
                                  e.target.style.color = '#FFFFFF';
                                }
                              }}
                              onMouseLeave={(e) => {
                                if (selectedFact === null || selectedFact.id !== reg_ligne.id) {
                                  e.target.style.backgroundColor = '#f0f0f0';
                                  e.target.style.color = '#000000';
                                }
                              }}
                            >
                              {reg_ligne.num_fact}
                              <span >  </span>
                              montant: {reg_ligne.montant}
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                style={{
                                  position: 'absolute',
                                  right: '10px',
                                  top: '50%',
                                  transform: 'translateY(-50%)',
                                  cursor: 'pointer'
                                }}
                                onClick={() => handleFactSelect(reg_ligne)}
                              />

                            </li>
                          ))}


                        </ul>
                      </div>

                      <div style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '10px', width: '45%', overflowY: 'auto', maxHeight: '600px' }}>
                        <h2 style={{ marginBottom: '10px', textAlign: 'center', color: 'black' }}>{"Encaissements restants"} </h2>
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                          {factures_restantes && factures_restantes.map((fact_ligne, index) => (


                            <li

                              key={fact_ligne.id}
                              style={{
                                marginBottom: '10px',
                                cursor: 'pointer',
                                padding: '10px',
                                borderRadius: '5px',
                                backgroundColor: (selectedFact !== null && selectedFact.id === fact_ligne.id) ? '#00A6FF' : '#f0f0f0',
                                color: (selectedFact !== null && selectedFact.id === fact_ligne.id) ? '#FFFFFF' : '#000000',
                                position: 'relative',
                                transition: 'background-color 0.3s ease, color 0.3s ease',
                              }}
                              //onClick={() => show_article_depots(article)}
                              onMouseEnter={(e) => {
                                if (selectedFact === null || selectedFact.id !== fact_ligne.id) {
                                  e.target.style.backgroundColor = '#00A6FF';
                                  e.target.style.color = '#FFFFFF';
                                }
                              }}
                              onMouseLeave={(e) => {
                                if (selectedFact === null || selectedFact.id !== fact_ligne.id) {
                                  e.target.style.backgroundColor = '#f0f0f0';
                                  e.target.style.color = '#000000';
                                }
                              }}
                            >
                              {fact_ligne.num_fact}
                              <span >  </span>
                              montant restant : {fact_ligne.montant_restant}
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                style={{
                                  position: 'absolute',
                                  right: '10px',
                                  top: '50%',
                                  transform: 'translateY(-50%)',
                                  cursor: 'pointer'
                                }}
                                onClick={() => handleFactSelect(fact_ligne)}
                              />

                            </li>
                          ))}


                        </ul>
                      </div>
                      <Modal show={showPopup} onHide={handleClosePopup} centered>
                        <Modal.Header closeButton>
                          <Modal.Title>{selectedFact && selectedFact.num_fact}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {selectedFact && selectedFact.dateEch && (
                            <p>date echeance :{selectedFact && selectedFact.dateEch}
                            </p>)}
                          {selectedFact && selectedFact.dateEnc && (
                            <p>date encaissement :{selectedFact && selectedFact.dateEnc}
                            </p>)}
                          {selectedFact && selectedFact.dateEsp && (
                            <p>date espace  :{selectedFact && selectedFact.dateEsp}
                            </p>)}

                          {selectedFact && selectedFact.montant && (
                            <p>  montant paye  :{selectedFact && selectedFact.montant}</p>)}

                          {selectedFact && selectedFact.total_ttc && (
                            <p> Totale TTC :{selectedFact && selectedFact.total_ttc}</p>)}

                          <p>  montant restant  :{selectedFact && selectedFact.montant_restant}</p>
                          {selectedFact && selectedFact.mode_reglement && (
                            <p>  type de paiement   :{selectedFact && selectedFact.mode_reglement}</p>)}


                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClosePopup}>Fermer</Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                )}


                {selectOperation.label === "paiement" && (
                  <div>
                    <Row style={{ marginTop: '20px' }} >

                      <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '30px' }}>
                        <Card style={{ border: '2px  solid ', borderRadius: '10px', height: '100px', width: "300px", borderColor: '#D3D3D3' }}>
                          <CardTitle style={{ textAlign: 'center' }}> {"Montant déja payé"} </CardTitle>
                          <CardBody style={{ textAlign: 'center', fontSize: '20px' }}> {montant_deja_paiement}</CardBody>

                        </Card>
                      </Col>

                      <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '30px' }} >
                        <Card style={{ border: '2px  solid ', borderRadius: '10px', height: '100px', width: "300px", borderColor: '#D3D3D3' }}>
                          <CardTitle style={{ textAlign: 'center' }}> {"Montant à payé"} </CardTitle>
                          <CardBody style={{ textAlign: 'center', fontSize: '20px' }}> {montant_a_paiement}</CardBody>

                        </Card>
                      </Col>
                    </Row>



                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                      <div style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '10px', width: '45%', overflowY: 'auto', maxHeight: '600px' }}>
                        <h2 style={{ marginBottom: '10px', textAlign: 'center', color: 'black' }}>{"Factures paiement effectué"}</h2>
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                          {reglementsFor_lignes && reglementsFor_lignes.map((regFor_ligne, index) => (


                            <li

                              key={regFor_ligne.id}
                              style={{
                                marginBottom: '10px',
                                cursor: 'pointer',
                                padding: '10px',
                                borderRadius: '5px',
                                backgroundColor: (selectedFact !== null && selectedFact.id === regFor_ligne.id) ? '#00A6FF' : '#f0f0f0',
                                color: (selectedFact !== null && selectedFact.id === regFor_ligne.id) ? '#FFFFFF' : '#000000',
                                position: 'relative',
                                transition: 'background-color 0.3s ease, color 0.3s ease',
                              }}
                              //onClick={() => show_article_depots(article)}
                              onMouseEnter={(e) => {
                                if (selectedFact === null || selectedFact.id !== regFor_ligne.id) {
                                  e.target.style.backgroundColor = '#00A6FF';
                                  e.target.style.color = '#FFFFFF';
                                }
                              }}
                              onMouseLeave={(e) => {
                                if (selectedFact === null || selectedFact.id !== regFor_ligne.id) {
                                  e.target.style.backgroundColor = '#f0f0f0';
                                  e.target.style.color = '#000000';
                                }
                              }}
                            >
                              {regFor_ligne.num_fact}
                              <span >  </span>
                              montant: {regFor_ligne.montant}
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                style={{
                                  position: 'absolute',
                                  right: '10px',
                                  top: '50%',
                                  transform: 'translateY(-50%)',
                                  cursor: 'pointer'
                                }}
                                onClick={() => handleFactSelect(regFor_ligne)}
                              />

                            </li>
                          ))}


                        </ul>
                      </div>

                      <div style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '10px', width: '45%', overflowY: 'auto', maxHeight: '600px' }}>
                        <h2 style={{ marginBottom: '10px', textAlign: 'center', color: 'black' }}>{"Factures à payer"} </h2>
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                          {facturesFor_restantes && facturesFor_restantes.map((factFor_ligne, index) => (


                            <li

                              key={factFor_ligne.id}
                              style={{
                                marginBottom: '10px',
                                cursor: 'pointer',
                                padding: '10px',
                                borderRadius: '5px',
                                backgroundColor: (selectedFact !== null && selectedFact.id === factFor_ligne.id) ? '#00A6FF' : '#f0f0f0',
                                color: (selectedFact !== null && selectedFact.id === factFor_ligne.id) ? '#FFFFFF' : '#000000',
                                position: 'relative',
                                transition: 'background-color 0.3s ease, color 0.3s ease',
                              }}
                              //onClick={() => show_article_depots(article)}
                              onMouseEnter={(e) => {
                                if (selectedFact === null || selectedFact.id !== factFor_ligne.id) {
                                  e.target.style.backgroundColor = '#00A6FF';
                                  e.target.style.color = '#FFFFFF';
                                }
                              }}
                              onMouseLeave={(e) => {
                                if (selectedFact === null || selectedFact.id !== factFor_ligne.id) {
                                  e.target.style.backgroundColor = '#f0f0f0';
                                  e.target.style.color = '#000000';
                                }
                              }}
                            >
                              {factFor_ligne.num_fact}
                              <span >  </span>
                              montant restant : {factFor_ligne.montant_restant}
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                style={{
                                  position: 'absolute',
                                  right: '10px',
                                  top: '50%',
                                  transform: 'translateY(-50%)',
                                  cursor: 'pointer'
                                }}
                                onClick={() => handleFactSelect(factFor_ligne)}
                              />

                            </li>
                          ))}


                        </ul>
                      </div>
                      <Modal show={showPopup} onHide={handleClosePopup} centered>
                        <Modal.Header closeButton>
                          <Modal.Title>{selectedFact && selectedFact.num_fact}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {selectedFact && selectedFact.dateEch && (
                            <p>date echeance :{selectedFact && selectedFact.dateEch}
                            </p>)}
                          {selectedFact && selectedFact.dateEnc && (
                            <p>date encaissement :{selectedFact && selectedFact.dateEnc}
                            </p>)}
                          {selectedFact && selectedFact.dateEsp && (
                            <p>date espace  :{selectedFact && selectedFact.dateEsp}
                            </p>)}

                          {selectedFact && selectedFact.montant && (
                            <p>  montant paye  :{selectedFact && selectedFact.montant}</p>)}

                          {selectedFact && selectedFact.total_ttc && (
                            <p> Totale TTC :{selectedFact && selectedFact.total_ttc}</p>)}

                          <p>  montant restant  :{selectedFact && selectedFact.montant_restant}</p>
                          {selectedFact && selectedFact.mode_reglement && (
                            <p>  type de paiement   :{selectedFact && selectedFact.mode_reglement}</p>)}


                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClosePopup}>Fermer</Button>
                        </Modal.Footer>
                      </Modal>
                    </div>



                  </div>
                )}




              </div>




            )}









          </Row>


        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />


    </React.Fragment>
  )
}
export default withRouter(withTranslation()(CadreFinancier))
CadreFinancier.propTypes = {
  personnel: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
