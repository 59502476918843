import React, { useEffect, useState } from "react"
import Select from "react-select"
import API from "../../api"
import PropTypes from "prop-types"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { withTranslation } from "react-i18next"
import { Button, Col, Label, Row } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import DataTable from "react-data-table-component"
// Checkbox
import Checkbox from "rc-checkbox"
import "rc-checkbox/assets/index.css"
const AddRole = props => {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [roles, setRoles] = useState([])
  const [selectRole, setSelectRole] = useState("")
  // pagination
  const [nbrTotlEl, setNbrTotlEl] = useState(0)
  const [nbrEltperPage, setNbrEltperPage] = useState(5)
  const [filterElement, setFilterElement] = useState("")
  const [mycurrentPage, setMycurrentPage] = useState(1)
  const [resetFirstPage, setResetFirstPage] = useState(false)
  const [listmenu, setListmenu] = useState([])
  //const [data, setData] = useState([])
  const [permissions, setPermissions] = useState([]);

  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatchError, setPasswordMatchError] = useState('');
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);


  useEffect(async () => {

    API.get("menu/list").then(resU => {

      console.log(resU.data.Menu)
      // setData(resU.data.Menu)
      const updatedListMenu = resU.data.Menu.map(menuItem => ({
        ...menuItem,  // Garde les attributs existants de l'élément menu
        sous_menu: menuItem.sous_menu.map(subMenuItem => ({
          ...subMenuItem, // Garde les attributs existants de l'élément sous-menu
          allChecked: false // Ajoute l'attribut allChecked avec une valeur par défaut de false
        }))
      }));
      // setNbrTotlEl(res.data.Data[0].total)
      setListmenu(updatedListMenu)
    })
  }, [])
  console.log(listmenu)

  const checkMenu = (data) => {
   

    const index = listmenu.findIndex(item => item.menu_id === data.data.id);

    if (index !== -1) { 
        const menuItem = listmenu[index]; 
        const sousMenuCount = menuItem.sous_menu.length; 


        const e = { 
            target: { checked: true }
        };

        for (let i = 0; i < sousMenuCount; i++) {
            addPerm(index, e, i, 'add');
            addPerm(index, e, i, 'edit');
            addPerm(index, e, i, 'delete');
            addPerm(index, e, i, 'view');
            addPerm(index, e, i, 'imp');

        }
    } else {
        console.log("ID non trouvé dans listmenu");
    }
}


const uncheckMenu = (data) => {
  console.log("ID donné :", data.data.id);
  console.log("Liste des menus :", listmenu);

  const index = listmenu.findIndex(item => item.menu_id === data.data.id);
  console.log("Index dans listmenu :", index);

  if (index !== -1) { 
      const menuItem = listmenu[index]; 
      const sousMenuCount = menuItem.sous_menu.length; 

      console.log("Nombre d'éléments dans sous_menu :", sousMenuCount);

      const e = { 
          target: { checked: false }
      };

      for (let i = 0; i < sousMenuCount; i++) {
          addPerm(index, e, i, 'add');
          addPerm(index, e, i, 'edit');
          addPerm(index, e, i, 'delete');
          addPerm(index, e, i, 'view');
          addPerm(index, e, i, 'imp');

      }
  } else {
      console.log("ID non trouvé dans listmenu");
  }
}


  const checkSubMenu = (index, i) => {
    const e = {
      target: { checked: true }
    };

    addPerm(index, e, i, 'add');
    addPerm(index, e, i, 'delete');
    addPerm(index, e, i, 'edit');
    addPerm(index, e, i, 'view');
    addPerm(index, e, i, 'imp');
  };
  const uncheckSubMenu = (index, i) => {
    const e = {
      target: { checked: false }
    };
    console.log("index",index)
    console.log("i",i)
    addPerm(index, e, i, 'add');
    addPerm(index, e, i, 'delete');
    addPerm(index, e, i, 'edit');
    addPerm(index, e, i, 'view');
    addPerm(index, e, i, 'imp');
  };




  const addPerm = (index, e, rowIndex, attribute) => {

    setPermissions((prevPermissions) => {
      const updatedPermissions = [...prevPermissions];
      const rowData = dataa[index].Detail[rowIndex];

      if (!updatedPermissions[index]) {
        updatedPermissions[index] = [];
      }

      if (!updatedPermissions[index][rowIndex]) {
        updatedPermissions[index][rowIndex] = {
          sousmenu: rowData.props.children.props.data[0].id,
          add: 0,
          delete: 0,
          edit: 0,
          view: 0,
          imp: 0,
        };
      }

      // Update the attribute value for this combination of index and rowIndex
      updatedPermissions[index][rowIndex][attribute] = e.target.checked ? 1 : 0;
      return updatedPermissions;
      // console.log(updatedPermissions)
    });
  };
  // const addPerm = (index,e, rowIndex, attribute) => {
  //   const updatedPermissions = [...permissions];
  //   console.log(updatedPermissions)
  //   const rowData = dataa[index].Detail[rowIndex];
  //   console.log(index)
  //   console.log(rowIndex)
  //   console.log(rowData)
  //   console.log(dataa)
  // console.log(  rowData.props.children.props.data)
  // console.log(updatedPermissions[index])

  // console.log(updatedPermissions[rowIndex])
  //   if (!updatedPermissions[rowIndex]) {
  //     updatedPermissions[rowIndex] = {
  //       sousmenu: rowData.props.children.props.data[0].id,
  //       add: 0,
  //       delete: 0,
  //       edit: 0,
  //       view: 0,
  //       imp: 0,
  //     };


  //   }
  //   console.log( updatedPermissions[rowIndex])
  //   updatedPermissions[rowIndex][attribute] = e.target.checked ? 1 : 0;

  //   setPermissions(updatedPermissions);
  //   console.log(permissions)
  // };

  // The rest of your code





  // const dataa = [
  //   {listmenu.map((el, index) => (
  //   //Object.keys(listmenu).map((el,index)=>(
  //  {
  // id:el.menu_id,
  // name:el.menu,
  // Detail : 
  //   <DataTable
  //     columns={[
  //       { name: 'sousmenu', selector: 'sousmenu', sortable: true },
  //       { name: 'modifier', selector: 'modifier', sortable: true },
  //       { name: 'ajouter', selector: 'ajouter', sortable: true },
  //       { name: 'supprimer', selector: 'supprimer', sortable: true },
  //       { name: 'consulter', selector: 'consulter', sortable: true },

  //     ]}

  //     data={

  //       [Object.keys(el.sous_menu).map((elsousmenu,i)=>(

  //       { id: 1, sousmenu: elsousmenu.sous_menu, modifier:(<div key={i} style={{ display: "flex", justifyContent: "center" }}>
  //       <Checkbox

  //                   // onChange={e => cocheMontant(e, index)}
  //                  //  disabled={disable}
  //                  />
  //    </div>),ajouter:(<div style={{ display: "flex", justifyContent: "center" }}>
  //       <Checkbox

  //                   // onChange={e => cocheMontant(e, index)}
  //                  //  disabled={disable}
  //                  />
  //    </div>),supprimer:(<div style={{ display: "flex", justifyContent: "center" }}>
  //       <Checkbox

  //                   // onChange={e => cocheMontant(e, index)}
  //                  //  disabled={disable}
  //                  />
  //    </div>),consulter:(<div style={{ display: "flex", justifyContent: "center" }}>
  //       <Checkbox

  //                   // onChange={e => cocheMontant(e, index)}
  //                  //  disabled={disable}
  //                  />
  //    </div>)},

  //        { id: 2, sousmenu: 'bon livraison', modifier:(<div style={{ display: "flex", justifyContent: "center" }}>
  //        <Checkbox
  //                    // onChange={e => cocheMontant(e, index)}
  //                   //  disabled={disable}
  //                   />
  //     </div>)}

  // ))



  //   ]  }
  //         noHeader

  //   />}))}
  //] 

  console.log(listmenu);

  const expandableRowsComponent = (row) => (

    <div>
      {/* {dataa.map((el, index) => (
      // Check if row.unique_id is equal to index before rendering the div
        <div key={index}>{el.Detail}{console.log(el.Detail)}{console.log(row)}</div>
      
    ))} */}
      <button style={{ marginRight: '5px', backgroundColor: 'green', color: 'white', border: 'none', borderRadius: '5px' }} onClick={(e) => checkMenu(row)} ><i className="fa fa-check-circle" style={{ color: 'white' }}></i></button>
      <button style={{ backgroundColor: 'red', color: 'white', border: 'none', borderRadius: '5px' }} ><i className="fa fa-times-circle" style={{ color: 'white' }} onClick={(e) =>uncheckMenu(row)} ></i></button>

      {row.data.Detail.map((el, i) => (

        <div key={i}>
          {el}
        </div>
      ))}
    </div>
  );
  // const expandableRowsComponent = (row) => (
  //   <div>
  //     {dataa.map((el, index) => (
  //       // Check if row.unique_id is equal to index before rendering the div
  //         <div key={index}>{el.Detail}{console.log(el.Detail)}{console.log(row)}</div>

  //     ))}
  //   </div>
  // );

  // <div>{dataa.Detail.map((row,index)=>( <div key={index}>{row.data}</div>))}</div>;

  const dataa = listmenu.map((menu, index) => ({
    id: menu.menu_id,
    name: menu.menu,
    Detail: menu.sous_menu.map((sousmenu, i) => (



      <div key={i}>



        <DataTable
          columns={[
            {
              name: 'sousmenu',
              cell: row => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <button style={{ marginRight: '5px', backgroundColor: 'green', color: 'white', border: 'none', borderRadius: '5px' }} onClick={(e) => checkSubMenu(index, i)} ><i className="fa fa-check-circle" style={{ color: 'white' }}></i></button>
                  <button style={{ backgroundColor: 'red', color: 'white', border: 'none', borderRadius: '5px' }} ><i className="fa fa-times-circle" style={{ color: 'white' }} onClick={(e) => uncheckSubMenu(index, i)} ></i></button>


                </div>
              ),
              sortable: true
            },
            { name: 'sousmenu', selector: 'sousmenu', sortable: true },
            { name: 'ajouter', selector: 'ajouter', sortable: true },
            { name: 'supprimer', selector: 'supprimer', sortable: true },
            { name: 'modifier', selector: 'modifier', sortable: true },
            { name: 'consulter', selector: 'consulter', sortable: true },
            { name: 'imprimer', selector: 'imprimer', sortable: true },
          ]}

          data={
            [{



              id: sousmenu.sous_menu_id,
              sousmenu: sousmenu.sous_menu,

              Tous: (
                <div key={i} style={{ display: 'flex', marginRight: 0 }}>
                  <Checkbox
                    onChange={(e) => checkSubMenu(index, e, i)}

                  />



                </div>
              ),

              ajouter: (
                <div key={i} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Checkbox
                    checked={permissions[index]?.[i]?.add === 1}
                    onChange={(e) => addPerm(index, e, i, 'add')} />


                </div>
              ),
              supprimer: (
                <div key={i} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Checkbox
                    checked={permissions[index]?.[i]?.delete === 1}
                    onChange={(e) => addPerm(index, e, i, 'delete')} />
                </div>
              ),
              modifier: (
                <div key={i} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Checkbox
                    checked={permissions[index]?.[i]?.edit === 1}
                    onChange={(e) => addPerm(index, e, i, 'edit')} />
                </div>
              ),
              consulter: (
                <div key={i} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Checkbox
                    checked={permissions[index]?.[i]?.view === 1}
                    onChange={(e) => addPerm(index, e, i, 'view')} />
                </div>
              ),
              imprimer: (
                <div key={i} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Checkbox
                    checked={permissions[index]?.[i]?.imp === 1}
                    onChange={(e) => addPerm(index, e, i, 'imp')} />
                </div>
              )
            }]




          }
          noHeader

        //  expandableRowsComponent={YourComponent}
        />
      </div>
    )),
  }));

  // Now you can use the 'data' array in your component









  // const data = [
  //   { id: 1, name: 'espace fournisseur', 
  //   Detail: ( 
  //   <DataTable
  //     columns={[
  //       { name: 'sousmenu', selector: 'sousmenu', sortable: true },
  //       { name: 'modifier', selector: 'modifier', sortable: true },
  //       { name: 'ajouter', selector: 'ajouter', sortable: true },
  //       { name: 'supprimer', selector: 'supprimer', sortable: true },
  //       { name: 'consulter', selector: 'consulter', sortable: true },

  //     ]}
  //     // data contient : id et le nom de sous menu
  //     data={[
  //       { id: 1, sousmenu: 'bon commande', modifier:(<div style={{ display: "flex", justifyContent: "center" }}>
  //       <Checkbox

  //                   // onChange={e => cocheMontant(e, index)}
  //                  //  disabled={disable}
  //                  />
  //    </div>),ajouter:(<div style={{ display: "flex", justifyContent: "center" }}>
  //       <Checkbox

  //                   // onChange={e => cocheMontant(e, index)}
  //                  //  disabled={disable}
  //                  />
  //    </div>),supprimer:(<div style={{ display: "flex", justifyContent: "center" }}>
  //       <Checkbox

  //                   // onChange={e => cocheMontant(e, index)}
  //                  //  disabled={disable}
  //                  />
  //    </div>),consulter:(<div style={{ display: "flex", justifyContent: "center" }}>
  //       <Checkbox

  //                   // onChange={e => cocheMontant(e, index)}
  //                  //  disabled={disable}
  //                  />
  //    </div>)},

  //        { id: 2, sousmenu: 'bon livraison', modifier:(<div style={{ display: "flex", justifyContent: "center" }}>
  //        <Checkbox
  //                    // onChange={e => cocheMontant(e, index)}
  //                   //  disabled={disable}
  //                   />
  //     </div>)},
  //     ]}
  //     noHeader

  //   />) },
  //   { id: 2, name: 'Jane Smith', age: 28, country: 'Canada', children: <div>Child Row Data 2</div> },
  //   { id: 3, name: 'Jane Smith', age: 28, country: 'Canada', children: <div>Child Row Data 2</div> },
  //   // { id: 4, name: 'Jane Smith', age: 28, country: 'Canada', children: <div>Child Row Data 2</div> },
  //   // { id: 5, name: 'Jane Smith', age: 28, country: 'Canada', children: <div>Child Row Data 2</div> },
  //   // { id: 6, name: 'Jane Smith', age: 28, country: 'Canada', children: <div>Child Row Data 2</div> },
  //   // { id: 7, name: 'Jane Smith', age: 28, country: 'Canada', children: <div>Child Row Data 2</div> },
  //   // { id: 8, name: 'Jane Smith', age: 28, country: 'Canada', children: <div>Child Row Data 2</div> },
  //   // { id: 9, name: 'Jane Smith', age: 28, country: 'Canada', children: <div>Child Row Data 2</div> },
  //   // { id: 10, name: 'Jane Smith', age: 28, country: 'Canada', children: <div>Child Row Data 2</div> },
  //   // { id: 11, name: 'Jane Smith', age: 28, country: 'Canada', children: <div>Child Row Data 2</div> },
  //   // { id: 12, name: 'Jane Smith', age: 28, country: 'Canada', children: <div>Child Row Data 2</div> },

  //   // Add other rows with children as needed
  // ];
  const renderChildRow = (row) => {
    return (
      <div>
        <p>Name: {row.name}</p>
        <p>Age: {row.age}</p>
        <p>Country: {row.country}</p>
        {/* You can add other child row information as needed */}
      </div>
    );
  };
  const columns = [

    { name: 'Menu', selector: (row) => row.name },

  ];

  //const expandableRowsComponent = ({ dataa }) => <div>{dataa.Detail}</div>;








  const save = async () => {
    if (name != "") {

      const res = await API.post("role/add", {
        name,
        permissions: permissions,

      }).then(res => {
        props.setSection(1)
        console.log("role", res)
      })

    }
  }
  const newElement = async (page, totalRows) => {
    setMycurrentPage(page)
    const res = await API.get("menu/list")
      .then(res => {
        setListmenu(res.data.Menu)
        console.log(listmenu)
        //setNbrTotlEl(res.data.Data[0].total)
      })
  }

  const perPage = async (currentRowsPerPage, currentPage) => {
    setMycurrentPage(currentPage)
    const res = await API.get("menu/list")
      .then(res => {
        setListmenu(res.data.Menu)
        // setNbrTotlEl(res.data.Data[0].total)
      })
  }


  return (
    <React.Fragment>
      <div>
        <Row>
          <AvForm>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Nom du role</Label>
                  <AvField
                    name="type"
                    placeholder="Inserer un type"
                    type="text"
                    errorMessage="* Nom obligatoire"
                    className="form-control"
                    onChange={e => setName(e.target.value)}
                    validate={{ required: { value: true } }}
                  />
                </div>
              </Col>
            </Row>
            <Row>


              {/*<Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-address-input1">Rôle</Label>
                  <Select
                    options={roles}
                    isSearchable={true}
                    classNamePrefix="select2-selection"
                    onChange={setSelectRole}
                  />
                </div>
                </Col>*/}
            </Row>
            {/* datatable permissions */}
            <DataTable
              title="Permissions"
              columns={columns}
              data={dataa}
              // pagination={true}
              // paginationServer={true}
              // onChangePage={(page, totalRows) => newElement(page, totalRows)}
              expandableRows
              expandableRowsComponent={expandableRowsComponent}
              //onExpandableRowClicked={expandableRowsComponent}
              onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                perPage(currentRowsPerPage, currentPage)
              }
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  className="text-center mt-4"
                >
                  <Button
                    type="button"
                    color="warning"
                    className="btn btn-warning  mb-2 me-2"
                    onClick={props.back}
                  >
                    {props.t("Annuler")}
                  </Button>
                </div>
              </Col>
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
                >
                  <button
                    onClick={save}
                    type="submit"
                    className="btn btn-primary "
                  >
                    Confirmer
                  </button>
                </div>
              </Col>
            </div>
          </AvForm>



        </Row>
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withTranslation()(AddRole)

AddRole.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}