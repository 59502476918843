import React , {useEffect, useState} from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import profileImg from "../../assets/images/profile-img.png"
import user1 from "../../assets/images/users/userImage.png"
import API from "../../api"
import cover_photo from "../../assets/images/cover-erp.png"
const WelcomeComp = () => {
  const [name, setName] = useState("")

  const [nbClient, setnbClient] = useState("")
  const[nbproduit, setnbProduit]= useState("")
  const[nbFournisseur, setnbFournisseur]= useState("")


  const [photo , setPhoto]= useState(JSON.parse(localStorage.getItem("userAuth")).user.photo64)
 
  useEffect(async () => {
    const res = await API.get("dashboard/stats").then(res => {



      setName(res.data.nom_societe)
      setnbClient(res.data.nb_clients)
      setnbFournisseur(res.data.nb_fournisseurs)
      setnbProduit(res.data.nb_produits)
   


  })
 

 }, [])

   

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div 
        style={{
          backgroundImage: `url(${cover_photo})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          height: '50vh', 
        }}
        >
          <Row>
            <Col xs="12">
              <div className="text-primary p-3">
                <h4 className="text-primary">Bienvenue</h4>
                <h5>CRESUS</h5>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={`data:image/png;base64, ${photo}` }
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h5 className="font-size-15 text-truncate">{name}</h5>
            </Col>

            <Col lg sm="8">
              <div className="pt-4">
                <Row>
                  <Col md="4" xs="12">
                    <h5 className="font-size-15">{nbClient}</h5>
                    <p className="text-muted mb-0">Nombre des clients</p>
                  </Col>
                  <Col md="4" xs="12">
                    <h5 className="font-size-15">{nbFournisseur}</h5>
                    <p className="text-muted mb-0">Nombre des fournisseur</p>
                  </Col>
                  <Col md="4" xs="12">
                    <h5 className="font-size-15">{nbproduit}</h5>
                    <p className="text-muted mb-0">Nombre des produits</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
