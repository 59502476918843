import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import API from "../../../api"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardBody,
  Table,
} from "reactstrap"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"

const AddBonRetourFournisseur = props => {
  var userAuth = JSON.parse(localStorage.getItem("userAuth"))
  const user_id = userAuth.user.id
  const annee = userAuth.user.year
  const [numRetour, setNumRetour] = useState("")
  const [dateRetour, setDateRetour] = useState(new Date())
  const [numEntree, setNumEntree] = useState([])
  const [selectNumEntree, setSelectNumEntree] = useState("")
  const [dateEntree, setDateEntree] = useState("")
  const [fournisseur, setFournisseur] = useState([])
  const [selectFournisseur, setSelectFournisseur] = useState("")
  const [adresse, setAdresse] = useState("")
  const [raisonSocial, setRaisonSocial] = useState("")
  const [codeTva, setCodeTva] = useState("")
  const [etatFodec, setEtatFodec] = useState("")
  const [modeReglement, setModeReglement] = useState([])
  const [selectModeReglement, setSelectModeReglement] = useState("")
  const [depot, setDepot] = useState([])
  const [selectDepot, setSelectDepot] = useState("")
  const [reference, setReference] = useState("")
  const [dateReference, setDateReference] = useState(new Date())
  const [montantRef, setMontantRef] = useState("")
  const [observation, setObservation] = useState("")
  const [modal, setModal] = useState(false)
  const [indexLigne, setIndexLigne] = useState("")
  const [arrayProduit, setArrayProduit] = useState([
    {
      produit_id: "",
      designation: "",
      qte: "",
      prix_ht: "",
      remise: "",
      fodec: "",
      montant_ht: 0,
      tva: "",
      montant_ttc: 0,
      montant_remise: 0,
      montant_fodec: 0,
      depot_id: "",
      depots: [],
      product_selected_depot:null

    },
  ])
  const [produits, setProduits] = useState([])
  const [selectProduit, setSelectProduit] = useState("")
  const [indexOfProduit, setIndexOfProduit] = useState("")
  //
  const [prixHT, setPrixHT] = useState("")
  const [designation, setDesignation] = useState("")
  const [tva, setTva] = useState("")
  const [quantite, setQuantite] = useState("")
  const [totalHT, setTotalHT] = useState("")
  const [totalTTC, setTotalTTC] = useState("")
  const [totalTva, setTotalTva] = useState("")
  const [totalRemise, setTotalRemise] = useState("")
  const [remise, setRemise] = useState("")
  const [totalFodec, setTotalFodec] = useState("")
  const [fodec, setFodec] = useState("")
  const [buttonClicked, setButtonClicked] = useState(false);


  useEffect(async () => {
    const resL = await API.post("fournisseur/retour/num",{
      annee:annee
    }).then(resL => {

      setNumRetour(resL.data.BonRetour)
     
    })
    const resN = await API.get("fournisseur/retour/get_num_retour").then(
      resN => {
        setNumEntree(resN.data.BonEntree)
      }
    )
    const res = await API.get("fournisseur/select").then(res => {
      setFournisseur(res.data.Fournisseur)
    })
    const resM = await API.get("mode/reglement/select").then(resM => {
      setModeReglement(resM.data.ModeReglement)
    })
    const resD = await API.get("depot/select").then(resA => {
      setDepot(resA.data.Depot)
    })
  }, [])

  const getFournisseur = async selectNumEntree => {
    if (selectNumEntree != null) {
      var numEntree_id = selectNumEntree.value
    } else {
      var numEntree_id = ""
    }
    setSelectNumEntree(selectNumEntree)
    const res = await API.post("fournisseur/retour/entree", {
      id: numEntree_id,
      
    }).then(
      res => {
     setSelectFournisseur(res.data.BonEntree.fournisseur_id)
      setAdresse(res.data.BonEntree.adresse)
      setEtatFodec(res.data.BonEntree.fodec)
      //setDate(new Date(res.data.BonEntree.date_entree))
      setRaisonSocial(res.data.BonEntree.raisonSocial)
      setCodeTva(res.data.BonEntree.code)
      setArrayProduit(res.data.BonEntree.ligne)
      setTotalHT(res.data.BonEntree.total_ht)
      setTotalRemise(res.data.BonEntree.total_remise)
      setTotalTva(res.data.BonEntree.total_tva)
      setTotalFodec(res.data.BonEntree.total_fodec)
      setTotalTTC(res.data.BonEntree.total_ttc)
      setDateEntree(res.data.BonEntree.date_entree)
      setSelectModeReglement(res.data.BonEntree.mode_reglement)
      //setFournisseur([])
    })
  }

  const getnumEntree = async selectFournisseur => {
   
    if (selectFournisseur != null) {
      var fournisseur_id = selectFournisseur.value
      
    } else {
      var fournisseur_id = ""
     
    }
    
    setSelectFournisseur(selectFournisseur)
    setArrayProduit([
      {
        produit_id: "",
        designation: "",
        qte: "",
        prix_ht: "",
        remise: "",
        fodec: "",
        montant_ht: 0,
        tva: "",
        montant_ttc: 0,
        montant_remise: 0,
        montant_fodec: 0,
        depot_id: "",
        depots: [],
        product_selected_depot:null
  
      },
    ])
    setDateEntree("")
    setSelectNumEntree("")

    
    const res = await API.post("fournisseur/get_by_id", {
      id: fournisseur_id,
    }).then(res => {
      setAdresse(res.data.Fournisseur.adresse)
      setEtatFodec(res.data.Fournisseur.fodec)
      setRaisonSocial(res.data.Fournisseur.raisonSocial)
      setCodeTva(res.data.Fournisseur.code)
      setSelectModeReglement(res.data.Fournisseur.mode_reglement_id)

    })
    const resN = await API.post("fournisseur/retour/fournisseur", {
      fournisseur_id: fournisseur_id,
    }).then(resN => {
      setNumEntree(resN.data.CommandeFournisseur)
    })
    const resA = await API.get("article/select").then(resA => {
      setProduits(resA.data.Article)
    })
  }

  const addLigne = () => {
    let element = {
      produit_id: "",
      designation: "",
      qte: "",
      prix_ht: "",
      remise: "",
      fodec: "",
      montant_ht: 0,
      tva: "",
      montant_ttc: 0,
      montant_remise: 0,
      montant_fodec: 0,
      depot_id: "",
      depots: [],
      product_selected_depot:null
      
    }
    setArrayProduit([...arrayProduit, element])
  }

  const select_articleDepot = (depot, indexOfProduit) => {
    
  
    const selectedOption = {
      value: depot.value,
      label: depot.label,
    };
    setArrayProduit((prevArrayProduit) =>
      prevArrayProduit.map((el, id) =>
        id === indexOfProduit
          ? {
              ...el,
              depot_id: depot.value,
              product_selected_depot: selectedOption,
            }
          : el
      )
    );
  };

  const refrech = async () => {
    const res = await API.post("fournisseur/retour/entree", {
      id: selectNumEntree.value,
    }).then(res => {
      setSelectFournisseur(res.data.BonEntree.fournisseur_id)
      setAdresse(res.data.BonEntree.adresse)
      setEtatFodec(res.data.BonEntree.fodec)
      //setDate(new Date(res.data.BonEntree.date_entree))
      setRaisonSocial(res.data.BonEntree.raisonSocial)
      setCodeTva(res.data.BonEntree.code)
      setArrayProduit(res.data.BonEntree.ligne)
      setTotalHT(res.data.BonEntree.total_ht)
      setTotalRemise(res.data.BonEntree.total_remise)
      setTotalTva(res.data.BonEntree.total_tva)
      setTotalFodec(res.data.BonEntree.total_fodec)
      setTotalTTC(res.data.BonEntree.total_ttc)
      setDateEntree(res.data.BonEntree.date_entree)

    // setFournisseur([])
    })
  }

  const toggle = indexOfLigne => {
    setModal(!modal)
    setIndexLigne(indexOfLigne)
  }

  const toggleDelete = async () => {
    arrayProduit.splice(indexLigne, 1)
    setModal(false)
  }

  useEffect(() => {
    var tot_ht = arrayProduit
      .map(elem => elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ht = parseFloat(tot_ht).toFixed(3)
    setTotalHT(total_ht)
    var tot_ttc = arrayProduit
      .map(elem => elem.montant_ttc)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ttc = parseFloat(tot_ttc).toFixed(3)
    setTotalTTC(total_ttc)
    var tot_tva = arrayProduit
      .map(elem => elem.montant_ttc - elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_tva = parseFloat(tot_tva).toFixed(3)
    setTotalTva(total_tva)
    var tot_remise = arrayProduit
      .map(elem => elem.montant_remise)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_rem = parseFloat(tot_remise).toFixed(3)
    setTotalRemise(total_rem)
    var tot_fodec = arrayProduit
      .map(elem => elem.montant_fodec)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_fodec = parseFloat(tot_fodec).toFixed(3)
    setTotalFodec(total_fodec)
  }, [modal])

  const getProduits = async (selectProduit, indexOfProduit) => {
    if (selectProduit != null) {
      var produit_id = selectProduit.value
    } else {
      var produit_id = ""
    }
    if (selectProduit != null) {
      var produit = selectProduit
    } else {
      var produit = ""
    }
    setIndexOfProduit(indexOfProduit)
    const res = await API.post("article/get_by_id", {
      id: produit_id,
    })
      .then(res => {
        setDesignation(res.data.Article.designation)
        setPrixHT(res.data.Article.prix_achat_ht)
        setTva(res.data.Article.tva)
        setFodec(res.data.Article.fodec)
        setSelectProduit(selectProduit)
      })
      .then(() => {
        setArrayProduit(
          arrayProduit.map((el, id) =>
            id === indexOfProduit
              ? Object.assign(el, {
                  produit_id: produit,
                  designation: designation,
                  prix_ht: prixHT,
                  tva: tva,
                  fodec: fodec,
                })
              : el
          )
        )
      })
      .then(() => {
        var tot_ht = arrayProduit
          .map(elem => elem.montant_ht)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_ht = parseFloat(tot_ht).toFixed(3)
        setTotalHT(total_ht)
        var tot_ttc = arrayProduit
          .map(elem => elem.montant_ttc)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_ttc = parseFloat(tot_ttc).toFixed(3)
        setTotalTTC(total_ttc)
        var tot_tva = arrayProduit
          .map(elem => elem.montant_ttc - elem.montant_ht)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_tva = parseFloat(tot_tva).toFixed(3)
        setTotalTva(total_tva)
        var tot_remise = arrayProduit
          .map(elem => elem.montant_remise)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_rem = parseFloat(tot_remise).toFixed(3)
        setTotalRemise(total_rem)
        var tot_fodec = arrayProduit
          .map(elem => elem.montant_fodec)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_fodec = parseFloat(tot_fodec).toFixed(3)
        setTotalFodec(total_fodec)
      })
  }

  useEffect(() => {
    if (selectProduit != null) {
      var produit = selectProduit
    } else {
      var produit = ""
    }
    setArrayProduit(
      arrayProduit.map((el, id) =>
        id === indexOfProduit
          ? Object.assign(el, {
              produit_id: produit,
              designation: designation,
              prix_ht: prixHT,
              tva: tva,
              fodec: fodec,
            })
          : el
      )
    )
  }, [selectProduit])

  const addQuantite = (e, indexOfQte) => {
    setQuantite(e)
    var qte = e
    var prixHT = arrayProduit[indexOfQte].prix_ht
    var tva = arrayProduit[indexOfQte].tva.label
    var fodec = arrayProduit[indexOfQte].fodec
    var remise = arrayProduit[indexOfQte].remise
    var qte_liv = arrayProduit[indexOfQte].qte_liv
    var montantHT = prixHT * qte
    // calcule total HT avec remise
    var montantRemise = montantHT - (montantHT * remise) / 100
    // calcule total remise
    var totalRemise = montantHT - montantRemise
    // calcule fodec
    if (etatFodec == 1 && fodec == 1) {
      var montantFodec = (montantRemise * 1) / 100
    } else if (etatFodec == 0 || fodec == 0) {
      var montantFodec = 0
    }
    // calcule TTC
    var montantHT_fodec = montantRemise + montantFodec
    var montantTTC = (montantHT_fodec * tva) / 100 + parseFloat(montantHT_fodec)
    if (selectNumEntree == "") {
      if (0 <= qte) {
        setArrayProduit(
          arrayProduit.map((el, id) =>
            id === indexOfQte
              ? Object.assign(el, {
                  qte: qte,
                  montant_ht: parseFloat(montantRemise).toFixed(3),
                  montant_remise: parseFloat(totalRemise).toFixed(3),
                  montant_ttc: parseFloat(montantTTC).toFixed(3),
                  montant_fodec: parseFloat(montantFodec).toFixed(3),
                })
              : el
          )
        )
      }
    } else {
      if (qte_liv != 0) {
        if (qte <= qte_liv && 0 <= qte) {
          setArrayProduit(
            arrayProduit.map((el, id) =>
              id === indexOfQte
                ? Object.assign(el, {
                    qte: qte,
                    montant_ht: parseFloat(montantRemise).toFixed(3),
                    montant_remise: parseFloat(totalRemise).toFixed(3),
                    montant_ttc: parseFloat(montantTTC).toFixed(3),
                    montant_fodec: parseFloat(montantFodec).toFixed(3),
                  })
                : el
            )
          )
        } else {
          toast.error("⛔ Veuillez vous ne pas dépasser la quantité", {
            containerId: "A",
          })
        }
      }
    }
  }

  useEffect(() => {
    var tot_ht = arrayProduit
      .map(elem => elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ht = parseFloat(tot_ht).toFixed(3)
    setTotalHT(total_ht)
    var tot_ttc = arrayProduit
      .map(elem => elem.montant_ttc)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ttc = parseFloat(tot_ttc).toFixed(3)
    setTotalTTC(total_ttc)
    var tot_tva = arrayProduit
      .map(elem => elem.montant_ttc - elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_tva = parseFloat(tot_tva).toFixed(3)
    setTotalTva(total_tva)
    var tot_remise = arrayProduit
      .map(elem => elem.montant_remise)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_rem = parseFloat(tot_remise).toFixed(3)
    setTotalRemise(total_rem)
    var tot_fodec = arrayProduit
      .map(elem => elem.montant_fodec)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_fodec = parseFloat(tot_fodec).toFixed(3)
    setTotalFodec(total_fodec)
  }, [quantite])

  const addRemise = (e, indexOfRemise) => {
    setRemise(e)
    var remise = e
    var qte = arrayProduit[indexOfRemise].qte
    var prixHT = arrayProduit[indexOfRemise].prix_ht
    var fodec = arrayProduit[indexOfRemise].fodec
    var tva = arrayProduit[indexOfRemise].tva.label
    // calcule HT avec remise
    var montantHT = prixHT * qte
    var montantRemise = montantHT - (montantHT * remise) / 100
    // calcule total remise
    var totalRemise = montantHT - montantRemise
    // calcule fodec
    if (etatFodec == 1 && fodec == 1) {
      var montantFodec = (montantRemise * 1) / 100
    } else if (etatFodec == 0 || fodec == 0) {
      var montantFodec = 0
    }
    // calcule TTC
    var montantHT_fodec = montantRemise + montantFodec
    var montantTTC = (montantHT_fodec * tva) / 100 + parseFloat(montantHT_fodec)
    if (0 <= remise && remise <= 100) {
      setArrayProduit(
        arrayProduit.map((el, id) =>
          id === indexOfRemise
            ? Object.assign(el, {
                montant_ht: parseFloat(montantRemise).toFixed(3),
                remise: remise,
                montant_remise: parseFloat(totalRemise).toFixed(3),
                montant_ttc: parseFloat(montantTTC).toFixed(3),
                montant_fodec: parseFloat(montantFodec).toFixed(3),
              })
            : el
        )
      )
    }
  }

  useEffect(() => {
    var tot_ht = arrayProduit
      .map(elem => elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ht = parseFloat(tot_ht).toFixed(3)
    setTotalHT(total_ht)
    var tot_ttc = arrayProduit
      .map(elem => elem.montant_ttc)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ttc = parseFloat(tot_ttc).toFixed(3)
    setTotalTTC(total_ttc)
    var tot_tva = arrayProduit
      .map(elem => elem.montant_ttc - elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_tva = parseFloat(tot_tva).toFixed(3)
    setTotalTva(total_tva)
    var tot_remise = arrayProduit
      .map(elem => elem.montant_remise)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_rem = parseFloat(tot_remise).toFixed(3)
    setTotalRemise(total_rem)
    var tot_fodec = arrayProduit
      .map(elem => elem.montant_fodec)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_fodec = parseFloat(tot_fodec).toFixed(3)
    setTotalFodec(total_fodec)
  }, [remise])

  const save = async () => {

   /* if (dateRetour != "") {
      let dateRet = dateRetour
      let month = "" + (dateRet.getMonth() + 1)
      let day = "" + dateRet.getDate()
      let year = dateRet.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDateRet = [year, month, day].join("-")
    } else {
      var convertDateRet = ""
    }
    if (dateReference != "") {
      let dateRef = dateReference
      let month = "" + (dateRef.getMonth() + 1)
      let day = "" + dateRef.getDate()
      let year = dateRef.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDateRef = [year, month, day].join("-")
    } else {
      var convertDateRef = ""
    }*/
    setButtonClicked(true)
    if (selectNumEntree != "") {
      var entree_fournisseur_id = selectNumEntree.value
    } else {
      var entree_fournisseur_id = 0
    }
    var etat = true
    for (let i = 0; i < arrayProduit.length; i++) {
      var qte = arrayProduit[i].qte
      if (qte <= 0) {
        var etat = false
        setButtonClicked(false)
        toast.error("⛔ Quantité doit étre supérieur à 0", {
          containerId: "A",
        })
      }
      var depot = arrayProduit[i].depot_id
      if(depot === undefined){
     
        var selection_depot = 1;
      }
    }
    if (selection_depot === 1) {
      setButtonClicked(false)
      toast.error("⛔ Selectionner un depot SVP", {
        containerId: "A",
      })
    
    }
  
    else if (arrayProduit.length == 0) {
      setButtonClicked(false)
      toast.error("⛔ Saisir au moins une article", {
        containerId: "A",
      })
    /*} else if (montantRef != totalTTC) {
      setButtonClicked(false)
      toast.error("⛔ Montant référence et total TTC doit étre égale", {
        containerId: "A",
      })*/
    } else if (selectFournisseur == "" || selectModeReglement == "" ) {
      
      setButtonClicked(false)
      toast.error("⛔ Remplir les champs obligatoire", {
        containerId: "A",
      })
    } else if (etat) {
      const res = await API.post("fournisseur/retour/add", {
        user_id: user_id,
        annee:annee,
        //i add this line
        bon_entree_id:selectNumEntree.value,
        
        num_retour: numRetour,
        date_retour: dateRetour,
        commande_fournisseur_id: entree_fournisseur_id,
        fournisseur_id: selectFournisseur.value,
        mode_reglement_id: selectModeReglement.value,
        depot_id: selectDepot.value,
        reference: reference,
        date_ref: dateReference,
        montant_ref: montantRef,
        observation: observation,
        total_remise: totalRemise,
        total_ht: totalHT,
        total_fodec: totalFodec,
        total_tva: totalTva,
        total_ttc: totalTTC,
        ligne: arrayProduit,
      }).then(res => {
        
        return res.json();
      })
      .then(data => {
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.error) {
          console.error('Erreur1:', error.response.data.error);
          toast.error(error.response.data.error, {
            containerId: "A",
          })
        } else {
          props.setSection(1)
        }
      });
      
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col md={3}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">N° bon de retour</Label>
                <Input
                  lg="3"
                  type="text"
                  className="form-control"
                  disabled
                  value={numRetour}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Date bon</Label>
                <Col md={12} className="pr-0">
                  <DatePicker
                    className="form-control ddate"
                    selected={dateRetour}
                    onChange={() => setDateRetour(e)}
                    dateFormat="dd/MM/yyyy"
                  />
                </Col>
              </div>
            </Col>
            <Col md={3}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">
                  {" N° bon d'entrée"}
                </Label>
                <Select
                  options={numEntree}
                  isSearchable={true}
                  onChange={e => getFournisseur(e)}
                  value ={selectNumEntree}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">
                  {"Date de la bon d'entrée"}
                </Label>
                <Col md={12} className="pr-0">
                  <Input
                  type="date"
                    className="form-control"
                    value={dateEntree}
                    readOnly
                    
                  />
                </Col>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <div
                  style={{ display: "flex", justifyContent: "space-arround" }}
                >
                  <Label for="basicpill-firstname-input1">Fournisseur</Label>
                  <Label style={{ color: "red", paddingLeft: "5px" }}>*</Label>
                </div>
                <Select
                  options={fournisseur}
                  isSearchable={true}
                  value={selectFournisseur}
                  onChange={e => getnumEntree(e)}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Adresse</Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Adresse"
                  disabled
                  value={adresse}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Raison sociale</Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Raison sociale"
                  disabled
                  value={raisonSocial}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2"> Code TVA </Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Code tva"
                  disabled
                  value={codeTva}
                />
              </div>
            </Col>
            <Col className="mt-3" md={3}>
              <div className="mb-3 mt-3">
                <div className="form-check form-check-inline">
                  <Input
                    type="checkbox"
                    className="form-check-Input"
                    id="horizontal-customCheck"
                    disabled
                    checked={etatFodec == 1}
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="horizontal-customCheck"
                  >
                    Fodec
                  </Label>
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Mode de règlement</Label>
                <Label style={{ color: "red", paddingLeft: "5px" }}>
                  *
                </Label>
                <Select
                  options={modeReglement}
                  isSearchable={true}
                  value={selectModeReglement}
                  onChange={e => setSelectModeReglement(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
           
            <Col md={3}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Référence fournisseur
                </Label>
                <Input
                  placeholder="Référence"
                  type="text"
                  className="form-control"
                  onChange={e => setReference(e.target.value)}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Date référence du fournisseur
                </Label>
                <Col md={12} className="pr-0">
                  <DatePicker
                    className="form-control ddate"
                    selected={dateReference}
                    onChange={e => setDateReference(e)}
                    dateFormat="dd/MM/yyyy"
                  />
                </Col>
              </div>
            </Col>
            {/*<Col md={3}>
              <Label for="basicpill-firstname-input1">
                Montant référence du fournisseur
              </Label>
              <div className="mb-3">
                <Input
                  placeholder="Montant"
                  type="number"
                  className="form-control"
                  onChange={e => setMontantRef(e.target.value)}
                />
              </div>
  </Col>*/}
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Mémo</Label>
                <Input
                  type="textarea"
                  className="form-control"
                  placeholder="Mémo"
                  onChange={e => setObservation(e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </Form>
        <CardTitle className="h4 mt-4">Ajouter Produit</CardTitle>
        <div>
          <table className="table table-bordered mt-4">
            <thead>
              <tr>
                <th style={{ textAlign: "center" }} scope="col">
                  Article
                </th>
                <th style={{ textAlign: "center" }} scope="col">
                  Désignation
                </th>
                <th style={{ textAlign: "center" }} scope="col">
                  Qte
                </th>
                <th style={{ textAlign: "center" }} scope="col">
                  P.U.H.T
                </th>
                <th style={{ textAlign: "center" }} scope="col">
                  Remise
                </th>
                <th style={{ textAlign: "center" }} scope="col">
                  Fodec
                </th>
                <th style={{ textAlign: "center" }} scope="col">
                  Montant HT
                </th>
                <th style={{ textAlign: "center" }} scope="col">
                  TVA
                </th>
                <th style={{ textAlign: "center" }} scope="col">
                  Montant TTC
                </th>
                <th style={{ textAlign: "center" }} scope="col">
                 Depot
                </th>
                {selectNumEntree == "" ? (
                  <th style={{ textAlign: "center" }} scope="col">
                    <i
                      style={{
                        color: "#a0ceb8",
                        cursor: "pointer",
                      }}
                      className="fas fa-plus"
                      onClick={addLigne}
                    />
                  </th>
                ) : (
                  <th style={{ textAlign: "center" }} scope="col">
                    <i
                      style={{
                        color: "#a0ceb8",
                        cursor: "pointer",
                      }}
                      className="fas fa-redo"
                      onClick={refrech}
                    />
                  </th>
                )}
              </tr>
            </thead>
            {arrayProduit.map((el, index) => (
              <tbody key={index}>
                <tr>
                  <td style={{ textAlign: "center", width: "13%" }}>
                    <Select
                      options={produits}
                      isSearchable={true}
                      value={el.produit_id}
                      onChange={e => getProduits(e, index)}
                    />
                  </td>
                  <td style={{ textAlign: "center", width: "13%" }}>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="..."
                      value={el.designation}
                    />
                  </td>
                  <td style={{ textAlign: "center", width: "8%" }}>
                    <Input
                      type="number"
                      className="form-control"
                      placeholder="..."
                      value={el.qte}
                      min={0}
                      onChange={e => addQuantite(e.target.value, index)}
                    />
                  </td>
                  <td style={{ textAlign: "center", width: "8%" }}>
                    <Input
                      type="number"
                      className="form-control"
                      placeholder="..."
                      value={el.prix_ht}
                    />
                  </td>
                  <td style={{ textAlign: "center", width: "8%" }}>
                    <Input
                      type="number"
                      className="form-control"
                      placeholder="..."
                      value={el.remise}
                      onChange={e => addRemise(e.target.value, index)}
                    />
                  </td>
                  <td style={{ textAlign: "center", width: "7%" }}>
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="horizontal-customCheck"
                      checked={el.fodec == 1}
                    />
                  </td>
                  <td style={{ textAlign: "center", width: "10%" }}>
                    <Input
                      type="number"
                      className="form-control"
                      placeholder="..."
                      value={el.montant_ht}
                    />
                  </td>
                  <td style={{ textAlign: "center", width: "7%" }}>
                    <Input value={el.tva.label} disabled />
                  </td>
                  <td style={{ textAlign: "center", width: "10%" }}>
                    <Input
                      type="number"
                      className="form-control"
                      placeholder="..."
                      value={el.montant_ttc}
                    />
                  </td>
                  <td style={{ textAlign: "center", width: "18%" }}>
                  <Select
                        options={el.depots}
                        isSearchable={true}
                        onChange={e => select_articleDepot(e, index)}
                      //onChange={e => setSelectDepot(e)}
                      value={el.product_selected_depot}
                      /></td>
                  <td style={{ textAlign: "center", width: "8%" }}>
                    <i
                      style={{ color: "#cc0000" }}
                      className="mdi mdi-delete font-size-18"
                      id="deletetooltip"
                      onClick={() => toggle(index)}
                    />
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
        <Card>
          <CardBody>
            <CardTitle className="mb-3">Total </CardTitle>
            <div className="table-responsive">
              <Table className="table mb-0">
                <tbody>
                  <tr>
                    <td>Total des remises :</td>
                    <td>{totalRemise}</td>
                  </tr>
                  <tr>
                    <td>Total HT :</td>
                    <td>{totalHT}</td>
                  </tr>
                  <tr>
                    <td>Total fodec :</td>
                    <td>{totalFodec}</td>
                  </tr>
                  <tr>
                    <td>Total TVA :</td>
                    <td>{totalTva}</td>
                  </tr>
                  <tr>
                    <td>Avance/Impot :</td>
                    <td>{""}</td>
                  </tr>
                  <tr>
                    <td>Total TTC : </td>
                    <td>{totalTTC}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={props.back}
              >
                Annuler
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button type="submit" className="btn btn-primary " disabled={buttonClicked} onClick={save}>
                CONFIRMER
              </button>
            </div>
          </Col>
        </div>
      </Row>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle} tag="h4">
          Suppression ligne{" "}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  onClick={toggleDelete}
                  color="info"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default AddBonRetourFournisseur
AddBonRetourFournisseur.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}