import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  Label
} from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"

import Toggle from 'react-toggle';
import "react-toggle/style.css";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import API from "../../api"
import defaultImage from '../../assets/images/loading-opaque.gif';


const UserProfile = props => {
  const dispatch = useDispatch();

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }));

  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [role, setRole] = useState("");
  const [photo, setPhoto] = useState(null)
  const [ImageChanged, setImageChanged] = useState(false)

  const [password, setPassword] = useState('');
  const [passwordActuel, setPasswordActuel] = useState('');


  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatchError, setPasswordMatchError] = useState('');
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);



  const [isPasswordEnabled, setIsPasswordEnabled] = useState(false);








  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName);
        setemail(obj.email);
        setidx(obj.uid);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.username);
        setemail(obj.email);
        setidx(obj.uid);
      }
      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success]);

  const userAuth = JSON.parse(localStorage.getItem('userAuth'));
  const idUser=(userAuth.user.id)
  
  useEffect(async () => {
    
    const res = await API.post("user/get_by_id", {
      id: idUser,

    }).then(res => {
    
      setname(res.data.Users.name)
      setemail(res.data.Users.email)
      setRole(res.data.Users.role)
      setPhoto(res.data.Users.photo)




    })

  }, []);
  function handleValidSubmit(event, values) {
    dispatch(editProfile(values));
  }

  const handleImageUpload = (event) => {
    setImageChanged(true)
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setPhoto(reader.result);
    };

    reader.readAsDataURL(file);
  };
  useEffect(async ()=>{
  })


  const confirmer = async () => {
    try {
      if (isPasswordEnabled === false) {
        const res = await API.post("user/edit", {
          id: idUser,
          name: name,
          email: email,
          role_id: role.value,
          photo: photo,
          ImageChanged: ImageChanged
        });
  
        var userAuth = JSON.parse(localStorage.getItem('userAuth'));
        var photoWithoutPrefix = photo.replace(/^data:image\/(png|jpeg);base64,/, '');
        userAuth.user.photo64 = photoWithoutPrefix;
        localStorage.setItem("userAuth", JSON.stringify(userAuth));
        window.location.reload();
        
      } else {
        if (passwordActuel && password && confirmPassword) {
          if (password === confirmPassword) {
            const res = await API.post("user/edit", {
              id: idx,
              name: name,
              email: email,
              password: password,
              confirmPassword: confirmPassword,
              passwordActuel: passwordActuel,
              role_id: role.value,
              photo: photo,
              ImageChanged: ImageChanged
            });
  
            var userAuth = JSON.parse(localStorage.getItem('userAuth'));
            var photoWithoutPrefix = photo.replace(/^data:image\/(png|jpeg);base64,/, '');
            userAuth.user.photo64 = photoWithoutPrefix;
            localStorage.setItem("userAuth", JSON.stringify(userAuth));
            window.location.reload();
            
          } else {
            toast.error("Les nouveaux mots de passe ne correspondent pas", {
              containerId: "A",
            });
          }
        } else {
          toast.error("Veuillez remplir tous les champs de mot de passe", {
            containerId: "A",
          });
        }
      }
   } catch (error) {
      toast.error(error.response?.data?.message || "Une erreur est survenue", {
        containerId: "A",
      });
    }
  };
  

  useEffect(() => {
    if (confirmPassword != "" && passwordActuel != "") {
      if (password !== confirmPassword) {
        setPasswordMatchError('Les mots de passe ne correspondent pas');
        setPasswordsMatch(false);
      } else if (password === confirmPassword) {
        setPasswordMatchError('Les mots de passe correspondent');
        setPasswordsMatch(true);

      }
    }
    else {
      setPasswordMatchError('');

    }
  }, [password, confirmPassword, passwordActuel]);
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordTouched(true);
  };
  const handleToggleChange = () => {
    setIsPasswordEnabled(!isPasswordEnabled);
  };



  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | Cresus</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          {/*<Breadcrumb title="CRES SOFT" breadcrumbItem="Profile" />*/}

          <Row>
            <Col lg={12}>
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <Media>
                    <div className="ms-2" style={{ width: "20rem" }}>
                      <img
                        id="selectedImage1"
                        src={photo ? (ImageChanged === false ? `data:image/png;base64, ${photo}` : photo) : defaultImage}
                        className="avatar-s rounded-circle img-thumbnail"
                      />
                      <div className="col-md-12 btn btn-danger btn-rounded " >
                        <label className="form-label text-white m-1" htmlFor="customFile1">Modifier photo de profil</label>
                        <input type="file" name="logo_image" className="form-control d-none" id="customFile1" onChange={handleImageUpload} />
                      </div>
                    </div>
                    <Media body className="align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{role.label}</p>
                      </div>

                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Changer vos informations</h4>
          <Card>
            <CardBody>
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                <div className="form-group row">
                  <div className="col-md-6">
                    <AvField
                      name="username"
                      label="Nom"
                      value={name}
                      className="form-control"
                      placeholder="Entrez votre nom"
                      type="text"
                      onChange={(e) => setname(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <AvField
                      name="email"
                      label="E-mail"
                      value={email}
                      className="form-control"
                      placeholder="Entrez votre e-mail"
                      type="email"
                      onChange={(e) => setemail(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div style={{marginTop:'2rem'}}>
                <label>
                  <Toggle
                    checked={isPasswordEnabled}
                    onChange={handleToggleChange}
                  />
                  <span> Modifier du mot de passe</span>
                </label>
                </div>
                


                <div className="form-group row">
                  <div className="col-md-6">
                    <Label for="basicpill-firstname-input1">Mot de passe actuel</Label>
                    <AvField
                      name="currentPassword"
                      type="password"
                      placeholder="Insérer un mot de passe actuel"
                      errorMessage="* Mot de passe actuel obligatoire"
                      //validate={{ required: { value: true } }}
                      onChange={(e) => setPasswordActuel(e.target.value)}
                      disabled={!isPasswordEnabled}

                    />
                  </div>

                  <div className="col-md-6">
                    <Label for="basicpill-firstname-input1">Nouveau mot de passe</Label>
                    <AvField
                      name="newPassword"
                      type="password"
                      placeholder="Insérer un nouveau mot de passe"
                      errorMessage="* Nouveau mot de passe obligatoire"
                      //validate={{ required: { value: true } }}
                      onChange={handlePasswordChange}
                      disabled={!isPasswordEnabled}

                    />
                  </div>
                  <div className="col-md-6"></div>


                  <div className="col-md-6">
                    <Label for="basicpill-firstname-input1">Confirmer mot de passe</Label>
                    <AvField
                      name="confirmPassword"
                      type="password"
                      placeholder="Confirmez le nouveau mot de passe"
                      errorMessage="* Mot de passe obligatoire"
                      //validate={{ required: { value: true } }}
                      onChange={handleConfirmPasswordChange}
                      disabled={!isPasswordEnabled}

                    />
                    {passwordMatchError && passwordsMatch === false && <span style={{ color: 'red' }}>{passwordMatchError}</span>}
                    {passwordMatchError && passwordsMatch === true && <span style={{ color: 'green' }}>{passwordMatchError}</span>}
                  </div>
                </div>




                <div className="text-center mt-4">
                  <Button type="submit" color="danger" onClick={confirmer}>
                    {"Enregistrer"}

                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </div>

      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
