
import PropTypes from "prop-types"
import React,{useEffect,useState} from "react"
import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"
// Import all middleware
import Authmiddleware from "./routes/route"
// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
// Import scss
import "./assets/scss/theme.scss"
import fakeBackend from "./helpers/AuthType/fakeBackend"
import API from "./api"
import { Helmet } from "react-helmet"
// import { useIdleTimer } from "react-idle-timer"
// Activating fake backend
fakeBackend()

const App = props => {
  // const handleOnIdle = event => {
  //   if (event === undefined) {
  //     localStorage.clear()
  //   }
  // }
  // const handleOnActive = event => {}
  // const handleOnAction = event => {}
  // const { getRemainingTime, getLastActiveTime } = useIdleTimer({
  //   timeout: 100 * 30 * 10,
  //   onIdle: handleOnIdle,
  //   onActive: handleOnActive,
  //   onAction: handleOnAction,
  //   debounce: 500,
  // })
  const [menuColor, setMenuColor] = useState()
  const [favicon, setFavicon] = useState(null)

  useEffect(async () => {
    const res = await API.get("societe/get_infos").then(res => {
      localStorage.setItem("color_menu",res.data.societe.couleur_menu)
       setMenuColor(res.data.societe.couleur_menu)
    setMenuColor(localStorage.getItem("color_menu"))})
    const resIcone = await API.get("societe/get_icon").then(resIcone => {

    localStorage.setItem("favicon",resIcone.data.icone)
    setFavicon(resIcone.data.icone)
    setFavicon(localStorage.getItem("favicon"))

  })

    
}, []);

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()
 
  return (
    <div>
      <Helmet>
      <link rel="icon" href={favicon} />

      </Helmet>
     
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}
            {authProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    </div>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
