/* eslint-disable react/no-unknown-property */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import { Row, Col, Spinner, Modal, ModalBody, Button, Label } from "reactstrap"
import { withRouter } from "react-router-dom"
import DataTable from "react-data-table-component"
import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select"
import { Link } from "react-router-dom"

const DataTableUser = props => {
  const [loading, setLoading] = useState(false)
  const [modalUpd, setModalUpd] = useState(false)
  const [modalDel, setModalDel] = useState(false)
  const [id, setId] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [name, setName] = useState("")
  const [roles, setRoles] = useState([])
  const [selectRole, setSelectRole] = useState("")
  // pagination
  const [nbrTotlEl, setNbrTotlEl] = useState(0)
  const [nbrEltperPage, setNbrEltperPage] = useState(5)
  const [filterElement, setFilterElement] = useState("")
  const [mycurrentPage, setMycurrentPage] = useState(1)
  const [resetFirstPage, setResetFirstPage] = useState(false)
  const [data, setData] = useState([])

  const columns = [
    {
      name: "id",
      selector: "id",
      sortable: false,
      center: true,
    },
    {
      name: "Rôle",
      selector:"name",
      center: true,
      reorder: true,
    },
    
    {
      name: "Modification",
      // eslint-disable-next-line react/display-name
      cell: row => (
      

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to={"/ModifRole?id=" + row.id}>
          <i
            style={{ color: "cornflowerblue", cursor: "pointer" }}
            className="fas fa-edit"
            onClick={() => toggleUpd(row)}
          ></i>
          </Link>
        </div>
      ),
      center: true,
      reorder: true,
    },

    {
      name: "Suppression",
      // eslint-disable-next-line react/display-name
      cell: row =>
        row.perm_delete === true ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#ED6464", cursor: "pointer" }}
              onClick={() => toggleDel(row.id)}
              className="fas fa-trash-alt"
            ></i>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "rgb(167, 163, 163)", cursor: "pointer" }}
              className="fas fa-trash-alt"
              title="Ce role est déjà affecté à un utlisateur"
            ></i>
          </div>
        ),
      center: true,
      reorder: true,
    },

  ]

  const customStyles = {
    rows: {
      style: {
        minHeight: "52px", // override the row height
      },
    },
    headCells: {
      style: {
        fontSize: "13px",
        backgroundColor: "#eff2f7",
      },
    },
    cells: {
      style: {
        fontSize: "13px",
      },
    },
  }

  useEffect(async () => {
  
    const res = await API.post("role/list", {
      filterElement: filterElement,
      nbrElParPage: 5,
      page: 1,
    }).then(res => {
      setData(res.data.Data[0].Role)
      setNbrTotlEl(res.data.Data[0].total)
    })
    setLoading(true)
  }, [])

  const newElement = async (page, totalRows) => {
    setMycurrentPage(page)
    const res = await API.post("role/list", {
      filterElement: filterElement,
      nbrElParPage: nbrEltperPage,
      page: page,
    }).then(res => {
      setData(res.data.Data[0].Role)
      setNbrTotlEl(res.data.Data[0].total)
    })
  }

  const perPage = async (currentRowsPerPage, currentPage) => {
    setMycurrentPage(currentPage)
    const res = await API.post("role/list", {
      filterElement: filterElement,
      nbrElParPage: currentRowsPerPage,
      page: currentPage,
    }).then(res => {
      setData(res.data.Data[0].Role)
      setNbrTotlEl(res.data.Data[0].total)
    })
  }

  const filterSearch = async e => {
    setFilterElement(e.target.value)
    const res = await API.post("role/list", {
      filterElement: e.target.value,
      nbrElParPage: nbrEltperPage,
      page: mycurrentPage,
    }).then(res => {
      setData(res.data.Data[0].Role)
      setNbrTotlEl(res.data.Data[0].total)
    })
  }

  const toggleUpd = el => {
    setModalUpd(!modalUpd)
    setId(el.id)
    setName(el.name)
    setEmail(el.email)
    setPassword(el.password)
   // setSelectRole(el.role)
    setName(el.name)
  }

  const toggleDel = id => {
    setModalDel(!modalDel)
    setId(id)
  }

  

  const toggleSupp = async () => {
    const res = await API.post("role/delete", {
      id: id,
    }).then(res => {
      setModalDel(!modalDel)
      const resD = API.post("role/list", {
        nbrElParPage: nbrEltperPage,
        page: mycurrentPage,
      }).then(resD => {
        setData(resD.data.Data[0].Role)
        setNbrTotlEl(resD.data.Data[0].total)
      })
    })
  }

  return (
    <React.Fragment>
      {loading ? (
        <Row>
          <Col xs="12">
            <div className="mb-2 row">
              <div className="col-md-4">
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <label htmlFor="search-bar-0" className="search-label">
                      <input
                        id="search-bar-0"
                        type="text"
                        aria-labelledby="search-bar-0-label"
                        className="form-control "
                        placeholder="Search"
                        // onChange={(currentRowsPerPage, currentPage) =>
                        //   filterSearch(currentRowsPerPage, currentPage)
                        // }
                      />
                    </label>
                    <i className="bx bx-search-alt search-icon" />
                  </div>
                </div>
              </div>
            </div>

            <DataTable
              columns={columns}
              data={data}
              customStyles={customStyles}
              highlightOnHover={false}
              pagination={true}
              paginationServer={true}
              onChangePage={(page, totalRows) => newElement(page, totalRows)}
              paginationTotalRows={nbrTotlEl}
              paginationPerPage={nbrEltperPage}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
              onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                perPage(currentRowsPerPage, currentPage)
              }
              paginationResetDefaultPage={resetFirstPage}
              paginationComponentOptions={{
                rowsPerPageText: "Elements par page:",
                rangeSeparatorText: "de",
                noRowsPerPage: false,
              }}
            />
          </Col>
        </Row>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            {" "}
            Chargement en cours ...{" "}
          </h4>
        </div>
      )}
     
      
      {/* <Delete> */}
      <Modal isOpen={modalDel} toggle={toggleDel} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggleDel}
        >
          {props.t("Suppression d'un utilisateur")}
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p>
                {props.t(
                  "Êtes-Vous sûr de vouloir supprimer cet utilisateur ?"
                )}
              </p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={toggleSupp}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  {props.t("Oui")}
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggleDel}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  {props.t("Non")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableUser))
DataTableUser.propTypes = {
  data: PropTypes.array,
  t: PropTypes.any,
}
