import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import API from "../../api"
//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

// import logo from "../../assets/images/logo.svg";
// import logoDark from "../../assets/images/logo-dark.png";
import logoLightPng from "../../assets/images/LOGOerp2.png";
import logoLightSvg from "../../assets/images/login Images/logo.png";



const Sidebar = props => {
  const [menuColor, setMenuColor] = useState()

  const [LogoImg, setLogoImg] = useState(null)
  
  
  const userAuth = JSON.parse(localStorage.getItem('userAuth'));

  useEffect(async () => {
    setMenuColor(localStorage.getItem("color_menu"))

    let LogoImg = localStorage.getItem("LogoImg");


    if (LogoImg && LogoImg.match(/^data:image\/[^;]+;base64,/)) {
      LogoImg = LogoImg.replace(/^data:image\/[^;]+;base64,/, '');
    }
   setLogoImg(LogoImg)
  }, []);
  useEffect(async () => {
    setMenuColor(localStorage.getItem("color_menu"))

    let LogoImg = localStorage.getItem("LogoImg");

    

    if (LogoImg && LogoImg.match(/^data:image\/[^;]+;base64,/)) {
      LogoImg = LogoImg.replace(/^data:image\/[^;]+;base64,/, '');
    }

   setLogoImg(LogoImg)


   const res = await API.get("societe/get_infos").then(res => {
    localStorage.setItem("color_menu",res.data.societe.couleur_menu)
     setMenuColor(res.data.societe.couleur_menu)
  setMenuColor(localStorage.getItem("color_menu"))
  localStorage.setItem("LogoImg",res.data.logo)
 setLogoImg( res.data.logo)
})
  }, []);
  return (
    <React.Fragment>
      <link rel="icon" type="image/x-icon" href={logoLightPng} />
      <div className="vertical-menu" style={{ backgroundColor: menuColor }}  >
        <div className="navbar-brand-box" style={{ backgroundColor: menuColor }} >
          {/* <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="17" />
            </span>
          </Link> */}

          <Link to="/dashboard" className="logo logo-light" style={{ backgroundColor: menuColor }} >
            <span className="logo-sm" style={{ backgroundColor: menuColor }} >
              <img src={`data:image/png;base64, ${LogoImg}`} alt="" height="22" />
            </span>
            <span className="logo-lg" style={{ backgroundColor: menuColor }} >
              <img src={`data:image/png;base64, ${LogoImg}`} alt="" height="50" width="auto" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
