import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import { Row, Col, Spinner, Modal, ModalBody, Button, Label, Input } from "reactstrap"
import { withRouter } from "react-router"
//lang
import { withTranslation } from "react-i18next"
import DataTable from "react-data-table-component"
import { Search } from "react-bootstrap-table2-toolkit"
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"



const AddInventaire = props => {
  const [loading, setLoading] = useState(false)
  const [depot, setDepot] = useState([])
  const [selectDepot, setSelectDepot] = useState("")
  const [selectedDepot, setSelectedDepot] = useState("")

  const [depot_id, setDepot_id] = useState(0)
  const [code, setCode] = useState("")
  const [designation, setDesignation] = useState("")
  const [quantite, setQuantite] = useState("")
  const [quantite_max, setQuantiteMaximal] = useState("")
  const [quantite_min, setQuantiteMinimal] = useState("")
  const [nbrTotlEl, setNbrTotlEl] = useState(0)
  const [nbrEltperPage, setNbrEltperPage] = useState(5)
  const [filterElement, setFilterElement] = useState("")
  const [mycurrentPage, setMycurrentPage] = useState(1)
  const [resetFirstPage, setResetFirstPage] = useState(false)
  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  const [data, setData] = useState([])
  const [userdata, setUserdata] = useState([])
  const [arrayProduit, setArrayProduit] = useState([])
  //date
  const [dateDebut, setDateDebut] = useState(new Date())
  const [dateFin, setDateFin] = useState("")

  const [btnDisabled, setBtnDisabled] = useState(true)



  //verification
  const [depotError, setDepotError] = useState("");
  const [dateDebutError, setDateDebutError] = useState("");
  const [dateFinError, setDateFinError] = useState("");
  const [perm, setPerm] = useState({
    add: 0,
    delete: 0,
    edit: 0,
    imp: 0,
    view: 0
  });
  const maxHeight = 150;
  const [tousArt, setTousArt] = useState(false)
  const SelectOptions = [
    { value: 0, label: "select..." }
  ];

  //verificationnnnnnnn


  useEffect(() => {
    // Validation pour le dépôt
    if (!selectDepot) {
      setDepotError("Veuillez sélectionner un dépôt.");
    } else {
      setDepotError("");
    }
  }, [selectDepot]);

  useEffect(() => {
    // Validation pour la date de début
    if (!dateDebut) {
      setDateDebutError("Veuillez sélectionner une date de début.");
    } else {
      setDateDebutError("");
    }
  }, [dateDebut]);

  useEffect(() => {
    if (!dateFin) {
      setDateFinError("Veuillez sélectionner une date de fin.");
    } else {
      setDateFinError("");
    }
  }, [dateFin]);

  const handleDepotChange = async (selectedOption) => {
    setSelectDepot(true);
    setSelectedDepot(selectedOption)
    setBtnDisabled(false);
    setTousArt(false)
  };

  const handleDateDebutChange = (e) => {
    setDateDebut(e.target.value);
  };

  const handleDateFinChange = (e) => {
    setDateFin(e.target.value);
  };
  const filterSearch = async e => {
    setFilterElement(e.target.value)
    const res = await API.post("stock/consulter_par_depot", {
      filterElement: e.target.value,
      nbrElParPage: nbrEltperPage,
      page: mycurrentPage,
    }).then(res => {
      setData(res.data.Data[0].DevisClient)
      setNbrTotlEl(res.data.Data[0].total)
    })
  }

  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('userAuth'));
    setUserdata(userAuth.user.userData)
  }, [])

  useEffect(() => {
    const typeArticleSubMenu = userdata.find(user =>
      user.sous_menus.some(subMenu => subMenu.sous_menu === "Inventaire et alimentation du stock")
    );

    if (typeArticleSubMenu) {
      const typeArticlePermission = typeArticleSubMenu.sous_menus.find(
        subMenu => subMenu.sous_menu === "Inventaire et alimentation du stock"
      ).permissions[0];
      setPerm(prevPerm => ({
        ...prevPerm,
        add: typeArticlePermission.add,
        delete: typeArticlePermission.delete,
        edit: typeArticlePermission.edit,
        imp: typeArticlePermission.imp,
        view: typeArticlePermission.view,
      }));
    }
  }, [userdata]);

  useEffect(async () => {
    const resDE = await API.get("stock/all_articles_par_depot");
    const depots = resDE.data.depots.map(depot => ({
      value: depot.id,
      label: depot.designation,
      articles: depot.articles.map(article => {
        const diffMin = article.quantite - article.quantite_min;
        const diffMax = article.quantite_max - article.quantite + 1;
        let remarque = "Tout est bien";
        if (diffMin < 1 || diffMax < 1) {
          if (diffMin < 1) {
            remarque = "Attention";
          } else {
            remarque = "Sur stock";
          }
        }
        return { ...article, nouvelle_quantite: 0, remarque };
      })
    }));
    setDepot(depots);
    setLoading(true);
  }, []);









  const handleNewQuantityChange = (depotId, articleIndex, value) => {
    setDepot(prevDepot => {
      const updatedDepot = prevDepot.map(dep => {
        if (dep.value === depotId) {
          const updatedArticles = dep.articles.map((article, index) => {
            if (index === articleIndex) {
              const nouvelle_quantite = value.trim() === "" ? 0 : parseFloat(value);
              let nouvelleRemarque;

              console.log("quantite max", article.quantite_max)
              console.log("nv qte", nouvelle_quantite)
              console.log("qte", article.quantite)
              const qte_total = nouvelle_quantite + article.quantite;
              if (article.quantite_max < qte_total) {
                nouvelleRemarque = "Sur stock"
              }
              if (qte_total <= article.quantite_min) {
                nouvelleRemarque = "Attention";
              }
              if (qte_total > article.quantite_min && article.quantite_max >= qte_total) {
                nouvelleRemarque = "Tout est bien";
              }

              return { ...article, nouvelle_quantite: nouvelle_quantite, remarque: nouvelleRemarque };
            }
            return article;
          });
          return { ...dep, articles: updatedArticles };
        }
        return dep;
      });
      setData(updatedDepot.find(dep => dep.value === depot_id)?.articles || []);
      return updatedDepot;
    });
  };


  const handleQuantityMin = (depotId, articleIndex, value) => {
    if (!tousArt) {
      console.log("depots", depot)
      setDepot(prevDepot => {
        const updatedDepot = prevDepot.map(dep => {
          if (dep.value === depotId) {
            const updatedArticles = dep.articles.map((article, index) => {
              if (index === articleIndex) {
                const quantite_min = value.trim() === "" ? 0 : parseFloat(value);
                let nouvelleRemarque;
                const qte_tot = article.nouvelle_quantite + article.quantite
                console.log("tugrdhf", qte_tot)
                console.log("min", quantite_min)
                if (article.quantite_max < qte_tot) {
                  nouvelleRemarque = "Sur stock";
                }
                if (qte_tot <= quantite_min) {
                  nouvelleRemarque = "Attention";
                }

                if (!(article.quantite_max < qte_tot) && !(qte_tot <= quantite_min)) {
                  nouvelleRemarque = "Tout est bien";
                }
                return { ...article, quantite_min: quantite_min, remarque: nouvelleRemarque };
              }
              return article;
            });
            return { ...dep, articles: updatedArticles };
          }
          return dep;
        });
        setData(updatedDepot.find(dep => dep.value === depot_id)?.articles || []);
        return updatedDepot;
      });
    }
    else {
      const newData = data.map((item, i) => {
        if (i === articleIndex) {

          let nouvelleRemarque
          if (item.quantite_totale > item.quantite_max) {
            nouvelleRemarque = "Sur Stock";
          }
          if (item.quantite_totale <= value) {
            nouvelleRemarque = "Attention";
          }
          if (!(item.quantite_totale > item.quantite_max) && !(item.quantite_totale <= value)) {
            nouvelleRemarque = "Tout est bien";
          }


          return Object.assign({}, item, {
            quantite_min: value,
            remarque: nouvelleRemarque
          });
        }
        return item;
      });
      setData(newData);
    }
  };


  const handleQuantityMax = (depotId, articleIndex, value) => {
    if (!tousArt) {


      console.log("depots", depot)
      setDepot(prevDepot => {
        const updatedDepot = prevDepot.map(dep => {
          if (dep.value === depotId) {
            const updatedArticles = dep.articles.map((article, index) => {
              if (index === articleIndex) {
                const quantite_max = value.trim() === "" ? 0 : parseFloat(value);
                let nouvelleRemarque;
                const qte_tot = article.nouvelle_quantite + article.quantite

                if (article.quantite_min >= qte_tot) {
                  nouvelleRemarque = "Attention";
                }

                if (qte_tot > quantite_max) {
                  nouvelleRemarque = "Sur stock";
                }
                if (!(article.quantite_min >= qte_tot) && !(qte_tot > quantite_max)) {
                  nouvelleRemarque = "Tout est bien";
                }

                return { ...article, quantite_max: quantite_max, remarque: nouvelleRemarque };
              }
              return article;
            });
            return { ...dep, articles: updatedArticles };
          }
          return dep;
        });
        setData(updatedDepot.find(dep => dep.value === depot_id)?.articles || []);
        return updatedDepot;
      });
    }
    else {



      const newData = data.map((item, i) => {
        if (i === articleIndex) {

          let nouvelleRemarque
          if (item.quantite_totale > value) {
            nouvelleRemarque = "Sur Stock";
          }
          if (item.quantite_totale <= item.quantite_min) {
            nouvelleRemarque = "Attention";
          }
          if (!(item.quantite_totale > value) && !(item.quantite_totale <= item.quantite_min)) {
            nouvelleRemarque = "Tout est bien";
          }


          return Object.assign({}, item, {
            quantite_max: value,
            remarque: nouvelleRemarque
          });
        }
        return item;
      });
      setData(newData);



    }
  };




  const [tousLesZero, setTousLesZero] = useState(true)
  useEffect(() => {
    verifier_saisie();
  }, [depot, dateDebut, dateFin]);




  const verifier_saisie = () => {
    let verif_zero = 0;
    for (const d of depot) {
      for (const article of d.articles) {
        if (article.nouvelle_quantite !== 0 && ((dateDebut !== "" && dateDebut !== null))) {
          verif_zero = 1;

        }

      }

    }
    if (verif_zero > 0) {
      setTousLesZero(false);

    }
    else {
      setTousLesZero(true);

    }



  }




  const getStockParDepot = selectedDepot => {
    const depotArticles = depot.find(dep => dep.value === selectedDepot.value)?.articles;

    setData(depotArticles || []);
    setDepot_id(selectedDepot.value);

  };


  const customStyles = {
    rows: {
      style: {
        minHeight: "52px",
      },
    },
    headCells: {
      style: {
        fontSize: "13px",
        backgroundColor: "#eff2f7",
      },
    },
    cells: {
      style: {
        fontSize: "13px",
      },
    },
  }

  /* useEffect(async () => {
     const res = await API.post("stock/consulter_par_depot", {
       nbrElParPage: 5,
       page: 1,
     }).then(res => {
       setData(res.data)
       console.log("table", data)
       //setNbrTotlEl(res.data.Article[0].total)
     })
     setLoading(true)
   }, [])
 
   /*const newElement = async (page, totalRows) => {
     setMycurrentPage(page)
     const res = await API.post("article/list", {
       nbrElParPage: nbrEltperPage,
       page: page,
     }).then(res => {
       setData(res.data.Article[0].Article)
       setNbrTotlEl(res.data.Article[0].total)
     })
   }*/

  /*const perPage = async (currentRowsPerPage, currentPage) => {
    setMycurrentPage(currentPage)
    const res = await API.post("article/list", {
      nbrElParPage: currentRowsPerPage,
      page: currentPage,
    }).then(res => {
      setData(res.data.Article[0].Article)
      setNbrTotlEl(res.data.Article[0].total)
    })
  }*/

  const toggle = id => {
    setModal(!modal)
    setId(id)
  }
  const [isDisabled, setIsDisabled] = useState(true);

  // Fonction de validation pour le champ de sélection du dépôt
  const validateDepot = () => {
    if (!depotSelected) {
      return "Le champ Dépôt est requis.";
    }
    return "";
  };

  // Fonction de validation pour le champ de date de début
  const validateDateDebut = () => {
    if (!dateDebutSelected) {
      return "Le champ Date Début est requis.";
    }
    return "";
  };

  // Fonction de validation pour le champ de date de fin
  const validateDateFin = () => {
    if (!dateFinSelected) {
      return "Le champ Date Fin est requis.";
    }
    return "";
  };


  const save = async () => {

    if (!depot_id && !tousArt) {
      return;
    }
    let isEmpty = true;
    let erreur = false;
    if (!tousArt) {
      console.log("tousArt", tousArt)
      depot.forEach(dep => {
        dep.articles.forEach(article => {
          if (article.nouvelle_quantite !== "") {
            isEmpty = false;
          }
          if (article.quantite_max <= article.quantite_min) {
            erreur = true;
          }
        });
      });
    }

    else {
      console.log("data", data)
      data.forEach(item => {

        if (item.quantite_max < item.quantite_min) {
          erreur = true;
        }
      })
    }



    /*if (isEmpty) {
      return;
    }*/
    const userA = JSON.parse(localStorage.getItem('userAuth'))
    const userID = userA.user.id;
    if (!erreur) {
      if (!tousArt) {
        const res = await API.post("inventaire/add", {
          depot: depot,
          date: dateDebut,
          user_id: userID,
          tousArt: tousArt
        });
      }
      if (tousArt) {
        const res = await API.post("inventaire/add", {
          data: data,
          date: dateDebut,
          user_id: userID,
          tousArt: tousArt
        });
        console.log('ress', res)
      }

      window.location.reload()
    }
    else {
      toast.error("⛔La quantité maximale doit être supérieure à la quantité minimale", {
        containerId: "A",
      })

    }
  };


  const toggleSupp = async (id) => {
    const res = await API.post("article/delete", {
      id: id,
    }).then(res => {
      setModal(!modal)
      const resD = API.post("article/list", {
        nbrElParPage: nbrEltperPage,
        page: mycurrentPage,
      }).then(resD => {
        setData(resD.data.Article[0].Article)
        setNbrTotlEl(resD.data.Article[0].total)
      })
    })
  }

  const getRemarqueTextColorClass = (remarque) => {
    return remarque === "Tout est bien" ? "text-success" : "text-danger";
  };

  const CheckboxChange = async (e) => {
    setBtnDisabled(false);

    if (e.target.checked === true) {

      setTousArt(true)

      const res = await API.get("stock/all_articles")
      const data = res.data.map(article => ({
        ...article,
        remarque: (article.quantite_totale - article.quantite_min) <= 0 ? "Attention" : "Tout est bien"
      }))
      setData(data)


    }
    if (e.target.checked === false) {
      setData([])
      setTousArt(false)

    }
  }
  const { SearchBar } = Search

  return (
    <React.Fragment>

      <div>

        {loading ? (

          <Row>

            <Col md={4} sm={6}>
              <div className="mb-3">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-arround",
                  }}
                >
                  <Label for="basicpill-firstname-input1">
                    Depot
                  </Label>
                  <Label style={{ color: "red", paddingLeft: "5px" }}>
                    *
                  </Label>
                </div>
                <Select
                  required
                  options={depot}
                  value={tousArt ? SelectOptions : selectedDepot}

                  isSearchable={true}
                  classNamePrefix="select2-selection"
                  onChange={(selectedOption) => {
                    handleDepotChange(selectedOption);
                    getStockParDepot(selectedOption);
                  }}

                  styles={{
                    menu: base => ({ ...base, maxHeight, overflowY: 'auto' })
                  }}

                />
                {depotError && <div className="error-message" style={{ color: "red" }}>{depotError}</div>}
              </div>
            </Col>



            <Col md={4} sm={6}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Date </Label>
                <Col md={12} className="pr-0">
                  <DatePicker
                    className="form-control ddate"
                    selected={dateDebut}
                    onChange={e => setDateDebut(e)}
                    dateFormat="dd/MM/yyyy"


                  />
                </Col>
              </div>
            </Col>
            <Col md={4} sm={6}></Col>

            <Col md={4} sm={6} className="mb-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="exampleCheckbox"
                  checked={tousArt}
                  onChange={e => CheckboxChange(e)}
                  style={{ width: '15px', height: '15px' }}
                />
                <label className="form-check-label ms-2" htmlFor="exampleCheckbox" style={{ fontSize: '15px' }}>
                  Tous les articles
                </label>
              </div>
            </Col>





            <Col sm="12" mt="12">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative">
                  <input
                    id="search-bar-0"
                    type="text"
                    aria-labelledby="search-bar-0-label"
                    className="form-control "
                    placeholder="Search"
                    onChange={(currentRowsPerPage, currentPage) =>
                      filterSearch(currentRowsPerPage, currentPage)
                    }
                  />
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>
            </Col>

            <Col xs="12">
              <table className="table table-bordered mt-4">
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }} scope="col">
                      code
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Désignation
                    </th>
                    {!tousArt && <th style={{ textAlign: "center" }} scope="col">
                      Quantité depot
                    </th>}
                    <th style={{ textAlign: "center" }} scope="col">
                      Quantité maximal
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Quantité Minimal
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Quantité Totale de l article
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Remarque
                    </th>
                    {!tousArt &&
                      <th style={{ textAlign: "center" }} scope="col">
                        Nouvelle Quantité
                      </th>}


                  </tr>
                </thead>
                {data.map((el, index) => (
                  <tbody key={index}>
                    <tr>
                      <td style={{ textAlign: "center", width: "10%" }}>
                        {el.code}
                      </td>
                      <td style={{ textAlign: "center", width: "10%" }}>
                        {el.designation}
                      </td>
                      {!tousArt && <td style={{ textAlign: "center", width: "10%" }}>
                        {el.quantite}
                      </td>}
                      <td style={{ textAlign: "center", width: "8%" }}>
                        <Input
                          required
                          type="number"
                          className="form-control"
                          placeholder="Nouvelle quantité"
                          value={el.quantite_max}
                          onChange={(e) => handleQuantityMax(depot_id, index, e.target.value)}

                        />

                      </td>
                      <td style={{ textAlign: "center", width: "7%" }}>
                        <Input
                          required
                          type="number"
                          className="form-control"
                          placeholder="Nouvelle quantité"
                          value={el.quantite_min}
                          onChange={(e) => handleQuantityMin(depot_id, index, e.target.value)}

                        />


                      </td>
                      <td style={{ textAlign: "center", width: "10%" }}>
                        {el.quantite_totale}
                      </td>
                      <td style={{ textAlign: "center", width: "10%" }} className={getRemarqueTextColorClass(el.remarque)}>
                        {el.remarque}
                      </td>
                      {!tousArt && <td style={{ textAlign: "center", width: "10%" }}>
                        <Input
                          required
                          type="number"
                          className="form-control"
                          placeholder="Nouvelle quantité"
                          value={el.nouvelle_quantite === 0 ? '' : el.nouvelle_quantite}
                          onChange={(e) => handleNewQuantityChange(depot_id, index, e.target.value)}
                        />
                      </td>}
                    </tr>
                  </tbody>
                ))}

              </table>
            </Col>
            <Col md={4}  > </Col>
            <Col md={1}  ></Col>
            <Col md={4}  >
              {perm.edit === 1 &&
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
                >
                  <button
                    type="submit"
                    className="btn btn-primary "
                    onClick={save}
                    disabled={btnDisabled}
                  >
                    Terminer 
                  </button>
                </div>
              }
            </Col>
            <ToastContainer
              transition={Slide}
              enableMultiContainer
              containerId={"A"}
              position={toast.POSITION.TOP_RIGHT}
              autoClose={2500}
            />



          </Row>


        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>


    </React.Fragment>
  )
}
export default withRouter(withTranslation()(AddInventaire))
AddInventaire.propTypes = {
  personnel: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
