import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// gestion utilisateur //
import User from "pages/GestionUtilisateur/User"

// paramétrage
// *** Gouvernerat *** //
import Gouvernerat from "pages/Parametrages/Gouvernerat/Gouvernerat"
// *** delegation *** //
import Delegation from "pages/Parametrages/Delegation/Delegation"
// *** municipalite *** //
import Municipalite from "pages/Parametrages/Municipalite/Municipalite"
// *** type article *** //
import TypeArticle from "pages/Parametrages/TypeArticle/TypeArt"
// *** groupe article *** //
import GroupeArticle from "pages/Parametrages/GroupeArticle/GpArt"
// *** famille article *** //
import FamilleArticle from "pages/Parametrages/FamilleArticle/FamArt"
// *** marque *** //
import Marque from "pages/Parametrages/Marque/Marque"
// *** Unite *** //
import Unite from "pages/Parametrages/Unite/Unite"
// *** personnel *** //
import Personnel from "pages/Parametrages/Personnel/Personnel"
// *** FamilleClient *** //
import FamilleClient from "pages/Parametrages/FamilleClient/FamilleClient"
// *** FamilleFournisseur *** //
import FamilleFournisseur from "pages/Parametrages/FamilleFournisseur/FamilleFournisseur"
// *** ModeRelement *** //
import ModeReglement from "pages/Parametrages/ModeReglement/ModeReglement"
// *** FormeJuridique *** //
import FormeJuridique from "pages/Parametrages/FormeJuridique/FormeJuridique"
// *** Depot *** //
import Depot from "pages/Parametrages/Depot/Depot"
//*** TVA ***/
import TVA from "pages/Parametrages/TVA/TVA"
// *** Departement *** //
import Departement from "pages/Parametrages/Departement/Departement"
// *** Emplacement *** //
import Emplacement from "pages/Parametrages/Emplacement/Emplacement"
// *** TypeRemise *** //
import TypeRemise from "pages/Parametrages/TypeRemise/TypeRemise"
// *** RemiseQuantite *** //
import RemiseQuantite from "pages/Parametrages/RemiseQuantite/RemiseQuantite"
// *** MatriculesBancaires *** //
import MatriculesBancaires from "pages/Parametrages/MatriculesBancaires/MatriculesBancaires"
// *** Compteur *** //
import Compteur from "pages/Parametrages/Compteur/Compteur"

// Fournisseur
import Fournisseur from "pages/Fournisseur/NewFournisseur/Fournisseur"
import DetailFournisseur from "pages/Fournisseur/NewFournisseur/DetailFournisseur"
import ModifFournisseur from "pages/Fournisseur/NewFournisseur/ModifFournisseur"

// client //
import Client from "pages/Client/NewClient/Client"
import DetailClient from "pages/Client/NewClient/DetailClient"
import EditClient from "pages/Client/NewClient/EditClient"

// CommandeFournisseur
import CommandeFournisseur from "pages/Fournisseur/CommandeFournisseur/CommandeFournisseur"
import ModifCommandeFournisseur from "pages/Fournisseur/CommandeFournisseur/ModifCommandeFournisseur"
import DetailCommandeFournisseur from "pages/Fournisseur/CommandeFournisseur/DetailCommandeFournisseur"

// LivraisonFournisseur
import LivraisonFournisseur from "pages/Fournisseur/LivraisonFournisseur/LivraisonFournisseur"
import ModifLivraisonFournisseur from "pages/Fournisseur/LivraisonFournisseur/ModifLivraisonFournisseur"
import DetailLivraisonFournisseur from "pages/Fournisseur/LivraisonFournisseur/DetailLivraisonFournisseur"

// FactureDirect
import FactureDirectFournisseur from "pages/Fournisseur/FactureDirect/FactureDirectFournisseur"
import ModifFactureDirectFournisseur from "pages/Fournisseur/FactureDirect/ModifFactureDirectFournisseur"
import DetailFactureDirectFournisseur from "pages/Fournisseur/FactureDirect/DetailFactureDirectFournisseur"

// FactureLivraison
import FactureLivraison from "pages/Fournisseur/FactureLivraison/FactureLivraison"
import ModifFactureLivraison from "pages/Fournisseur/FactureLivraison/ModifFactureLivraison"
import DetailFactureLivraison from "pages/Fournisseur/FactureLivraison/DetailFactureLivraison"

// AvoirFournisseur
import AvoirFournisseur from "pages/Fournisseur/AvoirFournisseur/AvoirFournisseur"
import ModifAvoirFournisseur from "pages/Fournisseur/AvoirFournisseur/ModifAvoirFournisseur"
import DetailAvoirFournisseur from "pages/Fournisseur/AvoirFournisseur/DetailAvoirFournisseur"

// Bon avoir
import BonAvoir from "pages/Fournisseur/BonAvoir/BonAvoir"
import ModifBonAvoir from "pages/Fournisseur/BonAvoir/ModifBonAvoir"
import DetailBonAvoir from "pages/Fournisseur/BonAvoir/DetailBonAvoir"

// Bon entrée
import BonEntreeFournisseur from "pages/Fournisseur/BonEntree/BonEntreeFournisseur"
import ModifBonEntreeFournisseur from "pages/Fournisseur/BonEntree/ModifBonEntreeFournisseur"
import DetailBonEntreeFournisseur from "pages/Fournisseur/BonEntree/DetailBonEntreeFournisseur"

// Bon retourr
import BonRetourFournisseur from "pages/Fournisseur/BonRetour/BonRetourFournisseur"
import ModifBonRetourFournisseur from "pages/Fournisseur/BonRetour/ModifBonRetourFournisseur"
import DetailBonRetourFournisseur from "pages/Fournisseur/BonRetour/DetailBonRetourFournisseur"

// Produits
import Produits from "pages/Produits/Produits"
import DetailProduit from "pages/Produits/DetailProduit"
import ModifProduit from "pages/Produits/ModifProduit"

//Inventaire
import Inventaire from "pages/Inventaire/Inventaire"

import DetailInventaire from "pages/Inventaire/DetailInventaire"


// devis client
import DevisClient from "pages/Client/DevisClient/DevisClient"
import DetailDevisClient from "pages/Client/DevisClient/DetailDevisClient"
import EditDevisClient from "pages/Client/DevisClient/EditDevisClient"

// bon de commande client
import BonCmdClient from "pages/Client/BonCommandeClient/BonCmdClient"
import DetailBonCmdClient from "pages/Client/BonCommandeClient/DetailBonCmdClient"
import EditBonCmdClient from "pages/Client/BonCommandeClient/EditBonCmdClient"

// bon de livraison client
import BonLivClient from "pages/Client/BonLivraisonClient/BonLivClient"
import DetailBonLivClient from "pages/Client/BonLivraisonClient/DetailBonLivClient"
import EditBonLivClient from "pages/Client/BonLivraisonClient/EditBonLivClient"

// Facture client //
import FactClient from "pages/Client/FactureClient/FactClient"
import DetailFactClient from "pages/Client/FactureClient/DetailFactClient"
import EditFactClient from "pages/Client/FactureClient/EditFactClient"
// avoir client //
import AvoirClient from "pages/Client/AvoirClient/AvoirClient"
import DetailAvoirClient from "pages/Client/AvoirClient/DetailAvoirClient"
import EditAvoirClient from "pages/Client/AvoirClient/EditAvoirClient"
// bon avoir client //
import BonAvoirClient from "pages/Client/BonAvoirClient/BonAvoirClient"
import DetailBonAvoirClient from "pages/Client/BonAvoirClient/DetailBonAvoirClient"
import EditBonAvoirClient from "pages/Client/BonAvoirClient/EditBonAvoirClient"
// bon sortie client //
import BonSortieClient from "pages/Client/BonSortieClient/BonSortieClient"
import DetailBonSortieClient from "pages/Client/BonSortieClient/DetailBonSortieClient"
import EditBonSortieClient from "pages/Client/BonSortieClient/EditBonSortieClient"
// bon retour client //
import BonRetourClient from "pages/Client/BonRetourClient/BonRetourClient"
import DetailBonRetourClient from "pages/Client/BonRetourClient/DetailBonRetourClient"
import EditBonRetourClient from "pages/Client/BonRetourClient/EditBonRetourClient"
// BL client //
import FactureBlClient from "pages/Client/FactureBlClient/FactureBlClient"
import DetailFactureBlClient from "pages/Client/FactureBlClient/DetailFactureBlClient"
import EditFactureBlClient from "pages/Client/FactureBlClient/EditFactureBlClient"

// Reglement Fournisseur
import ReglementFournisseur from "pages/Reglements/ReglementFournisseur"
import DetailReglementFournisseur from "pages/Reglements/DetailReglementFournisseur"
import ModifReglementFournisseur from "pages/Reglements/ModifReglementFournisseur"
import ReglementClient from "pages/Reglements/ReglementClient"
import EditReglementClient from "pages/Reglements/EditReglementClient"
import detailReglementClient from "pages/Reglements/detailReglementClient"
import DetailUser from "pages/GestionUtilisateur/DetailUser"
import ModifUser from "pages/GestionUtilisateur/ModifUser"
import AddInventaire from "pages/Inventaire/AddInventaire1"


//Suivie 
import StatistiqueSuivi from "pages/Suivie/StatistiqueSuivi"
import Suivie from "pages/Suivie/Suivie"


//Deplacement d article d un depot a un autre

import Deplacement from "pages/DeplacementArticle/Deplacement"
// Promotion 
import Promotion from "pages/Promotion/Promo"
import AddPromo from "pages/Promotion/AddPromo"
import DetailPromo from "pages/Promotion/DetailPromo"
import ModifPromo from "pages/Promotion/ModifPromo"
// Banque 
import Banque from "pages/Parametrages/Banque/Banque"
import AddBanque from "pages/Parametrages/Banque/AddBanque"

//CadreFinancier
import CadreFinancier from "pages/Cadre_financier/CadreFinancier"
//societe
import Societe from "pages/Societe/Societe"
import Role from "pages/GestionRole/Role"
import ModifRole from "pages/GestionRole/ModifRole"



 


const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  // profile
  { path: "/profile", component: UserProfile },

  // Gestion Utilisateur
  { path: "/User", component: User },
  { path: "/DetailUser", component: DetailUser },
  { path: "/ModifUser", component: ModifUser },

  // paramétrage
  // *** gouvernerat *** //
  { path: "/Gouvernerat", component: Gouvernerat },
  // *** delegation *** //
  { path: "/Delegation", component: Delegation },
  // *** municipalite *** //
  { path: "/Municipalite", component: Municipalite },
  // *** type article *** //
  { path: "/TypeArticle", component: TypeArticle },
  // *** groupe article *** //
  { path: "/GroupeArticle", component: GroupeArticle },
  // *** famille article *** //
  { path: "/FamilleArticle", component: FamilleArticle },
  // *** marque *** //
  { path: "/Marque", component: Marque },
  // *** unite *** //
  { path: "/Unite", component: Unite },
  // *** personnel *** //
  { path: "/Personnel", component: Personnel },
  // *** FamilleClient *** //
  { path: "/FamilleClient", component: FamilleClient },
  // *** FamilleFournisseur *** //
  { path: "/FamilleFournisseur", component: FamilleFournisseur },
  // *** TypePaiement *** //
  { path: "/ModeReglement", component: ModeReglement },
  // *** FormeJuridique *** //
  { path: "/FormeJuridique", component: FormeJuridique },
  // *** Depot *** //
  { path: "/Depot", component: Depot },

    // *** TVA *** //
    { path: "/TVA", component: TVA },
  // *** Departement
  { path: "/Departement", component: Departement },
  // *** Departement *** //
  { path: "/Emplacement", component: Emplacement },
  // *** TypeRemise *** //
  { path: "/TypeRemise", component: TypeRemise },
  // *** RemiseQuantite *** //
  { path: "/RemiseQuantite", component: RemiseQuantite },
  // *** MatriculesBancaires *** //
  { path: "/MatriculesBancaires", component: MatriculesBancaires },
  // *** Compteur *** //
  { path: "/Compteur", component: Compteur },

  // Fournisseur
  { path: "/Fournisseur", component: Fournisseur },
  { path: "/DetailFournisseur", component: DetailFournisseur },
  { path: "/ModifFournisseur", component: ModifFournisseur },

  // client //
  { path: "/Client", component: Client },
  { path: "/DetailClient", component: DetailClient },
  { path: "/EditClient", component: EditClient },

  // Commande Fournisseur
  { path: "/CommandeFournisseur", component: CommandeFournisseur },
  { path: "/ModifCommandeFournisseur", component: ModifCommandeFournisseur },
  { path: "/DetailCommandeFournisseur", component: DetailCommandeFournisseur },

  // Livraison Fournisseur
  { path: "/LivraisonFournisseur", component: LivraisonFournisseur },
  { path: "/ModifLivraisonFournisseur", component: ModifLivraisonFournisseur },
  {
    path: "/DetailLivraisonFournisseur",
    component: DetailLivraisonFournisseur,
  },

  // Facture Direct
  { path: "/FactureDirectFournisseur", component: FactureDirectFournisseur },
  {
    path: "/ModifFactureDirectFournisseur",component:ModifFactureDirectFournisseur},
  {
    path: "/DetailFactureDirectFournisseur",
    component: DetailFactureDirectFournisseur,
  },

  // Facture livraison
  { path: "/FactureLivraison", component: FactureLivraison },
  { path: "/ModifFactureLivraison", component: ModifFactureLivraison },
  { path: "/DetailFactureLivraison", component: DetailFactureLivraison },

  // Avoir fournisseur
  { path: "/AvoirFournisseur", component: AvoirFournisseur },
  { path: "/ModifAvoirFournisseur", component: ModifAvoirFournisseur },
  { path: "/DetailAvoirFournisseur", component: DetailAvoirFournisseur },

  // Bon avoir
  { path: "/BonAvoir", component: BonAvoir },
  { path: "/ModifBonAvoir", component: ModifBonAvoir },
  { path: "/DetailBonAvoir", component: DetailBonAvoir },

  // Bon entree
  { path: "/BonEntreeFournisseur", component: BonEntreeFournisseur },
  { path: "/ModifBonEntreeFournisseur", component: ModifBonEntreeFournisseur },
  {
    path: "/DetailBonEntreeFournisseur",
    component: DetailBonEntreeFournisseur,
  },

  // Bon retour
  { path: "/BonRetourFournisseur", component: BonRetourFournisseur },
  { path: "/ModifBonRetourFournisseur", component: ModifBonRetourFournisseur },
  {
    path: "/DetailBonRetourFournisseur",
    component: DetailBonRetourFournisseur,
  },

  // Produits
  { path: "/Produits", component: Produits },
  { path: "/DetailProduit", component: DetailProduit },
  { path: "/ModifProduit", component: ModifProduit },

  // Devis client
  { path: "/DevisClient", component: DevisClient },
  { path: "/DetailDevisClient", component: DetailDevisClient },
  { path: "/EditDevisClient", component: EditDevisClient },
  // bon de commande client
  { path: "/BonCmdClient", component: BonCmdClient },
  { path: "/DetailBonCmdClient", component: DetailBonCmdClient },
  { path: "/EditBonCmdClient", component: EditBonCmdClient },
  // bon de livraison client
  { path: "/BonLivClient", component: BonLivClient },
  { path: "/DetailBonLivClient", component: DetailBonLivClient },
  { path: "/EditBonLivClient", component: EditBonLivClient },

  // facture client
  { path: "/FactClient", component: FactClient },
  { path: "/DetailFactClient", component: DetailFactClient },
  { path: "/EditFactClient", component: EditFactClient },
  // avoir client
  { path: "/AvoirClient", component: AvoirClient },
  { path: "/DetailAvoirClient", component: DetailAvoirClient },
  { path: "/EditAvoirClient", component: EditAvoirClient },
  // bon de livraison client
  { path: "/BonAvoirClient", component: BonAvoirClient },
  { path: "/DetailBonAvoirClient", component: DetailBonAvoirClient },
  { path: "/EditBonAvoirClient", component: EditBonAvoirClient },
  // bon de sortie client
  { path: "/BonSortieClient", component: BonSortieClient },
  { path: "/DetailBonSortieClient", component: DetailBonSortieClient },
  { path: "/EditBonSortieClient", component: EditBonSortieClient },
  // bon de retour client
  { path: "/BonRetourClient", component: BonRetourClient },
  { path: "/DetailBonRetourClient", component: DetailBonRetourClient },
  { path: "/EditBonRetourClient", component: EditBonRetourClient },
  // BL client
  { path: "/FactBlClient", component: FactureBlClient },
  { path: "/DetailFactBlClient", component: DetailFactureBlClient },
  { path: "/EditFactBlClient", component: EditFactureBlClient },

  // Reglement Fournisseur
  { path: "/ReglementFournisseur", component: ReglementFournisseur },
  { path: "/DetailReglementFournisseur", component: DetailReglementFournisseur },
  { path: "/EditReglementFournisseur", component: ModifReglementFournisseur },
 // Reglement Client
 { path: "/ReglementClient", component: ReglementClient},
 { path: "/EditReglementClient", component: EditReglementClient },
  { path: "/detailReglementClient",component: detailReglementClient},

  //Inventaire
  { path: "/Inventaire", component: Inventaire },
  { path: "/AddInventaire", component: AddInventaire },

  { path: "/DetailInventaire", component: DetailInventaire },
  { path: "/StatistiqueSuivi", component: StatistiqueSuivi},
   //Suivie 
   { path: "/Suivie", component: Suivie },
   //Deplacement 
   {path:"/Deplacement", component: Deplacement},

   //Promo
   {path: "/Promotions", component : Promotion},
   {path: "/AddPromo", component : AddPromo},
   {path: "/DetailPromo", component : DetailPromo},
   {path: "/ModifPromo", component : ModifPromo},

   //Banque 
      //Promo
      {path: "/Banque", component : Banque},

      //CadreFinancier
      {path: "/CadreFinancier", component :CadreFinancier},

      
      //CadreFinancier
      {path :"/Societe" , component :Societe},

//Roles
 { path: "/gestion_roles", component: Role },
 {path: "/ModifRole", component : ModifRole},



  //{ path: "/EditReglementFournisseur", component: ReglementFournisseur },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }
