import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Flip } from "react-toastify"
import { Row, Col, CardBody, Card, Container } from "reactstrap"
import { withRouter } from "react-router-dom"
// availity-reactstrap-validation
import { AvForm, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
// import images
//import cresusFond from "../../assets/images/cresusfond.png"
import BACGROUNDCRESSOFT from "../../assets/images/login Images/BACKGROUND_CRES_SOFT1.png"
import logo2 from "../../assets/images/login Images/CRES_SOFT[].png"
import logo1 from "../../assets/images/login Images/logo.png"
import logoPLANNING from "../../assets/images/login Images/PLANNING-8.png"
import logoHUMAN from "../../assets/images/login Images/HUMAN_RESOURSE-8.png"
import logoPURCHASE from "../../assets/images/login Images/PURCHASE-8.png"
import logoINVENTORY from "../../assets/images/login Images/INVENTORY-8.png"
import logoFINNACE from "../../assets/images/login Images/FINNACE-8.png"
import logoCRES from "../../assets/images/login Images/CRES_SOFT_ICON.png"
import axios from "axios"
import API from "../../api"
import CheckBox from "rc-checkbox"

const Login = props => {
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [years, setYears] = useState([])
  const [menuColor, setMenuColor] = useState()
  const [textColor, settextColor] = useState()

  const [LOGOImg, setLOGOImg] = useState(null)


  // const years = [...Array(20).keys()].map(i => new Date().getFullYear() - i) 
  const signIn = async () => {
    const res = await axios.post("https://backcommercial.cresus-institut.ovh/login", {
     // const res = await axios.post("https://backcommercial.cresus-institut.ovh/login", {
      email,
      password,
      api_password: "sKgkGkRhObr0kXdrNyjW5S",
      year: selectedYear,

    }).then(
      res => {
        if (res.data.status === 200) {
          localStorage.setItem("userAuth", JSON.stringify(res.data))

          props.history.push("/dashboard")
        } else {
          toast.error("⛔ Veuillez vérifier votre login et mot de passe !", {
            containerId: "A",
          })

        }
      }
    )

  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://backcommercial.cresus-institut.ovh/annee/get", {
          //const response = await axios.get("https://backcommercial.cresus-institut.ovh/annee/get", {
          apipassword: "sKgkGkRhObr0kXdrNyjW5S",

        }).then(
          resC => {
            setYears(resC.data)

          }
        )
        // setYears(response.data);

      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    fetchData();
    localStorage.setItem("menuState", JSON.stringify({}))


    // Cleanup function
    return () => {
      // Vous pouvez mettre ici le code de nettoyage si nécessaire
    };
  }, []);

  useEffect(async () => {
    const res = await API.get("societe/get_infos").then(res => {
     localStorage.setItem("color_menu",res.data.societe.couleur_menu)
     localStorage.setItem("color_Texte",res.data.societe.text_color)

     localStorage.setItem("LogoImg",res.data.logo)

     settextColor(res.data.societe.text_color)

      setMenuColor(res.data.societe.couleur_menu)
      setLOGOImg(res.data.logo)
    })
  }, []);
  


  return (
    <div 
    className="backgroundImages"
      style={{
        overflow: 'hidden',
        backgroundImage: `url(${BACGROUNDCRESSOFT})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        width: "100%",
        position: 'fixed',

      }}
    >
      <img 
        src={logo2}
        alt="logo2"
        className="Images"
        style={{
          height: "38rem",
          width:"600px",
          position: 'fixed',
          left: '90px',
          top: '60px',

          // marginRight: "5px", paddingBottom: '5px',


        }}
      />
      <React.Fragment>
        <MetaTags>
          <title>Login | Cresus</title>
        </MetaTags>

        <div className="d-flex justify-content-center align-items-center bg-black"  style={{ borderTop: '15px solid', borderColor: 'black' }}>
          <img
            src={logo1}
            alt="Logo"
            style={{ height: "30px", marginRight: "15px" , }}
          />
          <h4 className="text-white">CRESUS SOLUTIONS</h4>
        </div>







        <div className="pt-5 login">
          <div className="container-fluid">
            <Row>

              <Col  xs={12} md={6} lg={5} style={{ display: 'flex', justifyContent: 'center', marginTop: '220px', paddingLeft: '170px' }} className="tablette" >
                <div className="d-flex justify-content-between pl-5 pb-3">
                  <span className="Images"  style={{ fontSize: '50px', color: "white" }}>
                    CRES<span style={{ color: '#b0d809' }} >  SOFT</span>
                  </span>
                </div>
              </Col>

              <Col xs={12} md={10} lg={6} xl={5} className="mx-auto ms-auto me-auto   cardLogin" style={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
                <Card className="mt-5 formLogin" style={{ backgroundColor: 'black', width: '70%' }}>
                  <div style={{ borderTop: '5px solid #b0d809' }}>
                    <Row>
                      <Col xs={12}>
                        <div className=" p-4" style={{ color: 'white', textAlign: 'center' }}>
                          <h5>Se connecter</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody>
                    <AvForm className="form-horizontal">
                      <div className="mb-3 position-relative">
                        <AvField
                          name="email"
                          className="form-control pr-5"
                          placeholder="Email"
                          required
                          onChange={e => setEmail(e.target.value)}
                          style={{ paddingRight: '2.5rem' }}
                        />
                        <i className="fas fa-user position-absolute" style={{ top: '50%', right: '7%', transform: 'translateY(-50%)' }}></i>
                      </div>


                      <div className="mb-3 position-relative">
                        <AvField
                          name="password"
                          type="password"
                          required
                          placeholder="Mot de passe"
                          onChange={e => setPassword(e.target.value)}
                          className="form-control pr-5"
                          style={{ paddingRight: '2.5rem', width: '80%' }}
                        />
                        <i className="fas fa-lock position-absolute" style={{ top: '50%', right: '25%', transform: 'translateY(-50%)' }}></i>
                      </div>

                      <div className="mb-3 position-relative">
                        <select
                          id="year"
                          name="year"
                          className="form-control pr-5"
                          value={selectedYear}
                          onChange={e => setSelectedYear(e.target.value)}
                          style={{ paddingRight: '2.5rem', paddingLeft: '2.5rem', width: '60%' }}
                        >
                          {years.map(year => (
                            <option key={year.value} value={year.value}>
                              {year.label}
                            </option>
                          ))}
                        </select>
                        <i className="fas fa-calendar-alt position-absolute" style={{ top: '50%', right: '45%', transform: 'translateY(-50%)' }}></i>
                      </div>

                      <div className="text-primary" >
                        <AvCheckboxGroup
                          name="rememberMe"

                          style={{ border: 'none', boxShadow: 'none', display: 'inline-block', width: '0px' }}
                        >
                          <AvCheckbox style={{ color: 'white' }} />

                        </AvCheckboxGroup>
                        <label style={{ color: 'white', paddingLeft: '20px' }}>rester connecté</label>
                        <a
                          href="/forgot-password"
                          className="float-end text-decoration-none mt-2"
                          style={{ color: 'white', cursor: 'pointer' }}
                          target="_blank"
                        >
                          Mot de passe oublié ?
                        </a>



                      </div>





                      <div className="mt-3 d-grid">
                        <button
                          onClick={signIn}
                          className="btn btn-success btn-block"
                          type="submit"
                          style={{
                            backgroundColor: '#b0d809',
                            borderColor: '#b0d809',
                            width: '150px',
                            marginLeft: '120px'


                          }}>
                          Se connecter
                        </button>
                      </div>

                      <div className="mt-3 d-flex justify-content-center" style={{ textAlign: 'center' }}>
                        <span style={{ color: 'white', textAlign: 'center' }}>
                          {"Vous n'avez pas de compte?"}
                          <a
                            href="/"
                            className="text-decoration-none"
                            style={{ color: 'white', paddingLeft: '10px' }}
                          >
                            Créer un compte
                          </a>
                        </span>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '1px'  }}>
              <Col xs="auto" className="d-flex align-items-center">
                {/*<img
                  src={logo1}
                  alt="Logo"
                  style={{ height: "30px", marginRight: "5px", paddingBottom: '5px' }}
                />
                <div className="text-white d-inline">
                  <div >CRESUS</div>
                  <div>SOLUTIONS</div>
                        </div>*/}
              </Col>
              <Col className ="Images" >
                <img
                  src={logoPLANNING}
                  alt="logoPLANNING"
                  style={{
                    height: "50px",
                    position: 'absolute',

                    // marginRight: "5px", paddingBottom: '5px',
                    animation: 'pulse 1s ease-in-out infinite'
                  }}
                />
                <img
                  src={logoHUMAN}
                  alt="logoHUMAN"
                  style={{
                    height: "50px",
                    position: 'absolute',
                    left: '500px',
                    bottom: '110px',

                    // marginRight: "5px", paddingBottom: '5px',
                    animation: 'pulse 1s ease-in-out infinite'
                  }}
                />

                <img
                  src={logoPURCHASE}
                  alt="logoPURCHASE"
                  style={{
                    height: "50px",
                    position: 'absolute',
                    left: '257px',
                    bottom: '60px',

                    // marginRight: "5px", paddingBottom: '5px',
                    animation: 'pulse 1s ease-in-out infinite'
                  }}
                />
                <img
                  src={logoINVENTORY}
                  alt="logoINVENTORY"
                  style={{
                    height: "50px",
                    position: 'absolute',
                    left: '150px',
                    bottom: '450px',

                    // marginRight: "5px", paddingBottom: '5px',
                    animation: 'pulse 1s ease-in-out infinite'
                  }}
                />
                <img
                  src={logoFINNACE}
                  alt="logoFINNACE"
                  style={{
                    height: "50px",
                    position: 'absolute',
                    left: '750px',
                    bottom: '250px',

                    // marginRight: "5px", paddingBottom: '5px',
                    animation: 'pulse 1s ease-in-out infinite'
                  }}
                />

                <img
                  src={logoCRES}
                  alt="logoCRES"
                  style={{
                    height: "50px",
                    position: 'absolute',
                    left: '350px',
                    bottom: '300px',

                    // marginRight: "5px", paddingBottom: '5px',
                    animation: 'pulse 1s ease-in-out infinite'
                  }}
                />


              </Col>
              
              <style>
                  {`
      @keyframes pulse {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.1);
        }
        100% {
          transform: scale(1);
        }
      }
 


      
@media (max-width: 1000px) {
  .Images{
    display:none
  }

  .tablette{
    display:none,
    marginTop: '0px',
    paddingLeft: '0px' 
  }
  row{
    display:flex;
  }
  body{
    position:relative;
  }
  .cardLogin {
    margin-bottom: 200px;
}

    

  }
}
    
    `}
                </style>

            </Row>





          </div>


        </div>

        <ToastContainer
          transition={Flip}
          enableMultiContainer
          containerId={"A"}
          position={toast.POSITION.TOP_RIGHT}
          autoClose={2500}
        />
      </React.Fragment>
    </div>
  )


}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
