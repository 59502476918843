import React, { useEffect, useState } from "react"
import Select from "react-select"
import API from "../../api"
import PropTypes from "prop-types"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { withTranslation } from "react-i18next"
import { Button, Col, Label, Row ,Spinner} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import DataTable from "react-data-table-component"
// Checkbox
import Checkbox from "rc-checkbox"
import "rc-checkbox/assets/index.css"

const ModifRole = props => {
    const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [roles, setRoles] = useState([])
  const [selectRole, setSelectRole] = useState("")
  const [isChecked, setIsChecked] = useState(false)
// pagination
const [nbrTotlEl, setNbrTotlEl] = useState(0)
const [nbrEltperPage, setNbrEltperPage] = useState(5)
const [filterElement, setFilterElement] = useState("")
const [mycurrentPage, setMycurrentPage] = useState(1)
const [resetFirstPage, setResetFirstPage] = useState(false)
const [listmenu, setListmenu] = useState([])
const [id, setId] = useState("")
const [permissions, setPermissions] = useState([]);
//filteredMenus
const [filteredMenus, setFilteredMenus] = useState([]);
const [checkboxStates, setCheckboxStates] = useState([]);



 useEffect(async () => {
 var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    setId(id)

  
 API.post("role/get_by_id", { id }).then(resU => {

  console.log('resU',resU)

  
    setListmenu(resU.data.Role.permission);
    setName(resU.data.Role.name);
    setPermissions(resU.data.Role.permission)

 
    
    setLoading(true)
  })

}, [])

useEffect(() => {
  const updatedFilteredMenus = listmenu.map(menu => {
    const filteredSubMenus = menu.sous_menu.filter(subMenu => {
      return (
        subMenu.add !== 0 ||
        subMenu.delete !== 0 ||
        subMenu.edit !== 0 ||
        subMenu.view !== 0 ||
        subMenu.imp !== 0
      );
    });

    if (filteredSubMenus.length > 0) {
      return {
        menu: menu.menu,
        menu_id: menu.menu_id,
        sous_menu: filteredSubMenus
      };
    }

    return null;
  }).filter(menu => menu !== null);

  setFilteredMenus(updatedFilteredMenus);
}, [listmenu]);


const checkMenu = (data) => {
  console.log("ID donné :", data.data.id);
  console.log("Liste des menus :", listmenu);

  const index = listmenu.findIndex(item => item.menu_id === data.data.id);
  console.log("Index dans listmenu :", index);

  if (index !== -1) { 
      const menuItem = listmenu[index]; 
      const sousMenuCount = menuItem.sous_menu.length; 

      console.log("Nombre d'éléments dans sous_menu :", sousMenuCount);

      const e = { 
          target: { checked: true }
      };

      for (let i = 0; i < sousMenuCount; i++) {
          addPerm(index, e, i, 'add');
          addPerm(index, e, i, 'edit');
          addPerm(index, e, i, 'delete');
          addPerm(index, e, i, 'view');
          addPerm(index, e, i, 'imp');

      }
  } else {
      console.log("ID non trouvé dans listmenu");
  }
}


const uncheckMenu = (data) => {
console.log("ID donné :", data.data.id);
console.log("Liste des menus :", listmenu);

const index = listmenu.findIndex(item => item.menu_id === data.data.id);
console.log("Index dans listmenu :", index);

if (index !== -1) { 
    const menuItem = listmenu[index]; 
    const sousMenuCount = menuItem.sous_menu.length; 

    console.log("Nombre d'éléments dans sous_menu :", sousMenuCount);

    const e = { 
        target: { checked: false }
    };

    for (let i = 0; i < sousMenuCount; i++) {
        addPerm(index, e, i, 'add');
        addPerm(index, e, i, 'edit');
        addPerm(index, e, i, 'delete');
        addPerm(index, e, i, 'view');
        addPerm(index, e, i, 'imp');

    }
} else {
    console.log("ID non trouvé dans listmenu");
}
}


const checkSubMenu = (index, i) => {
  const e = {
    target: { checked: true }
  };

  addPerm(index, e, i, 'add');
  addPerm(index, e, i, 'delete');
  addPerm(index, e, i, 'edit');
  addPerm(index, e, i, 'view');
  addPerm(index, e, i, 'imp');
};
const uncheckSubMenu = (index, i) => {
  const e = {
    target: { checked: false }
  };

  addPerm(index, e, i, 'add');
  addPerm(index, e, i, 'delete');
  addPerm(index, e, i, 'edit');
  addPerm(index, e, i, 'view');
  addPerm(index, e, i, 'imp');
};

// const filteredMenus = listmenu.map(menu => {
//   const filteredSubMenus = menu.sous_menu.filter(subMenu => {
//     return Object.values(subMenu).some(listmenu => listmenu === 1);
//   });

//   if (filteredSubMenus.length > 0) {
//     return {
//       menu: menu.menu,
//       menu_id: menu.menu_id,
//       sous_menu: filteredSubMenus
//     };
//   }

//   return null; // Exclude menus with all permissions set to 0
// }).filter(menu => menu !== null);
// console.log(filteredMenus)
// const addPerm = (index, e, rowIndex, attribute,attribut) => {
//      const rowData = dataa[index].Detail[rowIndex];
//      console.log(attribute)
//      console.log(attribut)
//      console.log(rowData.props.children.props.data[0][attribut])
//      console.log(rowData.props.children.props.data[0][attribut].props.children.props.checked)
//      if(rowData.props.children.props.data[0][attribut].props.children.props.checked == false)
//      {
//       console.log("a")
//       console.log(listmenu[index].sous_menu[rowIndex])
//       // setPermissions((prevPermissions) => {
//       // const  updatedPermissions = [...prevPermissions];
//       //   if (!updatedPermissions[index]) {
//       //   updatedPermissions[index] = [];
//       //   console.log(updatedPermissions)
//       // }
//       // if (!updatedPermissions[index][rowIndex]) {
//      //   updatedPermissions[index][rowIndex] = {
//      //     sousmenu: rowData.props.children.props.data[0].id,
//      //     attribut: 1,
     
//      //   };
//      // }
//          // else{
//      //   console.log( updatedPermissions[index][rowIndex][attribute])
    
//    // }
//       // })
//      }
//      else{
//       console.log(listmenu[index].sous_menu[rowIndex])
//      }
//   // setPermissions((prevPermissions) => {
//   //   const updatedPermissions = [...prevPermissions];
//   //   const rowData = dataa[index].Detail[rowIndex];
//   //   console.log(updatedPermissions[index])
//   //   // Initialize the matrix for this index if it's not already defined
//   //   // if (!updatedPermissions[index]) {
//   //   //   updatedPermissions[index] = [];
//   //   //   console.log(updatedPermissions)
//   //   // }

//   //   // Initialize the row for this rowIndex if it's not already defined
//   //   // if (!updatedPermissions[index][rowIndex]) {
//   //   //   updatedPermissions[index][rowIndex] = {
//   //   //     sousmenu: rowData.props.children.props.data[0].id,
//   //   //     add: 0,
//   //   //     delete: 0,
//   //   //     edit: 0,
//   //   //     view: 0,
//   //   //     imp: 0,
//   //   //   };
//   //   // }
//   //   // else{
//   //   //   console.log( updatedPermissions[index][rowIndex][attribute])
    
//   //   // }


    

//   //   // Update the attribute value for this combination of index and rowIndex
//   // //  updatedPermissions[index][rowIndex][attribute] = e.target.checked ? 1 : 0;
//   //   //return updatedPermissions;
//   // // console.log(updatedPermissions)
//   // });
// };

const addPerm = (index, e, rowIndex, attribute) => {
  const rowData = dataa[index].Detail[rowIndex];
  const updatedCheckboxStates = [...checkboxStates];
  const updatedFilteredMenus = [...permissions];
 /* if (!updatedFilteredMenus[index]) {
    updatedFilteredMenus[index] = {
      sous_menu: [],
    };
  }*/
/*
  if (!updatedFilteredMenus[index].sous_menu[rowIndex]) {
    updatedFilteredMenus[index].sous_menu[rowIndex] = {
      sous_menu_id: rowData.props.children.props.data[0].id,
      add: 1,
      delete: 1,
      edit: 1,
      view: 1,
      imp: 1,
    };
  }*/
  updatedFilteredMenus[index].sous_menu[rowIndex][attribute] = e.target.checked ? 1 : 0;
  if (!updatedCheckboxStates[index]) {
    updatedCheckboxStates[index] = [];
  }
  updatedCheckboxStates[index][rowIndex] = e.target.checked;

  setCheckboxStates(updatedCheckboxStates);
  setFilteredMenus(updatedFilteredMenus);
  setPermissions(updatedFilteredMenus); 
};




// const addPerm = (index, e, rowIndex, attribute,attribut) => {
  
//    // const updatedPermissions = [...prevPermissions];
//     const rowData = dataa[index].Detail[rowIndex];
// console.log(rowData.props.children.props.data[0][attribut].props.children.props.checked)
// console.log(filteredMenus[index].sous_menu[rowIndex][attribute])
//  // Get the current value of the attribute in filteredMenus
//  const currentAttributeValue = filteredMenus[index].sous_menu[rowIndex][attribute];

//  // Determine the new value based on the condition you mentioned
//  let newValue;
//  if (attribut === 'ajouter' && rowData.props.children.props.data[0][attribut].props.children.props.checked) {
//   // If attribut is 'add' and its condition is true, set to 1
//   newValue = 1;
// } else if (currentAttributeValue === 1) {
//   // If current value is 1, set to 0
//   newValue = 0;
// } else {
//   // Otherwise, set to 1
//   newValue = 1;
// }
// setFilteredMenus(filteredMenus[index].sous_menu[rowIndex][attribute]=newValue)
// console.log(filteredMenus[index].sous_menu[rowIndex][attribute])
// console.log(filteredMenus)

  
// };


const expandableRowsComponent = (row) => (
  <div>
    {/* {dataa.map((el, index) => (
      // Check if row.unique_id is equal to index before rendering the div
        <div key={index}>{el.Detail}{console.log(el.Detail)}{console.log(row)}</div>
      
    ))} */}
     <button style={{ marginRight: '5px', backgroundColor: 'green', color: 'white', border: 'none', borderRadius: '5px' }} onClick={(e) => checkMenu(row)} ><i className="fa fa-check-circle" style={{ color: 'white' }}></i></button>
      <button style={{ backgroundColor: 'red', color: 'white', border: 'none', borderRadius: '5px' }} ><i className="fa fa-times-circle" style={{ color: 'white' }} onClick={(e) =>uncheckMenu(row)} ></i></button>

    {row.data.Detail.map((el,i)=>(
      <div key={i}>
{el}
      </div>
    ))}
  </div>
);

const dataa = listmenu.map((menu,index) => ({
  id: menu.menu_id,
  name: menu.menu,
 Detail: menu.sous_menu.map((sousmenu, i) => (

<div key={i}>

           <DataTable
      columns={[
        {
          name: 'sousmenu',
          cell: row => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button style={{ marginRight: '5px', backgroundColor: 'green', color: 'white', border: 'none', borderRadius: '5px' }} onClick={(e) => checkSubMenu(index, i)} ><i className="fa fa-check-circle" style={{ color: 'white' }}></i></button>
              <button style={{ backgroundColor: 'red', color: 'white', border: 'none', borderRadius: '5px' }} ><i className="fa fa-times-circle" style={{ color: 'white' }} onClick={(e) => uncheckSubMenu(index, i)} ></i></button>


            </div>
          ),
          sortable: true
        },
        { name: 'sousmenu', selector: 'sousmenu', sortable: true },
        { name: 'ajouter', selector: 'ajouter', sortable: true },
        { name: 'supprimer', selector: 'supprimer', sortable: true },
        { name: 'modifier', selector: 'modifier', sortable: true },
        { name: 'consulter', selector: 'consulter', sortable: true },
        { name: 'imprimer', selector: 'imprimer', sortable: true },
      ]}
      
      data={
       [{
     id: sousmenu.sous_menu_id,
        sousmenu: sousmenu.sous_menu,
        ajouter: (
          <div key={i} style={{ display: 'flex', justifyContent: 'center' }}>
          <Checkbox 
  //  checked={sousmenu.add === 1 ? true :isChecked}
  checked={ permissions &&  permissions[index]?.sous_menu[i]?.add === 1 }
      onChange={
        (e) => addPerm(index,e, i, 'add','ajouter')} />
          {/* {
            sousmenu.add === 1 ?
            <Checkbox 
            checked={sousmenu.add === 1 }
            onChange={(e) => {
              addPerm(index, e, i, 'add', 'ajouter');
            }}
          /> :        <Checkbox 
              checked={isChecked }
         
            onChange={(e) => {
                                  addPerm(index, e, i, 'add', 'ajouter');
           
          }}
        />
          } */}
   

          </div>
        ),
        supprimer: (
          <div key={i} style={{ display: 'flex', justifyContent: 'center' }}>
             <Checkbox 
     // checked={sousmenu.delete === 1}
     checked={ permissions && permissions[index]?.sous_menu[i]?.delete ===1 }

      onChange={(e) => addPerm(index,e, i, 'delete','supprimer')} />
          </div>
        ),
        modifier: (
          <div key={i} style={{ display: 'flex', justifyContent: 'center' }}>
           <Checkbox 
       //checked={sousmenu.edit === 1}
       checked={ permissions &&  permissions[index]?.sous_menu[i]?.edit ===1 }

      onChange={(e) => addPerm(index,e, i, 'edit','modifier')} />
          </div>
        ),
        consulter: (
          <div key={i} style={{ display: 'flex', justifyContent: 'center' }}>
            <Checkbox 
     // checked={sousmenu.view === 1}
     checked={permissions && permissions[index]?.sous_menu[i]?.view ===1 }

      onChange={(e) => addPerm(index,e, i, 'view','consulter')} />
          </div>
        ),
        imprimer: (
          <div key={i} style={{ display: 'flex', justifyContent: 'center' }}>
            <Checkbox 
      // checked={sousmenu.imp === 1}
      checked={permissions && permissions[index]?.sous_menu[i]?.imp ===1 }

      onChange={(e) => addPerm(index,e, i, 'imp','imprimer')} />
          </div>
        )
       }] 
        
    }
      noHeader
     
    //  expandableRowsComponent={YourComponent}
    />
</div>
  )),
}));

const renderChildRow = (row) => {
  return (
    <div>
      <p>Name: {row.name}</p>
      <p>Age: {row.age}</p>
      <p>Country: {row.country}</p>
      {/* You can add other child row information as needed */}
    </div>
  );
};
const columns = [

  { name: 'Menu', selector: (row) => row.name },

];

////Methode update ////
/////
const edit = async () => {
    const res = await API.post("role/edit", {
      id: id,
      name: name,
      permissions:permissions,
    })
    .then(res => {
      props.history.push("/gestion_roles")  
     //props.setSection(1)
    
     })
}
/////

const newElement = async (page, totalRows) => {
    setMycurrentPage(page)
    const res = await API.get("menu/list")
    .then(res => {
      setListmenu(res.data.Menu)
      //setNbrTotlEl(res.data.Data[0].total)
    })
  }

  const perPage = async (currentRowsPerPage, currentPage) => {
    setMycurrentPage(currentPage)
    const res = await API.get("menu/list")
    .then(res => {
      setListmenu(res.data.Menu)
      // setNbrTotlEl(res.data.Data[0].total)
    })
  }




  return (
     <React.Fragment>
      <div className="page-content card" >
      {loading ? (

        <Row>
          <AvForm>
            <Row>

              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Nom du rôle</Label>
                  <AvField
                    name="nom"
                    placeholder="Inserer un nom"
                    type="text"
                    errorMessage="* Nom obligatoire"
                    className="form-control"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    validate={{ required: { value: true } }}
                  />
                </div>
              </Col>
            </Row>
    {/* datatable permissions */}


          <DataTable
      title="Permissions"
      columns={columns}
      data={dataa}
      // pagination={true}
      // paginationServer={true}
      // onChangePage={(page, totalRows) => newElement(page, totalRows)}
      expandableRows
      expandableRowsComponent={expandableRowsComponent}
      //onExpandableRowClicked={expandableRowsComponent}
       onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                perPage(currentRowsPerPage, currentPage)
              }
    />

            <div style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  className="text-center mt-4"
                >
                  <Button
                    type="button"
                    color="warning"
                    className="btn btn-warning  mb-2 me-2"
                    // onClick={props.back}
                    onClick={() => props.history.push("/User")}
                  >
                    {props.t("Annuler")}
                  </Button>
                </div>
              </Col>
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
                >
                  <button
                    onClick={edit}
                    type="submit"
                    className="btn btn-primary "
                  >
                    Confirmer
                  </button>
                </div>
              </Col>
            </div>
          </AvForm>
        
       

        </Row>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4
            style={{ textAlign: "center", marginTop: "2%" }}
            className="ms-6"
          >
            {" "}
            {props.t("Chargement en cours...")}
          </h4>
        </div>
      )}
      </div>
   
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withTranslation()(ModifRole)
ModifRole.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}