import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import { Row, Col, Spinner, Label, Input } from "reactstrap"
import { withRouter } from "react-router"
//lang
import { withTranslation } from "react-i18next"
import { Search } from "react-bootstrap-table2-toolkit"
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import 'jspdf-autotable';

import { ToastContainer, toast } from "react-toastify"
import { Slide } from "react-toastify"



const DeplacementArticle = props => {
  const [loading, setLoading] = useState(false)
  const [depotSortie, setDepotSortie] = useState([])
  const [depotEntree, setDepotEntree] = useState([])

  const [selectDepotSortie, setSelectDepotSortie] = useState("")
  const [selectDepotEntree, setSelectDepotEntree] = useState("")


  const [depotEntree_id, setDepotEntree_id] = useState(0)
  const [depot_id, setDepot_id] = useState(0)


  const [quantite, setQuantite] = useState("")

  const [userdata, setUserdata] = useState([])

  //pdf 
  const contentRef = useRef(null);

  //date
  const [date, setDate] = useState(new Date())
  // Produits
  const [produit_id, setProduit_id] = useState(0)
  const [produits, setProduits] = useState([])
  const [selectProduit, setSelectProduit] = useState()




  const userAuth = JSON.parse(localStorage.getItem('userAuth'));
  const user_id = userAuth.user.id



  //verification
  const [depotError, setDepotError] = useState("");
  const [dateDebutError, setDateDebutError] = useState("");
  const [dateFinError, setDateFinError] = useState("");
  const [perm, setPerm] = useState({
    add: 0,
    delete: 0,
    edit: 0,
    imp: 0,
    view: 0
  });
  const maxHeight = 150;




  const handleDepotSortieChange = (selectedOption) => {
    setDepot_id(selectedOption.value);
    setProduits([]);

    //setSelectProduit([]);
    setSelectDepotSortie(selectedOption);
    //setSelectProduit(false);

  };
  const handleArticleChange = (selectedOption) => {
    setProduit_id(selectedOption.value)
    setSelectProduit(selectedOption)
    // setSelectDepot(true);
    // setSelectProduit(true);

  };



  const handleDepotEntreeChange = (selectedOption) => {
    setDepotEntree_id(selectedOption.value);


    //setSelectProduit([]);
    setSelectDepotEntree(selectedOption);
    //setSelectProduit(false);

  };


  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('userAuth'));
    setUserdata(userAuth.user.userData)
  }, [])

  useEffect(() => {
    const typeArticleSubMenu = userdata.find(user =>
      user.sous_menus.some(subMenu => subMenu.sous_menu === "Deplacement articles")
    );

    if (typeArticleSubMenu) {
      const typeArticlePermission = typeArticleSubMenu.sous_menus.find(
        subMenu => subMenu.sous_menu === "Deplacement articles"
      ).permissions[0];
      setPerm(prevPerm => ({
        ...prevPerm,
        add: typeArticlePermission.add,
        delete: typeArticlePermission.delete,
        edit: typeArticlePermission.edit,
        imp: typeArticlePermission.imp,
        view: typeArticlePermission.view,
      }));
    }
  }, [userdata]);

  useEffect(async () => {
    const resDE = await API.get("depot/select").then(resDE => {
      setDepotSortie(resDE.data.Depot)
      setDepotEntree(resDE.data.Depot)


    })


    setLoading(true);
  }, []);

  useEffect(async () => {
    const resP = await API.post("stock/get_articles_by_depot", {
      depot_id: depot_id
    }).then(resP => {
      setProduits(resP.data);
      if (resP.data.length === 0) {
        setSelectProduit()

      }
      else { setSelectProduit(resP.data[0]); }
    });

    setLoading(true);
  }, [depot_id]);

  const confirmer = async () => {

    if (date != "" && selectDepotSortie && selectDepotEntree && quantite != 0) {

      if (selectProduit) {
        const resDeplacement = await API.post("stock/deplacement_articles", {
          user_id: user_id,
          depotSortie_id: depot_id,
          depotEntree_id: depotEntree_id,
          article_id: selectProduit.value,
          date: date,
          quantite: quantite
        }).then(data => {
        }).catch(error => {
          if (error.response && error.response.data && error.response.data.error) {
            console.error('Erreur1:', error.response.data.error);
            toast.error(error.response.data.error, {
              containerId: "A",
            });
          } else {
            window.location.reload();
          }
        });

      }

    } else {
      toast.error("⛔ Remplir les champs obligatoire", {
        containerId: "A",
      })
    }




  };




  const { SearchBar } = Search

  return (
    <React.Fragment>

      <div ref={contentRef}>

        {loading ? (

          <Row>

            <Col md={4} sm={6}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Date </Label>
                <Col md={12} className="pr-0">
                  <DatePicker
                    className="form-control ddate"
                    selected={date}
                    onChange={e => setDate(e)}
                    dateFormat="dd/MM/yyyy"
                    readOnly={true}
                  />
                </Col>
              </div>
            </Col>





            <Col md={4} sm={6}>
              <div className="mb-3">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-arround",
                  }}
                >
                  <Label for="basicpill-firstname-input1">
                    Depot de sortie
                  </Label>
                  <Label style={{ color: "red", paddingLeft: "5px" }}>
                    *
                  </Label>
                </div>
                <Select
                  required
                  options={depotSortie}
                  isSearchable={true}
                  classNamePrefix="select2-selection"
                  onChange={(selectedOption) => {
                    handleDepotSortieChange(selectedOption);


                  }}
                  styles={{
                    menu: base => ({ ...base, maxHeight, overflowY: 'auto' })
                  }}

                />

              </div>
            </Col>

            <Col md={4} sm={6}>
              <div className="mb-3">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-arround",
                  }}
                >
                  <Label for="basicpill-firstname-input1">
                    Article
                  </Label>
                  <Label style={{ color: "red", paddingLeft: "5px" }}>
                    *
                  </Label>
                </div>
                <Select
                  required
                  options={produits}
                  isSearchable={true}
                  classNamePrefix="select2-selection"
                  onChange={(selectedOption) => {
                    handleArticleChange(selectedOption);
                  }}

                  value={selectProduit}
                />

              </div>
            </Col>





            <Col md={4} sm={6}>
              <div className="mb-3">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-arround",
                  }}
                >
                  <Label for="basicpill-firstname-input1">
                    {"Depot d'entrée"}
                  </Label>
                  <Label style={{ color: "red", paddingLeft: "5px" }}>
                    *
                  </Label>
                </div>
                <Select
                  required
                  options={depotEntree}
                  isSearchable={true}
                  classNamePrefix="select2-selection"
                  onChange={(selectedOption) => {
                    handleDepotEntreeChange(selectedOption);
                  }}
                  styles={{
                    menu: base => ({ ...base, maxHeight, overflowY: 'auto' })
                  }}

                />

              </div>
            </Col>



            <Col md={4} sm={6}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Quantite </Label>
                <Label style={{ color: "red", paddingLeft: "5px" }}>
                  *
                </Label>
                <Input
                  lg="3"
                  type="number"
                  className="form-control"
                  onChange={e => setQuantite(e.target.value)}
                />
              </div>
            </Col>


            <col></col>

            <Col md={{ span: 4, offset: 4 }}>
              <div

                className="text-center mt-4"
              >
                <button
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}

                  type="submit"
                  className="btn btn-primary"
                  onClick={confirmer}
                >
                  Confirmer
                </button>
              </div>
            </Col>









            <Col md={4}  >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="text-center mt-4"
              >

              </div>
            </Col>



          </Row>


        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />


    </React.Fragment>
  )
}
export default withRouter(withTranslation()(DeplacementArticle))
DeplacementArticle.propTypes = {
  personnel: PropTypes.array,
  history: PropTypes.object,
  //setSection: PropTypes.object,

  t: PropTypes.any,
}
