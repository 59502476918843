import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../../api"
import { Row, Col, Spinner, Modal, ModalBody, Button, Label } from "reactstrap"
import { withRouter } from "react-router"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { withTranslation } from "react-i18next"
import DataTable from "react-data-table-component"

const DataTableBanque = props => {
  const [loading, setLoading] = useState(false)
  const [modalUpd, setModalUpd] = useState(false)
  const [modalDel, setModalDel] = useState(false)
  const [id, setId] = useState("")
  const [code, setCode] = useState("")
  const [etatCode, setEtatCode] = useState(false)
  const [name, setName] = useState("")
  // pagination
  const [nbrTotlEl, setNbrTotlEl] = useState(0)
  const [nbrEltperPage, setNbrEltperPage] = useState(5)
  const [filterElement, setFilterElement] = useState("")
  const [mycurrentPage, setMycurrentPage] = useState(1)
  const [resetFirstPage, setResetFirstPage] = useState(false)
  const [data, setData] = useState([])
//////permission//////
const [userdata, setUserdata] = useState([])
const [perm, setPerm] = useState({
  add: 0,
  delete:0,
  edit:0,
  imp:0,
  view:0
});

useEffect(async () => {
  const userAuth = JSON.parse(localStorage.getItem('userAuth'));
  console.log(userAuth)
  setUserdata(userAuth.user.userData)
console.log(userAuth.user.userData)
}, [])


useEffect(() => {
console.log(userdata)
  const typeArticleSubMenu = userdata.find(user => 
    user.sous_menus.some(subMenu => subMenu.sous_menu === "Banque")
  );

  if (typeArticleSubMenu) {
    console.log(typeArticleSubMenu)
    const typeArticlePermission = typeArticleSubMenu.sous_menus.find(
      subMenu => subMenu.sous_menu === "Banque"
    ).permissions[0];
    console.log(typeArticlePermission)
    setPerm(prevPerm => ({
      ...prevPerm,
      add: typeArticlePermission.add,
      delete:typeArticlePermission.delete,
      edit:typeArticlePermission.edit,
      imp:typeArticlePermission.imp,
      view:typeArticlePermission.view
    }));
  }
}, [userdata]);
console.log(perm)
///////////permission////
  const columns = [
    {
      name: "Code",
      selector: "code",
      sortable: false,
      center: true,
    },
    {
      name: "Nom banque",
      selector: "name",
      sortable: false,
      center: true,
    },
    {
      name: "Modification",
      // eslint-disable-next-line react/display-name
      cell: row =>
      perm.edit == 1 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            style={{ color: "cornflowerblue", cursor: "pointer" }}
            className="fas fa-edit"
            onClick={() => toggleUpd(row)}
          ></i>
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            style={{ color: "rgb(167, 163, 163)", cursor: "pointer" }}
            className="fas fa-edit"
            title="pas de perm"
          ></i>
        </div>
      ),
      center: true,
      reorder: true,
    },
    {
      name: "Suppression",
      // eslint-disable-next-line react/display-name
      cell: row =>
         perm.delete == 1 && row.permession_supp===true ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#ED6464", cursor: "pointer" }}
              onClick={() => toggleDel(row.id)}
              className="fas fa-trash-alt"
            ></i>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "rgb(167, 163, 163)", cursor: "pointer" }}
              className="fas fa-trash-alt"
              title="Type d'article déjà utilisé"
            ></i>
          </div>
        ),
      center: true,
      reorder: true,
    },
  ]

  const customStyles = {
    rows: {
      style: {
        minHeight: "52px", // override the row height
      },
    },
    headCells: {
      style: {
        fontSize: "13px",
        backgroundColor: "#eff2f7",
      },
    },
    cells: {
      style: {
        fontSize: "13px",
      },
    },
  }

  useEffect(async () => {
    const res = await API.post("banque/list", {
      filterElement: filterElement,
      nbrElParPage: 5,
      page: 1,
    }).then(res => {
      setData(res.data[0].Banques)
      setNbrTotlEl(res.data[0].total)
    })
    setLoading(true)
  }, [])

  const newElement = async (page, totalRows) => {
    setMycurrentPage(page)
    const res = await API.post("banque/list", {
      filterElement: filterElement,
      nbrElParPage: nbrEltperPage,
      page: page,
    }).then(res => {
      setData(res.data[0].Banques)
      setNbrTotlEl(res.data[0].total)
    })
  }

  const perPage = async (currentRowsPerPage, currentPage) => {
    setMycurrentPage(currentPage)
    const res = await API.post("banque/list", {
      filterElement: filterElement,
      nbrElParPage: currentRowsPerPage,
      page: currentPage,
    }).then(res => {
      setData(res.data[0].Banques)
      setNbrTotlEl(res.data[0].total)
    })
  }

  const filterSearch = async e => {
    setFilterElement(e.target.value)
    const res = await API.post("banque/list", {
      filterElement: e.target.value,
      nbrElParPage: nbrEltperPage,
      page: mycurrentPage,
    }).then(res => {
      setData(res.data[0].Banques)
      setNbrTotlEl(res.data[0].total)
    })
  }

  const toggleUpd = el => {
    setModalUpd(!modalUpd)
    setId(el.id)
    setName(el.name)
    setCode(el.code)
  }

  const toggleDel = id => {
    setModalDel(!modalDel)
    setId(id)
  }

  const toggleModif = async () => {
    if (name != "" && code != "") {
      const res = await API.post("banque/edit", {
        id: id,
        name: name,
        code: code,
      }).then(res => {
        if (res.data[0].status == 200) {
          setModalUpd(!modalUpd)
          const resD = API.post("banque/list", {
            nbrElParPage: nbrEltperPage,
            page: mycurrentPage,
          }).then(resD => {
            setData(resD.data[0].Banques)
            setNbrTotlEl(resD.data[0].total)
          })
        } else {
          setEtatCode(true)
        }
      })
    }
  }

  const toggleSupp = async () => {
    const res = await API.post("banque/delete", {
      id: id,
    }).then(res => {
      setModalDel(!modalDel)
      const resD = API.post("banque/list", {
        nbrElParPage: nbrEltperPage,
        page: mycurrentPage,
      }).then(resD => {
        setData(resD.data[0].Banques)
        setNbrTotlEl(resD.data[0].total)
      })
    })
  }

  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row>
            <Col xs="12">
              <div className="mb-2 row">
                <div className="col-md-4">
                  <div className="search-box me-2 mb-2 d-inline-block">
                    <div className="position-relative">
                      <label htmlFor="search-bar-0" className="search-label">
                        <input
                          id="search-bar-0"
                          type="text"
                          aria-labelledby="search-bar-0-label"
                          className="form-control "
                          placeholder="Search"
                          onChange={(currentRowsPerPage, currentPage) =>
                            filterSearch(currentRowsPerPage, currentPage)
                          }
                        />
                      </label>
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                </div>
              </div>
              <DataTable
                columns={columns}
                data={data}
                customStyles={customStyles}
                highlightOnHover={false}
                pagination={true}
                paginationServer={true}
                onChangePage={(page, totalRows) => newElement(page, totalRows)}
                paginationTotalRows={nbrTotlEl}
                paginationPerPage={nbrEltperPage}
                paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                  perPage(currentRowsPerPage, currentPage)
                }
                paginationResetDefaultPage={resetFirstPage}
                paginationComponentOptions={{
                  rowsPerPageText: "Elements par page:",
                  rangeSeparatorText: "de",
                  noRowsPerPage: false,
                }}
              />
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>
      {/* <update> */}
      <Modal
        style={{
          maxWidth: "50%",
          width: "100%",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
        isOpen={modalUpd}
       toggle={toggleUpd}
        centered={true}
      >
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggleUpd}
        >
          {props.t("Modification matricule bancaire")}
        </div>
        <ModalBody>
          {etatCode ? (
            <div className="alert alert-danger">
              <strong>Code deja utilisé!</strong>
            </div>
          ) : null}
          <AvForm>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Code")}
                  </Label>
                  <AvField
                    name="code"
                    placeholder="Entrer code"
                    type="text"
                    errorMessage="* Code obligatoire"
                    className="form-control"
                    value={code}
                    validate={{ required: { value: true } }}
                    onChange={e => setCode(e.target.value)}
                    maxlength="5"
                    disabled
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Matricule bancaire")}
                  </Label>
                  <AvField
                    name="matricule"
                    placeholder="Entrer matricule bancaire"
                    type="text"
                    errorMessage="* Matricule bancaire obligatoire"
                    className="form-control"
                    value={name}
                    validate={{ required: { value: true } }}
                    onChange={e => setName(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  className="text-center mt-4"
                >
                  <Button
                    type="button"
                    color="warning"
                    className="btn btn-warning  mb-2 me-2"
                    onClick={toggleUpd}
                  >
                    {props.t("Annuler")}
                  </Button>
                </div>
              </Col>
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
                >
                  <button
                    type="submit"
                    className="btn btn-primary "
                    onClick={toggleModif}
                  >
                    {props.t("Confirmer")}
                  </button>
                </div>
              </Col>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
      {/* <Delete> */}
      <Modal isOpen={modalDel}
      toggle={toggleDel} 
      centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggleDel}
        >
          {props.t("Suppression")}
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p>
                {props.t(
                  "Êtes-Vous sûr de vouloir supprimer cette banque ?"
                )}
              </p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={toggleSupp}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  {props.t("Oui")}
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggleDel}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  {props.t("Non")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(DataTableBanque))
DataTableBanque.propTypes = {
  data: PropTypes.array,
  t: PropTypes.any,
}
