import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import API from "../../../api"
import {
  Col,
  Spinner,
  Input,
  Row,
  Label,
  Form,
  CardTitle,
  Card,
  CardBody,
  Table,
  Container,
} from "reactstrap"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const DetailBonCmdClient = props => {
  var userAuth = JSON.parse(localStorage.getItem("userAuth"))
  const user_id = userAuth.user.id
  //
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState("")
  const [numBonCmd, setNumBonCmd] = useState("")
  const [dateCmd, setDateCmd] = useState(new Date())
  const [selectDevis, setSelectDevis] = useState("")
  const [dateDevis, setDateDevis] = useState(new Date())
  const [selectCodeClient, setSelectCodeClient] = useState("")
  const [raisonSocial, setRaisonSocial] = useState("")
  const [adresse, setAdresse] = useState("")
  const [selectFamilleClient, setSelectFamilleClient] = useState("")
  const [etatFodec, setEtatFodec] = useState(0)
  const [codeTva, setCodeTva] = useState("")
  const [remiseExcep, setRemiseExcep] = useState("")
  const [selectRepre, setSelectRepre] = useState("")
  const [etatRegimeForf, setEtatRegimeForf] = useState(0)
  const [selectModeRegl, setSelectModeRegl] = useState("")
  const [numCmdInt, setNumCmdInt] = useState("")
  const [arrayProduit, setArrayProduit] = useState([])
  const [selectProduit, setSelectProduit] = useState("")
  //
  const [observation, setObservation] = useState("")
  const [totalRemise, setTotalRemise] = useState(0)
  const [totalTva, setTotalTva] = useState("")
  const [totalHT, setTotalHT] = useState(0)
  const [totalFodec, setTotalFodec] = useState(0)
  const [totalTTC, setTotalTTC] = useState(0)

  useEffect(async () => {
    // get by id
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    setId(id)
    const res = await API.post("bon/comande/client/get_by_id", { id }).then(
      res => {
        setNumBonCmd(res.data.BonComandeClient.num_comm)
        setDateCmd(new Date(res.data.BonComandeClient.date_comm))
        setSelectDevis(res.data.BonComandeClient.devis_client)
        setDateDevis(new Date(res.data.BonComandeClient.date_devis))
        setSelectCodeClient(res.data.BonComandeClient.client)
        setRaisonSocial(res.data.BonComandeClient.raisonSocial)
        setAdresse(res.data.BonComandeClient.adresse)
        setSelectFamilleClient(res.data.BonComandeClient.famille_client)
        setEtatFodec(res.data.BonComandeClient.fodec)
        setCodeTva(res.data.BonComandeClient.codeTva)
        setSelectRepre(res.data.BonComandeClient.representant)
        setSelectModeRegl(res.data.BonComandeClient.mode_reglement)
        setEtatRegimeForf(res.data.BonComandeClient.regimeForfitaire)
        setNumCmdInt(res.data.BonComandeClient.num_comm_int)
        setArrayProduit(res.data.BonComandeClient.ligne)
        setObservation(res.data.BonComandeClient.observation)
        setTotalRemise(res.data.BonComandeClient.total_remise)
        setTotalHT(res.data.BonComandeClient.total_ht)
        setTotalFodec(res.data.BonComandeClient.total_fodec)
        setTotalTva(res.data.BonComandeClient.total_tva)
        setTotalTTC(res.data.BonComandeClient.total_ttc)
        setLoading(true)
      }
    )
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        {loading ? (
          <Container>
            <Row>
              <CardTitle className="h4 mb-4" style={{ color: "#556ee6" }}>
                Consultation bon de commande client
              </CardTitle>
              <Form>
                <Row>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">
                        N° bon commande
                      </Label>
                      <Input
                        lg="3"
                        type="text"
                        className="form-control"
                        disabled
                        value={numBonCmd}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Date commande
                      </Label>
                      <DatePicker
                        name="Date devis"
                        selected={dateCmd}
                        className="form-control ddate"
                        dateFormat="dd/MM/yyyy"
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">Devis</Label>
                      <Select
                        isSearchable={true}
                        value={selectDevis}
                        isDisabled
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">Date devis</Label>
                      <DatePicker
                        name="Date devis"
                        selected={dateDevis}
                        className="form-control ddate"
                        dateFormat="dd/MM/yyyy"
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">Client</Label>
                      <Select
                        isSearchable={true}
                        value={selectCodeClient}
                        isDisabled
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">
                        Raison sociale
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Raison sociale"
                        value={raisonSocial}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">Adresse</Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Adresse"
                        value={adresse}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">Famille</Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Famille"
                        value={selectFamilleClient.label}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {/*<Col md={3}>
                    <div className="mb-3 mt-3">
                      <div className="form-check form-check-inline">
                        <Input
                          type="checkbox"
                          className="form-check-Input"
                          id="horizontal-customCheck"
                          checked={etatFodec == 1}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="horizontal-customCheck"
                        >
                          Fodec
                        </Label>
                      </div>
                    </div>
        </Col>*/}
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">Code TVA</Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Code TVA"
                        value={codeTva}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">
                        Representant
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Representant"
                        value={selectRepre.label}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">
                        Mode reglement
                      </Label>
                      <Select
                        isSearchable={true}
                        value={selectModeRegl}
                        isDisabled
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {/*<Col md={3}>
                    <div className="mb-3 mt-3">
                      <div className="form-check form-check-inline">
                        <Input
                          type="checkbox"
                          className="form-check-Input"
                          id="horizontal-customCheck"
                          checked={etatRegimeForf == 1}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="horizontal-customCheck"
                        >
                          Régime forfaitaire
                        </Label>
                      </div>
                    </div>
        </Col>*/}
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">
                        N° Commande interne
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="N° Commande interne"
                        value={numCmdInt}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
              </Form>
              <CardTitle className="h4 mt-4">Details</CardTitle>
              <div>
                <table className="table table-bordered mt-4">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Article
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Désignation
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Qte
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Gratuit
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        P.U.H.T
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Remise
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Fodec
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Montant HT
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        TVA
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Montant TTC
                      </th>
                    </tr>
                  </thead>
                  {arrayProduit.map((el, index) => (
                    <tbody key={index}>
                      <tr>
                        <td style={{ textAlign: "center", width: "24%" }}>
                          <Select
                            isSearchable={true}
                            value={el.produit_id}
                            onChange={e => getProduits(e, index)}
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "10%" }}>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="..."
                            value={el.designation}
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "8%" }}>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder="..."
                            value={el.qte}
                            min={0}
                            onChange={
                              selectProduit != ""
                                ? e => addQuantite(e.target.value, index)
                                : null
                            }
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "7%" }}>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder="..."
                            value={el.qte_gratuit}
                            min={0}
                            onChange={e =>
                              addQuantiteGrt(e.target.value, index)
                            }
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "10%" }}>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder="..."
                            value={el.prix_ht}
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "8%" }}>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder="..."
                            value={el.remise}
                            min={0}
                            max={100}
                            onChange={
                              selectProduit != ""
                                ? e => addRemise(e.target.value, index)
                                : null
                            }
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "2%" }}>
                          <Input
                            type="checkbox"
                            className="form-check-Input"
                            id="horizontal-customCheck"
                            checked={el.fodec == 1}
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "13%" }}>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder="..."
                            value={el.montant_ht}
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "7%" }}>
                          <Input value={el.tva.label} disabled />
                        </td>
                        <td style={{ textAlign: "center", width: "13%" }}>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder="..."
                            value={el.montant_ttc}
                          />
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
              <Card>
                <CardBody>
                  <CardTitle className="mb-3">Total </CardTitle>
                  <Row>
                    <Col className="mt-3" md={12}>
                      <div className="mb-3">
                        <Label for="basicpill-lastname-input2">
                          Observation
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Observation"
                          value={observation}
                          disabled
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <tbody>
                        <tr>
                          <td>Total des remises :</td>
                          <td>{totalRemise}</td>
                        </tr>
                        <tr>
                          <td>Total HT :</td>
                          <td>{totalHT}</td>
                        </tr>
                        <tr>
                          <td>Total fodec :</td>
                          <td>{totalFodec}</td>
                        </tr>
                        <tr>
                          <td>Total TVA :</td>
                          <td>{totalTva}</td>
                        </tr>
                        <tr>
                          <td>Avance/Impot :</td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <td>Total TTC : </td>
                          <td>{totalTTC}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              Chargement en cours ...{" "}
            </h4>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default DetailBonCmdClient
DetailBonCmdClient.propTypes = {
  history: PropTypes.object,
}
