import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import API from "../../api"
// //Import Scrollbar
import SimpleBar from "simplebar-react"
// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
const SidebarContent = props => {
  const [menuColor, setMenuColor] = useState()
  const [textColor, setTextColor] = useState()
  const [selectedtextColor, setselectedTextColor] = useState()
  const ref = useRef()
  const [listmenu, setListmenu] = useState([])
  const [userdata, setUserdata] = useState([])
  const [dashboardOpen, setDashboardOpen] = useState(true);
  const storedItem = localStorage.getItem('menuState');
  const parsedItem = JSON.parse(storedItem);
  const [MenuOpen, setMenuOpen] = useState( parsedItem)

  useEffect(async () => {
    setMenuColor(localStorage.getItem("color_menu"))
    setTextColor(localStorage.getItem("color_Texte"))

    const res = await API.get("societe/get_infos").then(res => {
      localStorage.setItem("color_menu",res.data.societe.couleur_menu)
       setMenuColor(res.data.societe.couleur_menu)
    setMenuColor(localStorage.getItem("color_menu"))
  
  
    localStorage.setItem("color_Texte",res.data.societe.text_color)
    setTextColor(res.data.societe.text_color)
    setTextColor(localStorage.getItem("color_Texte"))
    })

  }, []);


  useEffect(() => {
    if(textColor){
      augumenter_luminosite(textColor,50)
      console.log("selected texte color12", augumenter_luminosite(textColor,50))
    }
    
  }, [textColor])



  useEffect(() => {
    const userAuth = JSON.parse(localStorage.getItem('userAuth'));
    setUserdata(userAuth.user.userData)
    const pathName = props.location.pathname
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])
  useEffect(() => {
    ref.current.recalculate()
  })
/*
  useEffect(() => {
    const initialMenuOpenState = userdata.reduce((acc, curr, index) => {
      acc[index] = false;
      return acc;
    }, {});
    setMenuOpen(initialMenuOpenState);
  }, [userdata]);*/

  function augumenter_luminosite(hex, percent) {
    if (hex) {
        if (hex[0] === '#') {
            hex = hex.slice(1);
        }

        let r = parseInt(hex.substring(0, 2), 16);
        let g = parseInt(hex.substring(2, 4), 16);
        let b = parseInt(hex.substring(4, 6), 16);

        // Calcul de la luminance de la couleur
        let luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

        // Déterminer si la couleur est lumineuse ou sombre
        let isBright = luminance > 0.5;

        // Calcul du delta en fonction de si la couleur est lumineuse ou sombre
        let delta = Math.floor(255 * percent / 100) * (isBright ? -1 : 1);

        r = Math.min(255, Math.max(0, r + delta));
        g = Math.min(255, Math.max(0, g + delta));
        b = Math.min(255, Math.max(0, b + delta));

        let newHex = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

        return newHex;
    }
}



  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }
  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }
    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement
      if (parent2) {
        parent2.classList.add("mm-show") // ul tag
        const parent3 = parent2.parentElement // li tag
        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }
  const toggleDashboard = () => {
    setDashboardOpen(!dashboardOpen);
  };
  const toggleMenu = (index) => {
    setMenuOpen({
      ...MenuOpen,
      [index]: !MenuOpen[index]
    });
   // localStorage.setItem('menuState', JSON.stringify([MenuOpen]));
  };
  useEffect(() => {
    localStorage.setItem("menuState",JSON.stringify(MenuOpen))
  }, [MenuOpen]);
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {dashboardOpen &&
              userdata.map((menuData, index) => (
                <li key={index}  >
                  {menuData.sous_menus.length > 1 ? (
                    <a className="has-arrow " onClick={() => toggleMenu(index)}  style={{backgroundColor:menuColor}}>
                      <i style={{color:textColor}}className={menuData.icon}  ></i>
                      <span style={{color:textColor}} >{menuData.menu}</span>
                    </a>
                  ) : (
                    <Link to={menuData.sous_menus[0].url}  style={{backgroundColor:menuColor}}>
                      <i  style={{color:textColor}}className={menuData.icon}></i>
                      <span style={{ color: props.location.pathname === menuData.sous_menus[0].url ? selectedtextColor  :textColor }} >{menuData.menu}</span>
                    </Link>
                  )}
                  {MenuOpen[index] && menuData.sous_menus.length > 1 && (
                    <ul className="sub-menu" aria-expanded="false"  style={{backgroundColor:menuColor}}>
                      {menuData.sous_menus.map((subMenuData, subIndex) => (
                        <li key={subIndex} >
                          <Link to={subMenuData.url}>
                            <span style={{ color: props.location.pathname === subMenuData.url ? selectedtextColor : textColor }}  >{subMenuData.sous_menu}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}
SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
export default withRouter(withTranslation()(SidebarContent))