/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import API from "../../../api"
import {
  Spinner,
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  Table,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { set } from "lodash"

const AddFactureBlClient = props => {
  var userAuth = JSON.parse(localStorage.getItem("userAuth"))
  const user_id = userAuth.user.id
  const annee=userAuth.user.year
  //
  const [loading, setLoading] = useState(false)
  const [numFact, setNumFact] = useState("")
  const [dateFact, setDateFact] = useState(new Date())
  const [client, setClient] = useState([])
  const [selectCodeClient, setSelectCodeClient] = useState("")
  const [raisonSocial, setRaisonSocial] = useState("")
  const [adresse, setAdresse] = useState("")
  const [selectFamilleClient, setSelectFamilleClient] = useState("")
  const [etatFodec, setEtatFodec] = useState(0)
  const [codeTva, setCodeTva] = useState("")
  const [remiseExcep, setRemiseExcep] = useState("")
  const [selectRepre, setSelectRepre] = useState("")
  const [etatRegimeForf, setEtatRegimeForf] = useState(0)
  const [modeRegl, setModeRegl] = useState([])
  const [selectModeRegl, setSelectModeRegl] = useState("")
  const [displayBlock, setDisplayBlock] = useState(false)
  const [displayBlockTotal, setDisplayBlockTotal] = useState(false)
  const [arrayProduit, setArrayProduit] = useState([])
  const [array, setArray] = useState([])
  const [totalHT, setTotalHT] = useState("")
  const [totalTTC, setTotalTTC] = useState("")
  const [totalTva, setTotalTva] = useState("")
  const [totalRemise, setTotalRemise] = useState("")
  const [totalFodec, setTotalFodec] = useState("")
  const [buttonClicked, setButtonClicked] = useState(false);


  useEffect(async () => {
    const res = await API.post("facture/client/getnum",{
      annee:annee
    }).then(res => {
      setNumFact(res.data.FactureClient)
      setLoading(true)
    })
    const resC = await API.get("client/select").then(resC => {
      setClient(resC.data.Client)
    })

    const resT = await API.get("mode/reglement/select").then(rest => {
      setModeRegl(rest.data.ModeReglement)
    })
    
  }, [])

  const getClient = async selectCodeClient => {
    if (selectCodeClient != null) {
      var client_id = selectCodeClient.value
    } else {
      var client_id = ""
    }
    setSelectCodeClient(selectCodeClient)
    const res = await API.post("client/get_by_id", {
      id: client_id,
    }).then(res => {
      setRaisonSocial(res.data.Client.raisonSocial)
      setAdresse(res.data.Client.adresseLivraison)
      setSelectFamilleClient(res.data.Client.famille_client)
      setEtatFodec(res.data.Client.fodec)
      setCodeTva(res.data.Client.codeTVA)
      setSelectRepre(res.data.Client.Representant)
      setEtatRegimeForf(res.data.Client.regimeForfutaire)
     

    })
  }

  const coche = (event, index, id) => {
    if (event.target.checked == true) {
      let etat = 1
      setArrayProduit(
        arrayProduit.map((el, id) =>
          id === index
            ? Object.assign(el, {
                etat: etat,
              })
            : el
        )
      )
      var arrayFact = array
      arrayFact.push(id)
      setArray(arrayFact)
    } else {
      setArrayProduit(
        arrayProduit.map((el, id) =>
          id === index
            ? Object.assign(el, {
                etat: 0,
              })
            : el
        )
      )
      var arrayFact = array
      arrayFact.splice(index, 1)
      setArray(arrayFact)
    }
  }

  const display = async () => {
    if (selectCodeClient != null ) {
      var client_id = selectCodeClient.value
      const res = await API.post("facture/client/get_list_liv", {
        client_id,
      }).then(res => {
        setArrayProduit(res.data.BonLivraisonClient)
        if (res.data.BonLivraisonClient.length > 0) {
          setDisplayBlock(true)
        } else {
          toast.error("Pas de BL disponible pour ce client", {
            containerId: "A",
          })
        }
      })
    
    }
  }

  const facturer = async () => {
    const res = await API.post("facture/client/get_liv", {
      array_id_livraison: array,
    }).then(res => {
      setTotalHT(res.data.BonLivraisonClient.total_ht)
      setTotalRemise(res.data.BonLivraisonClient.total_remise)
      setTotalTva(res.data.BonLivraisonClient.total_tva)
      setTotalFodec(res.data.BonLivraisonClient.total_fodec)
      setTotalTTC(res.data.BonLivraisonClient.total_ttc)
      if (array.length > 0) {
        setDisplayBlockTotal(true)
      } else {
        toast.error("Veuillez cocher au moins une BL", {
          containerId: "A",
        })
      }
    })
  }

  const save = async () => {
    setButtonClicked(true)
    if (arrayProduit.length > 0) {
      if (dateFact != "") {
        let date = dateFact
        let month = "" + (date.getMonth() + 1)
        let day = "" + date.getDate()
        let year = date.getFullYear()
        if (month.length < 2) month = "0" + month
        if (day.length < 2) day = "0" + day
        var convertDateFact = [year, month, day].join("-")
      } else {
        var convertDateFact = ""
      }
      if (arrayProduit.length == 0) {
        toast.error("⛔ Saisir au moins une article", {
          containerId: "A",
        })
        setButtonClicked(false)

      } else if (selectCodeClient != "" && selectModeRegl !="") {
        const res = await API.post("facture/client/add_liv", {
          annee:annee,
          user_id,
          num_fact_bl: numFact,
          date_fact: convertDateFact,
          client_id: selectCodeClient.value,
          raison_social: raisonSocial,
          adresse: adresse,
          famille_id: selectFamilleClient.value,
          fodec: etatFodec,
          code_tva: codeTva,
          remise_excep: remiseExcep,
          representant_id: selectRepre.value,
          regime_forf: etatRegimeForf,
          mode_regl_id: selectModeRegl.value,
          total_remise: totalRemise,
          total_ht: totalHT,
          total_fodec: totalFodec,
          total_tva: totalTva,
          total_ttc: totalTTC,
          array_id_livraison: array,
        }).then(res => {
          props.setSection(1)
        })
      } else {
        toast.error("Veuillez choisir un client et une mode de reglement ", {
          containerId: "A",
        })
        setButtonClicked(false)

      }
    } else {
      toast.error("Veuillez choisir au moins un article", {
        containerId: "A",
      })
      setButtonClicked(false)

    }
  }

  return (
    <React.Fragment>
      {loading ? (
        <Row>
          <Form>
            <Row>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">N° facture BL</Label>
                  <Input
                    lg="3"
                    type="text"
                    className="form-control"
                    disabled
                    value={numFact}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Date facture BL</Label>
                  <Col md={12} className="pr-0">
                    <DatePicker
                      className="form-control ddate"
                      selected={dateFact}
                      onChange={e => setDateFact(e)}
                      dateFormat="dd/MM/yyyy"
                    />
                  </Col>
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Client
                    <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                  </Label>
                  <Select
                    options={client}
                    isSearchable={true}
                    onChange={e => getClient(e)}
                    value={selectCodeClient}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Raison sociale</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Raison sociale"
                    value={raisonSocial}
                    disabled
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Adresse</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Adresse"
                    value={adresse}
                    disabled
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Famille</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Famille"
                    value={selectFamilleClient.label}
                    disabled
                  />
                </div>
              </Col>
              {/*<Col md={3}>
                <div className="mb-3 mt-3">
                  <div className="form-check form-check-inline">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="horizontal-customCheck"
                      checked={etatFodec == 1}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="horizontal-customCheck"
                    >
                      Fodec
                    </Label>
                  </div>
                </div>
      </Col>*/}
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Code TVA</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Code TVA"
                    value={codeTva}
                    disabled
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Representant</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Representant"
                    value={selectRepre.label}
                    disabled
                  />
                </div>
              </Col>
              {/*<Col md={3}>
                <div className="mb-3 mt-3">
                  <div className="form-check form-check-inline">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="horizontal-customCheck"
                      checked={etatRegimeForf == 1}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="horizontal-customCheck"
                    >
                      Régime forfaitaire
                    </Label>
                  </div>
                </div>
      </Col>*/}
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Mode reglement
                  <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                  </Label>
                  <Select
                    options={modeRegl}
                    isSearchable={true}
                    onChange={e => setSelectModeRegl(e)}
                    value={selectModeRegl}
                  />
                </div>
              </Col>
            </Row>
          </Form>
          <div
            className="mb-4"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {" "}
            <div className="text-center mt-4">
              <button
                type="button"
                style={{
                  backgroundColor: "#761C19",
                  borderColor: "#761C19",
                  color: "white",
                }}
                className="btn mb-2 me-2"
                onClick={display}
              >
                Afficher
              </button>
            </div>
          </div>
          {displayBlock && arrayProduit.length > 0 ? (
            <Fragment>
              <div className="row mt-4">
                <table
                  style={{ marginTop: "10px" }}
                  className="table table-bordered"
                >
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Numéro piéce
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Date piéce
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Montant TTC
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Facturation
                      </th>
                    </tr>
                  </thead>
                  {arrayProduit.map((el, index) => (
                    <tbody key={index}>
                      <tr>
                        <td style={{ textAlign: "center" }}>
                          <p
                            style={{
                              textAlign: "center",
                              borderRadius: "5px",
                            }}
                          >
                            {el.numBonLiv}
                          </p>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <p
                            style={{
                              textAlign: "center",
                              borderRadius: "5px",
                            }}
                          >
                            {el.dateBonLiv}
                          </p>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <p
                            style={{
                              textAlign: "center",
                              borderRadius: "5px",
                            }}
                          >
                            {el.mntTtc}
                          </p>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <label style={{ paddingRight: "10px" }}>
                            {" "}
                            Facturer
                          </label>
                          <Input
                            type="checkbox"
                            className="form-check-Input"
                            onChange={e => coche(e, index, el.id)}
                            checked={el.etat}
                          />
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <Col lg="6">
                  <div className="text-center mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary "
                      onClick={facturer}
                    >
                      Facturer
                    </button>
                  </div>
                </Col>
              </div>
              {displayBlockTotal ? (
                <Fragment>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-3">Total </CardTitle>
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <tbody>
                            <tr>
                              <td>Total des remises :</td>
                              <td style={{ fontWeight: "bold" }}>
                                {totalRemise}
                              </td>
                            </tr>
                            <tr>
                              <td>Total HT :</td>
                              <td style={{ fontWeight: "bold" }}>{totalHT}</td>
                            </tr>
                            <tr>
                              <td>Total fodec :</td>
                              <td style={{ fontWeight: "bold" }}>
                                {totalFodec}
                              </td>
                            </tr>
                            <tr>
                              <td>Total TVA :</td>
                              <td style={{ fontWeight: "bold" }}>{totalTva}</td>
                            </tr>
                            <tr>
                              <td>Avance/Impot :</td>
                              <td style={{ fontWeight: "bold" }}>{""}</td>
                            </tr>
                            <tr>
                              <td>Total TTC : </td>
                              <td style={{ fontWeight: "bold" }}>{totalTTC}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        className="text-center mt-4"
                      >
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={props.back}
                        >
                          Annuler
                        </Button>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="text-center mt-4"
                      >
                        <button
                          type="submit"
                          className="btn btn-primary "
                          disabled={buttonClicked} 
                          onClick={save}
                        >
                          Confirmer
                        </button>
                      </div>
                    </Col>
                  </div>
                </Fragment>
              ) : null}
            </Fragment>
          ) : null}
        </Row>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            {" "}
            Chargement en cours ...{" "}
          </h4>
        </div>
      )}
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default AddFactureBlClient
AddFactureBlClient.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
