import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import { Row, Col, Spinner, Label, Input } from "reactstrap"
import { withRouter } from "react-router"
//lang
import { withTranslation } from "react-i18next"
import { Search } from "react-bootstrap-table2-toolkit"
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import 'jspdf-autotable';

import { ToastContainer, toast } from "react-toastify"
import { Slide } from "react-toastify"
import logo1 from "../../assets/images/LOGOerp2.png"
import defaultImage from '../../assets/images/loading-opaque.gif';






const societeParam = props => {
    const [loading, setLoading] = useState(false)



    const [userdata, setUserdata] = useState([])

    const contentRef = useRef(null);

    //date
    const [date, setDate] = useState(new Date())





    const userAuth = JSON.parse(localStorage.getItem('userAuth'));
    const user_id = userAuth.user.id



    //verification
    const [depotError, setDepotError] = useState("");
    const [dateDebutError, setDateDebutError] = useState("");
    const [dateFinError, setDateFinError] = useState("");
    const [perm, setPerm] = useState({
        add: 0,
        delete: 0,
        edit: 0,
        imp: 0,
        view: 0
    });
    const [couleurMenu, setCouleurMenu] = useState('#000000');
    const [couleurTexte, setCouleurTexte] = useState('#000000');

    // images Logos 
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedIcon, setSelectedIcon] = useState(null);


    const [phoneNumbers, setPhoneNumbers] = useState(['']);
    const [Nom_societe, setNom_societe] = useState('');
    const [Site_societe, setSite_societe] = useState('');
    const [Email_societe, setEmail_societe] = useState('');
    const [image_changed, setImageChanged] = useState(false);
    const [icone_changed, setIconeChanged] = useState(false);


    const maxHeight = 150;






    useEffect(async () => {
        const userAuth = JSON.parse(localStorage.getItem('userAuth'));
        setUserdata(userAuth.user.userData)
        console.log('user', userAuth)
    }, [])

    useEffect(() => {
        const typeArticleSubMenu = userdata.find(user =>
            user.sous_menus.some(subMenu => subMenu.sous_menu === "Parametrage societe")
        );

        if (typeArticleSubMenu) {
            const typeArticlePermission = typeArticleSubMenu.sous_menus.find(
                subMenu => subMenu.sous_menu === "Parametrage societe"
            ).permissions[0];
            setPerm(prevPerm => ({
                ...prevPerm,
                add: typeArticlePermission.add,
                delete: typeArticlePermission.delete,
                edit: typeArticlePermission.edit,
                imp: typeArticlePermission.imp,
                view: typeArticlePermission.view,
            }));
        }
    }, [userdata]);

    useEffect(async () => {
        setLoading(true);

        setCouleurMenu(localStorage.getItem("color_menu"))
        setCouleurTexte(localStorage.getItem("color_Texte"))

        const res = await API.get("societe/get_infos").then(res => {
            setSelectedImage(res.data.logo)
            setSelectedIcon(res.data.icone)
            setNom_societe(res.data.societe.nom_societe)
            setSite_societe(res.data.societe.site_web)
            setEmail_societe(res.data.societe.email_societe)
            setPhoneNumbers(res.data.societe.numeros)
            setCouleurMenu(res.data.societe.couleur_menu)
            setCouleurTexte(res.data.societe.text_color)


        })






    }, []);


    const confirmer = async () => {


        const res = await API.post("societe/save_infos", {
            user_id: user_id,
            Nom_societe: Nom_societe,
            Site_societe: Site_societe,
            Email_societe: Email_societe,
            phoneNumbers: phoneNumbers,
            couleurMenu: couleurMenu,
            couleurTexte: couleurTexte,
            selectedImage: selectedImage,
            image_changed: image_changed,
            icone_changed: icone_changed,
            selectedIcon: selectedIcon


        }).then(res => {
            console.log("Ajouter:", res);
            localStorage.setItem("color_menu", couleurMenu)
            localStorage.setItem("color_Texte", couleurTexte)

            localStorage.setItem("LogoImg", selectedImage)

            window.location.reload();

        })
    };

    const handleCouleurMenuChange = (e) => {
        setCouleurMenu(e.target.value);
    };
    const handleCouleurTexteChange = (e) => {
        setCouleurTexte(e.target.value);
    };
    const handleImageUpload = (event) => {
        setImageChanged(true)
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = function (e) {

            setSelectedImage(e.target.result);

        };

        reader.readAsDataURL(file);
    };
    const handleIconUpload = (event) => {
        setIconeChanged(true)
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = function (e) {

            setSelectedIcon(e.target.result);

        };

        reader.readAsDataURL(file);
    };
    const handleInputChange = (index, value) => {
        const updatedPhoneNumbers = [...phoneNumbers];
        updatedPhoneNumbers[index] = value;
        setPhoneNumbers(updatedPhoneNumbers);
    };

    const addPhoneNumberInput = () => {
        setPhoneNumbers([...phoneNumbers, '']); // Ajouter une nouvelle zone de saisie vide
    };
    const removePhoneNumberInput = (index) => {
        const updatedPhoneNumbers = phoneNumbers.filter((_, i) => i !== index);
        setPhoneNumbers(updatedPhoneNumbers);
    };


    const { SearchBar } = Search

    return (
        <React.Fragment>

            <div ref={contentRef}>

                {loading ? (

                    <Row>

                        <Col md={4} sm={6}>
                            <div className="mb-3">
                                <Label for="basicpill-lastname-input2">{"Nom de société"} </Label>
                                <Col md={12} className="pr-0">
                                    <Input
                                        placeholder="Nom de société"
                                        type="text"
                                        onChange={(e) => {
                                            setNom_societe(e.target.value);


                                        }}
                                        value={Nom_societe}

                                    />
                                </Col>
                            </div>
                        </Col>





                        <Col md={4} sm={6}>
                            <div className="mb-3">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-arround",
                                    }}
                                >
                                    <Label for="basicpill-firstname-input1">
                                        {"Site web de société"}
                                    </Label>

                                </div>
                                <Input
                                    placeholder="Site web de société"
                                    type="text"
                                    onChange={(e) => {
                                        setSite_societe(e.target.value);


                                    }}
                                    value={Site_societe}
                                />

                            </div>
                        </Col>






                        <Col md={4} sm={6}>
                            <div className="mb-3">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-arround",
                                    }}
                                >
                                    <Label for="basicpill-firstname-input1">
                                        {"E-mail de sociéte"}
                                    </Label>

                                </div>
                                <Input
                                    placeholder="E-mail de sociéte"
                                    type="email"
                                    onChange={(e) => {
                                        setEmail_societe(e.target.value);
                                    }}
                                    value={Email_societe}

                                />

                            </div>
                        </Col>
                        <Col md={4} sm={6}>
                            {phoneNumbers.map((phoneNumber, index) => (
                                <Row key={index}>
                                    <Col md={12} sm={12}>
                                        <Label for={`phone-input-${index}`}>
                                            {index === 0 ? "Numéro de téléphone" : ""}
                                        </Label>
                                        <div style={{ display: 'flex', }}>

                                            <Input
                                                id={`phone-input-${index}`}
                                                placeholder="Numéro de téléphone"
                                                type="tel"
                                                value={phoneNumber}
                                                maxLength={8}
                                                onChange={(e) => handleInputChange(index, e.target.value)}
                                            />

                                            {index !== 0 && (
                                                <button
                                                    className="btn btn-danger rounded-circle"
                                                    onClick={() => removePhoneNumberInput(index)}
                                                >
                                                    x
                                                </button>
                                            )}
                                            <button
                                                className="btn btn-primary rounded-circle mr-2"
                                                onClick={addPhoneNumberInput}
                                                style={{ display: index === phoneNumbers.length - 1 ? 'block' : 'none' }}
                                            >
                                                +
                                            </button>
                                        </div>
                                    </Col>


                                </Row>
                            ))}
                        </Col>



                        <Col md={1} sm={1}>
                            <div className=" mt-4 mb-3">

                                <Input style={{
                                    height: "40px",
                                }}
                                    type="color"
                                    className="form-control"
                                    value={couleurMenu}
                                    onChange={handleCouleurMenuChange}
                                />
                            </div>
                        </Col>

                        <Col md={3} sm={5}>
                            <div className="mb-3" style={{
                                marginTop: "30px",
                            }}>
                                <Label for="basicpill-lastname-input2">Couleur du menu </Label>

                            </div>
                        </Col>
                        <Col md={1} sm={1}>
                            <div className=" mt-4 mb-3">

                                <Input style={{
                                    height: "40px",
                                }}
                                    type="color"
                                    className="form-control"
                                    value={couleurTexte}
                                    onChange={handleCouleurTexteChange}
                                />
                            </div>
                        </Col>

                        <Col md={3} sm={5}>
                            <div className="mb-3" style={{
                                marginTop: "30px",
                            }}>
                                <Label for="basicpill-lastname-input2">Couleur du texte</Label>

                            </div>
                        </Col>
                        <Col md={12} lg={12} style={{ height: "20px" }}></Col>

                        <Col md={6} mt={4}>
                            <div className="col-md-4 d-flex" style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className="mb-3 d-flex">
                                    <img
                                        id="selectedImage1"
                                        //src={ defaultImage }
                                        src={selectedImage ? (image_changed === false ? `data:image/png;base64, ${selectedImage}` : selectedImage) : defaultImage}

                                        style={{
                                            width: '250px',
                                            height: '250px',
                                            borderRadius: '20%',
                                            marginLeft: '73px',
                                            backgroundColor: '#cccccc',
                                        }}
                                    />


                                </div>
                                <div className="col-md-12 btn btn-primary btn-rounded " style={{ marginLeft: '6rem' }}>
                                    <label className="form-label text-white m-1" htmlFor="customFile1">Modifier le Logo </label>
                                    <input type="file" name="logo_image" className="form-control d-none" id="customFile1" onChange={(e) => handleImageUpload(e)} />
                                </div>
                            </div>
                        </Col>


                        <Col md={6}>
                            <div className="col-md-4 d-flex" style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className="mb-3 d-flex">
                                    <img
                                        id="selectedIcon1"
                                        src={selectedIcon ? (icone_changed === false ? `data:image/png;base64, ${selectedIcon}` : selectedIcon) : defaultImage}

                                        style={{
                                            width: '250px',
                                            height: '250px',
                                            borderRadius: '20%',
                                            marginLeft: '73px',
                                            backgroundColor: '#cccccc',
                                        }}
                                    />
                                </div>
                                <div className="col-md-12 btn btn-primary btn-rounded " style={{ marginLeft: '6rem' }}>
                                    <label className="form-label text-white m-1" htmlFor="customFile2"> {"Modifier l'icône"}</label>
                                    <input type="file" name="logo_image" className="form-control d-none" id="customFile2" onChange={(e) => handleIconUpload(e)} />
                                </div>
                            </div>
                        </Col>


                        







                        <col></col>

                        <Col md={{ span: 4, offset: 4 }}>
                            <div

                                className="text-center mt-4"
                            >
                                <button
                                    style={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}

                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={confirmer}
                                >
                                    Confirmer
                                </button>
                            </div>
                        </Col>

                        <Col md={4}  >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                }}
                                className="text-center mt-4"
                            >

                            </div>
                        </Col>



                    </Row>


                ) : (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {" "}
                            <Spinner type="grow" className="ms-6" color="primary" />
                        </div>
                        <h4
                            style={{ textAlign: "center", marginTop: "2%" }}
                            className="ms-6"
                        >
                            {" "}
                            {props.t("Chargement en cours...")}
                        </h4>
                    </div>
                )}
            </div>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />


        </React.Fragment>
    )
}
export default withRouter(withTranslation()(societeParam))
societeParam.propTypes = {
    personnel: PropTypes.array,
    history: PropTypes.object,
    setSection: PropTypes.object,
    apiKey: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1513.9824445194495!2d10.752979927552579!3d34.74139679851973!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1301d32a5b1efd83%3A0x70344d257ce64f3c!2sCresus!5e0!3m2!1sfr!2stn!4v1715010564607!5m2!1sfr!2stn" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',

    t: PropTypes.any,
}
