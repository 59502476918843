import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import API from "../../../api"

// users
import user1 from "../../../assets/images/users/userImage.png"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("")
  const [userAuth, setuserAuth] = useState("")
  const [texteColor, setTexteColor] = useState()
  const [userphoto, setUSerPhoto] = useState()
  const [idx, setidx] = useState(JSON.parse(localStorage.getItem("userAuth")).user.id)
  const [userData, setUserData] = useState()


  useEffect(async () => {
    setUSerPhoto( JSON.parse(localStorage.getItem("userAuth")).user.photo64)
    setTexteColor(localStorage.getItem("color_Texte"))
   // setUserData(JSON.parse(localStorage.getItem("userAuth")))
   var user_data=JSON.parse(localStorage.getItem("userAuth"))
    setusername(JSON.parse(localStorage.getItem("userAuth")).user)
   
    const res = await API.get("societe/get_infos").then(res => {


      localStorage.setItem("color_Texte", res.data.societe.text_color)
      setTexteColor(res.data.societe.text_color)
      setTexteColor(localStorage.getItem("color_Texte"))
    })

    const res1 = await API.post("user/get_by_id", {
      id: idx,

    }).then(res1 => {
      setusername(res1.data.Users)
      user_data.user.name=res1.data.Users.name
      localStorage.setItem("userAuth", JSON.stringify(user_data))
    
      setUSerPhoto(res1.data.Users.photo)
      user_data.user.photo64=res1.data.Users.photo
      localStorage.setItem("userAuth", JSON.stringify(user_data))

    })


    }, []);

    useEffect (async()=> {

      var user_data=JSON.parse(localStorage.getItem("userAuth"))
      const year=user_data.user.year
      const id = user_data.user.id
    
      const res= await API.post("user/get_perms", {
        id: id,
        year:year 
      }) 
      localStorage.setItem("userAuth", JSON.stringify(res.data))
    },[])


  const clearStorage = () => {
    localStorage.clear()
    props.history.push("/login")
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={`data:image/png;base64, ${userphoto}`}

            alt="Header Avatar"
          />
          <span
            style={{ color: texteColor }}
            className="d-none d-xl-inline-block ms-2 me-1"
          >
            {username.name}
          </span>
          <i
            style={{ color: texteColor }}
            className="mdi mdi-chevron-down d-none d-xl-inline-block"
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end"  >
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span onClick={clearStorage}>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
ProfileMenu.propTypes = {
  history: PropTypes.object,
}
